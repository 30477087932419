import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  URLSendCV,
  URLGetJobOffers,
  URLLogin,
  URLDeleteJobOffer,
  expresiones,
} from "../../constants/constants";
import "./OfertasLaborales.css";
import { Modal, Loading, Form, Input } from "../../components/index";
import { setValue, getValue, removeValue } from "../../services/LocalStorage";
import {
  Alert,
  Grid,
  Box,
  Button,
  Typography,
  Container
} from "@mui/material";
import LoginIcon from "@mui/icons-material/Login";
import LogoutIcon from "@mui/icons-material/Logout";
import JobOfferCard from "../../components/JobOfferCard/JobOfferCard";

const FormData = require("form-data");

export default function OfertasLaborales() {
  const [joboffers, setJobOffers] = useState([]);
  const [jobOfferSelect, setJobOfferSelect] = useState({
    jobTitle: "",
    jobId: "",
  });
  const [loading, setLoading] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  const [user, setUser] = useState("");
  const [secret, setSecret] = useState("");
  const handleClose = () => setShowLogin(false);
  const [showApply, setShowApply] = useState(false);
  const [isUserAutenticated, setIsUserAutenticated] = useState(
    getValue("access_token")
  );
  const [alert, setAlert] = useState({ visible: false });
  const [fileUpload, setFileUpload] = useState(false);

  const [dataForm, setDataForm] = useState({
    name: "",
    lastname: "",
    birth: "",
    celphone: "",
    province: "",
    town: "",
    degree: "",
    languages: "",
    area: "",
    subarea: "",
    message: "",
    file: "",
    email: "",
    jobId: "",
  });
  const [sent, sended] = useState(false);

  const fetchJobOffers = () => {
    setLoading(true);
    axios({
      method: "get",
      url: `${URLGetJobOffers}`,
    })
      .then((result) => {
        setJobOffers(result.data);
        // console.log(joboffers);
        setLoading(false);
      })
      .catch((error) => console.log({ error: error.message }));
    
  };

  const handleInput = (value, id) => {
    if (id === "email") setUser(value);
    else setSecret(value);
  };

  const handleLogin = async () => {
    const formData = new FormData();

    // Update the formData object
    formData.set("user", user);
    formData.set("secret", secret);

    const result = await axios({
      method: "post",
      url: `${URLLogin}`,
      headers: {
        "content-type": `multipart/form-data`,
      },
      data: formData,
    });
    if (result.data) {
      setValue("access_token", result.data);
      window.location = "/panelofertas";
      return result;
    }
    setAlert({
      visible: true,
      message: "Usuario o contraseña erronea",
      severity: "error",
    });
  };

  const handleDeleteJob = (job_id) => {
    const formData = new FormData();
    formData.set("job_id", `${job_id}`);

    axios({
      method: "post",
      url: `${URLDeleteJobOffer}`,
      headers: {
        "content-type": `multipart/form-data`,
      },
      data: formData,
    })
      .then((result) => {
        setAlert({
          visible: true,
          message: "Oferta Eliminada",
          severity: "success",
        });
        fetchJobOffers();
      })
      .catch((error) => {
        setAlert({ visible: true, message: error.message, severity: "error" });
        console.log({ error: error.message });
      });
  };


  const handleLogOut = () => {
    removeValue("access_token");
    setIsUserAutenticated(false);
  };

  useEffect(() => {
    fetchJobOffers();
  }, []);

  useEffect(() => {
    getValue("access_token")
      ? setIsUserAutenticated(true)
      : setIsUserAutenticated(false);
  }, [isUserAutenticated]);

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <>
          <Grid
            container
            spacing={1}
            marginTop={"0"}
          >
            {alert.visible && (
              <>
                <br />
                <Alert severity={alert.severity}>{alert.message}</Alert>
              </>
            )}
            {joboffers.map((job, index) => (
              <Grid item xs={12} sm={6} md={4} lg={3} mb={5}>
                <JobOfferCard
                  jobID={job.job_id}
                  jobTitle={job.title}
                  jobDescription={job.description}
                  jobArea={job.zone}
                  jobTime={job.time}
                />
                <br />
              {isUserAutenticated && (
                <Button
                  variant="contained"
                  sx={{ bgcolor: "#EF8B2D" }}
                  onClick={() => handleDeleteJob(job.job_id)}
                >
                  <Typography variant="spam">Borrar</Typography>
                </Button>
              )}
              </Grid>
            ))}
            {!isUserAutenticated ? (
              <Grid container justifyItems={"baseline"}>
                <Button
                  onClick={() => setShowLogin(!showLogin)}
                  variant="contained"
                  sx={{ bgcolor: "#EF8B2D" }}
                  startIcon={<LoginIcon />}
                >
                  Login Empleados
                </Button>
              </Grid>
            ) : (
              <Grid container justifyItems={"baseline"}>
                <Button
                  variant="contained"
                  sx={{ bgcolor: "#EF8B2D" }}
                  startIcon={<LogoutIcon />}
                  onClick={() => handleLogOut()}
                >
                  Logout
                </Button>
              </Grid>
            )}
          </Grid>
        </>
      )}
      <Modal
        visibility={showLogin}
        changeVisibility={setShowLogin}
        title="Ingreso de Oferta Laboral"
        handleSubmit={handleLogin}
        buttonText="Login"
        disabled={!user || !secret}
      >
        <Container maxWidth="md">
          {alert.visible && (
            <Alert severity={alert.severity}>{alert.message}</Alert>
          )}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Grid
              container
              spacing={2}
              direction="column"
              justifyContent="space-around"
              alignItems="center"
            >
              <Grid item xs={12}>
                <Input
                  id="email"
                  label="E-mail"
                  helperText="Debe propocionar un correo válido."
                  error={!expresiones.correo.test(user)}
                  onChange={(e) => handleInput(e.target.value, "email")}
                />
              </Grid>
              <Grid item xs={12}>
                <Input
                  id="password"
                  type="password"
                  label="Contraseña"
                  helperText="Debe propocionar una contraseña válida."
                  onChange={(e) => handleInput(e.target.value, "password")}
                />
              </Grid>
            </Grid>
          </Box>
        </Container>
      </Modal>
    </>
  );
}
