import React from 'react';
import './Contact.css';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import Link from '@material-ui/core/Link';
import { linkedInURL, facebookURL, instagramURL, twitterURL }from '../../constants/constants';



function Contact() {
    return ( 
        <div className="contact-container">
            {/*Ttulo para Contacto*/}
            <div className="contact-head">
                <h2>C</h2>
                {/* <img src={imglogo} alt="MCO logo" style={{width:'50px', height:'50px'}}/> */}
                <h2>ontactanos!</h2>
                {/* <img src={imglogo} alt="MCO logo" style={{width:'50px', height:'50px'}}/> */}
            </div>
            <div className="contact-container-socialnetworks">
                <Link href={linkedInURL} target="_blank" rel="noopener">
                    <LinkedInIcon className="contact-socialnetworks-icons" sx={{ fontSize: 50 }}/>
                </Link>
                <Link href={instagramURL} target="_blank" rel="noopener">
                <InstagramIcon className="contact-socialnetworks-icons" sx={{ fontSize: 50 }}/>
                </Link>
                <Link href={facebookURL} target="_blank" rel="noopener">
                    <FacebookIcon className="contact-socialnetworks-icons" sx={{ fontSize: 50 }}/>
                </Link>
                <Link href={twitterURL} target="_blank" rel="noopener">
                    <TwitterIcon className="contact-socialnetworks-icons" sx={{ fontSize: 50 }}/>
                </Link>  
            </div> 
        </div> 
     );
}

export default Contact;