import { Route, Routes } from "react-router-dom";
import { Footer, Navbar } from "../components";
import {
  Home,
  SucursalesPage,
  Servicios,
  OfertasLaborales
} from "../pages/index";
import PrivateRoute from "./PrivateRoutes";
import DetallesOferta from "../pages/OfertasLaborales/DetallesOferta";

function Router() {
  return (
    <body>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<Home />} />
        <Route exact path="/sucursales" element={<SucursalesPage />} />
        <Route exact path="/servicios" element={<Servicios />} />
        <Route exact path="/ofertaslaborales" element={<OfertasLaborales />} />
        <Route exact path="/ofertaslaborales/:id" element={<DetallesOferta/>}/>
        <Route exact path="/panelofertas" element={<PrivateRoute />} />
      </Routes>
      <Footer />
    </body>
  );
}

export default Router;
