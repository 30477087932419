import img1 from '../images/family-bussines.jpg';
import img2 from '../images/fondoRRHH_1.jpg';
import img3 from '../images/handshaking.jpg'; 

export const URL = "https://moeventual.com.ar/";

export const URLSendCV = URL + "sendCV.php";

export const URLGetJobOffers = URL + "getjoboffers.php";

export const URLGetJobOffersByID = URL + "getjoboffersbyid.php";

export const URLLogin = URL + "login.php";

export const URLInsertJobOffer = URL + "insertjoboffer.php";

export const URLDeleteJobOffer = URL + "deletejoboffer.php";

// eslint-disable-next-line no-useless-concat
export const whatsappURL =
  "https://wa.me/5491167839199/" +
  `?text=${"Hola, me comunico con MO Eventual. Mi nombre es ..."}`;

export const linkedInURL =
  "https://www.linkedin.com/in/mco-nexo-laboral-srl-a333953a/";

export const facebookURL =
  "https://www.facebook.com/mconexomaritaortiz/?ref=aymt_homepage_panel";

export const instagramURL = "https://www.instagram.com/mco.nexolaboralsrl/";

export const twitterURL = "https://twitter.com/McoNexo";

export const subAreas = [
  {
    id: 1,
    value: "administracion",
    subareas: [
      { value: "administracion", label: "administracion" },
      { value: "contabilidad", label: "contabilidad" },
      { value: "impuestos", label: "impuestos" },
      { value: "recursoshumanos", label: "recursos humanos" },
      { value: "ventas", label: "ventas" },
      { value: "compras", label: "compras" },
      { value: "auditoria", label: "auditoria" },
    ],
  },
  {
    id: 2,
    value: "callcenter",
    subareas: [
      { value: "callcenter", label: "CallCenter" },
      { value: "telemercadeo", label: "Telemercadeo" },
    ],
  },
  {
    id: 3,
    value: "publicidad",
    subareas: [
      { value: "creativo", label: "Área Creativa" },
      { value: "cuentas", label: "Área de Cuentas" },
      { value: "investigacion", label: "Área de Investigación y Mercadeo" },
      { value: "medios", label: "Área de Medios" },
      { value: "produccion", label: "Área de Producción" },
    ],
  },
  {
    id: 4,
    value: "hoteleria",
    subareas: [
      { value: "admhotelera", label: "Administracion Hotelera" },
      { value: "housekeeping", label: "Housekeeping" },
      { value: "gastronomia", label: "Gastronomía" },
    ],
  },
  {
    id: 5,
    value: "informatica",
    subareas: [
      { value: "programador", label: "Programador" },
      { value: "sistoperativos", label: "Sistemas Operativos" },
      { value: "sistredes", label: "Sistemas de Redes" },
      { value: "soportetecnico", label: "IT" },
    ],
  },
  {
    id: 6,
    value: "logistica",
    subareas: [
      { value: "almacenamiento", label: "Logística de Almacenamiento" },
      { value: "produccion", label: "Logística de Producción" },
      { value: "distribucion", label: "Logística de Distribución" },
      { value: "inversa", label: "Logística Inversa" },
    ],
  },
  {
    id: 7,
    value: "ingenieria",
    subareas: [
      { value: "mecanica", label: "Mecánica" },
      { value: "informatica", label: "Informática/Sistemas" },
      { value: "industrial", label: "Industrial" },
      { value: "procesos", label: "En Procesos/Química" },
      { value: "naval", label: "Naval" },
      { value: "civil", label: "Civil" },
      { value: "sistemas", label: "Analista en Sistemas" },
    ],
  },
  {
    id: 8,
    value: "atenciónclientes",
    subareas: [
      { value: "recepcionista", label: "Recepcionista" },
      { value: "comercial", label: "Comercial" },
      { value: "profesional", label: "Profesionales/Consultorios/Legal" },
    ],
  },
  {
    id: 9,
    value: "comercioexterior",
    subareas: [
      { value: "mantenimiento", label: "Despacho de Aduana" },
      { value: "maestranza", label: "Almacenamiento y Distribución" },
    ],
  },
  {
    id: 10,
    value: "construccion",
    subareas: [
      { value: "directordeobra", label: "Director de Obra" },
      { value: "ingenierocivil", label: "Ingeniero Civil" },
      { value: "obrero", label: "Obrero" },
    ],
  },
  {
    id: 11,
    value: "gerencia",
    subareas: [
      { value: "general", label: "General" },
      { value: "produccion", label: "Producción" },
      { value: "venta", label: "Venta" },
      { value: "administracion", label: "Administración" },
      { value: "rrhh", label: "RRHH" },
      { value: "", label: "" },
    ],
  },
  {
    id: 12,
    value: "mantenimiento",
    subareas: [
      { value: "mantenimiento", label: "Reparaciones/Mantenimiento" },
      { value: "limpieza", label: "Limpieza" },
    ],
  },
  {
    id: 13,
    value: "legal",
    subareas: [
      { value: "", label: "Contratos" },
      { value: "", label: "Tributario" },
    ],
  },
  {
    id: 14,
    value: "salud",
    subareas: [
      { value: "", label: " " },
      { value: "", label: "" },
      { value: "", label: " " },
    ],
  },
  {
    id: 15,
    value: "educacion",
    subareas: [
      { value: "", label: " " },
      { value: "", label: "" },
      { value: "", label: " " },
    ],
  },
];

export const ciudadesArgentinas = [
  {
    id: 6,
    value: "Buenos Aires",
    label: "Buenos Aires",
    ciudades: [
      { value: "Abasto", label: "Abasto" },
      { value: "Abbott", label: "Abbott" },
      { value: "Acassuso", label: "Acassuso" },
      { value: "Acevedo", label: "Acevedo" },
      {
        value: "Adolfo Gonzales Chaves (Est. Chaves)",
        label: "Adolfo Gonzales Chaves (Est. Chaves)",
      },
      { value: "Adrogue", label: "Adrogue" },
      {
        value: "Aeropuerto Internacional Ezeiza",
        label: "Aeropuerto Internacional Ezeiza",
      },
      { value: "Aguas Verdes", label: "Aguas Verdes" },
      { value: "Agustin Mosconi", label: "Agustin Mosconi" },
      { value: "Agustin Roca", label: "Agustin Roca" },
      { value: "Agustina", label: "Agustina" },
      { value: "Alberdi Viejo", label: "Alberdi Viejo" },
      {
        value: "Alberti (Est. Andres Vaccarezza)",
        label: "Alberti (Est. Andres Vaccarezza)",
      },
      { value: "Aldo Bonzi", label: "Aldo Bonzi" },
      { value: "Alejandro Korn", label: "Alejandro Korn" },
      { value: "Alejandro Petion", label: "Alejandro Petion" },
      {
        value: "Alfredo Demarchi (Est. Facundo Quiroga)",
        label: "Alfredo Demarchi (Est. Facundo Quiroga)",
      },
      { value: "Almirante Brown", label: "Almirante Brown" },
      { value: "Altamirano", label: "Altamirano" },
      { value: "Alto Los Cardales", label: "Alto Los Cardales" },
      { value: "Álvarez De Toledo", label: "Álvarez De Toledo" },
      { value: "Alvarez Jonte", label: "Alvarez Jonte" },
      { value: "America", label: "America" },
      { value: "Andant", label: "Andant" },
      { value: "Angel Etcheverry", label: "Angel Etcheverry" },
      { value: "Antonio Carboni", label: "Antonio Carboni" },
      { value: "Aparicio", label: "Aparicio" },
      { value: "Arana", label: "Arana" },
      { value: "Arboledas", label: "Arboledas" },
      { value: "Área Cinturon Ecologico", label: "Área Cinturon Ecologico" },
      {
        value: "Area De Promocion El Triangulo",
        label: "Area De Promocion El Triangulo",
      },
      { value: "Arenas Verdes", label: "Arenas Verdes" },
      { value: "Arenaza", label: "Arenaza" },
      { value: "Argerich", label: "Argerich" },
      { value: "Ariel", label: "Ariel" },
      { value: "Arrecifes", label: "Arrecifes" },
      { value: "Arribeños", label: "Arribeños" },
      { value: "Arroyo Corto", label: "Arroyo Corto" },
      { value: "Arroyo De La Cruz", label: "Arroyo De La Cruz" },
      { value: "Arroyo Dulce", label: "Arroyo Dulce" },
      { value: "Arroyo Venado", label: "Arroyo Venado" },
      { value: "Arturo Segui", label: "Arturo Segui" },
      { value: "Ascension", label: "Ascension" },
      { value: "Atalaya", label: "Atalaya" },
      { value: "Atlantida", label: "Atlantida" },
      { value: "Avellaneda", label: "Avellaneda" },
      { value: "Ayacucho", label: "Ayacucho" },
      { value: "Azcuenaga", label: "Azcuenaga" },
      { value: "Azopardo", label: "Azopardo" },
      { value: "Azul", label: "Azul" },
      { value: "Bahia Blanca", label: "Bahia Blanca" },
      { value: "Bahia San Blas", label: "Bahia San Blas" },
      { value: "Baigorrita", label: "Baigorrita" },
      { value: "Bajo Hondo", label: "Bajo Hondo" },
      { value: "Balcarce", label: "Balcarce" },
      {
        value: "Balneario Laguna De Gomez",
        label: "Balneario Laguna De Gomez",
      },
      { value: "Balneario Marisol", label: "Balneario Marisol" },
      { value: "Balneario Orense", label: "Balneario Orense" },
      { value: "Balneario Pehuen Co", label: "Balneario Pehuen Co" },
      { value: "Balneario San Cayetano", label: "Balneario San Cayetano" },
      { value: "Balneario Sauce Grande", label: "Balneario Sauce Grande" },
      { value: "Banderalo", label: "Banderalo" },
      { value: "Banfield", label: "Banfield" },
      { value: "Baradero", label: "Baradero" },
      { value: "Barker", label: "Barker" },
      { value: "Barrio America Unida", label: "Barrio America Unida" },
      { value: "Barrio Banco Provincia", label: "Barrio Banco Provincia" },
      { value: "Barrio Belgrano", label: "Barrio Belgrano" },
      { value: "Barrio Colinas Verdes", label: "Barrio Colinas Verdes" },
      { value: "Barrio El Boqueron", label: "Barrio El Boqueron" },
      { value: "Barrio El Carmen (Este)", label: "Barrio El Carmen (Este)" },
      { value: "Barrio El Carmen (Oeste)", label: "Barrio El Carmen (Oeste)" },
      { value: "Barrio El Casal", label: "Barrio El Casal" },
      { value: "Barrio El Coyunco", label: "Barrio El Coyunco" },
      { value: "Barrio El Taladro", label: "Barrio El Taladro" },
      { value: "Barrio Gambier", label: "Barrio Gambier" },
      { value: "Barrio Kennedy", label: "Barrio Kennedy" },
      { value: "Barrio La Gloria", label: "Barrio La Gloria" },
      { value: "Barrio Las Casuarinas", label: "Barrio Las Casuarinas" },
      { value: "Barrio Las Golondrinas", label: "Barrio Las Golondrinas" },
      { value: "Barrio Las Malvinas", label: "Barrio Las Malvinas" },
      { value: "Barrio Las Quintas", label: "Barrio Las Quintas" },
      { value: "Barrio Los Bosquecitos", label: "Barrio Los Bosquecitos" },
      { value: "Barrio Los Pioneros", label: "Barrio Los Pioneros" },
      {
        value: "Barrio Parque Almirante Irizar (Ap. Kilometro 61)",
        label: "Barrio Parque Almirante Irizar (Ap. Kilometro 61)",
      },
      {
        value: "Barrio Parque General San Martin",
        label: "Barrio Parque General San Martin",
      },
      {
        value: "Barrio Parque Las Acacias",
        label: "Barrio Parque Las Acacias",
      },
      { value: "Barrio Rio Salado", label: "Barrio Rio Salado" },
      {
        value: "Barrio Ruta 24 Kilometro 10",
        label: "Barrio Ruta 24 Kilometro 10",
      },
      { value: "Barrio Saavedra", label: "Barrio Saavedra" },
      { value: "Barrio Santa Paula", label: "Barrio Santa Paula" },
      { value: "Barrio Santa Rosa", label: "Barrio Santa Rosa" },
      { value: "Barrio Universitario", label: "Barrio Universitario" },
      {
        value: "Barrios Lisandro De La Torre Y Santa Marta",
        label: "Barrios Lisandro De La Torre Y Santa Marta",
      },
      { value: "Batan", label: "Batan" },
      { value: "Bayauca", label: "Bayauca" },
      { value: "Beccar", label: "Beccar" },
      { value: "Belen De Escobar", label: "Belen De Escobar" },
      { value: "Bella Vista", label: "Bella Vista" },
      { value: "Bellocq", label: "Bellocq" },
      { value: "Benavidez", label: "Benavidez" },
      {
        value: "Benito Juarez (Est. Juarez)",
        label: "Benito Juarez (Est. Juarez)",
      },
      { value: "Berazategui", label: "Berazategui" },
      { value: "Berazategui Oeste", label: "Berazategui Oeste" },
      { value: "Berdier", label: "Berdier" },
      { value: "Berisso", label: "Berisso" },
      { value: "Bermudez", label: "Bermudez" },
      { value: "Bernal", label: "Bernal" },
      { value: "Bernal Oeste", label: "Bernal Oeste" },
      { value: "Berutti", label: "Berutti" },
      { value: "Billinghurst", label: "Billinghurst" },
      { value: "Blancagrande", label: "Blancagrande" },
      { value: "Blaquier", label: "Blaquier" },
      { value: "Blaquier", label: "Blaquier" },
      { value: "Bocayuva", label: "Bocayuva" },
      { value: "Bordenave", label: "Bordenave" },
      { value: "Bosques", label: "Bosques" },
      { value: "Boulogne Sur Mer", label: "Boulogne Sur Mer" },
      { value: "Bragado", label: "Bragado" },
      { value: "Burzaco", label: "Burzaco" },
      { value: "Cabildo", label: "Cabildo" },
      { value: "Cachari", label: "Cachari" },
      { value: "Cadret", label: "Cadret" },
      { value: "Camet", label: "Camet" },
      { value: "Camet Norte", label: "Camet Norte" },
      { value: "Campana", label: "Campana" },
      { value: "Campo De Mayo", label: "Campo De Mayo" },
      { value: "Campos Salles", label: "Campos Salles" },
      { value: "Canning", label: "Canning" },
      { value: "Canning", label: "Canning" },
      { value: "Cañada Seca", label: "Cañada Seca" },
      { value: "Cañuelas", label: "Cañuelas" },
      {
        value: "Capilla Del Señor (Est. Capilla)",
        label: "Capilla Del Señor (Est. Capilla)",
      },
      { value: "Capitan Castro", label: "Capitan Castro" },
      { value: "Capitan Sarmiento", label: "Capitan Sarmiento" },
      { value: "Carapachay", label: "Carapachay" },
      { value: "Cardenal Cagliero", label: "Cardenal Cagliero" },
      { value: "Carhue", label: "Carhue" },
      { value: "Carilo", label: "Carilo" },
      { value: "Carlos Beguerie", label: "Carlos Beguerie" },
      { value: "Carlos Casares", label: "Carlos Casares" },
      { value: "Carlos Keen", label: "Carlos Keen" },
      { value: "Carlos Maria Naon", label: "Carlos Maria Naon" },
      { value: "Carlos Salas", label: "Carlos Salas" },
      { value: "Carlos Spegazzini", label: "Carlos Spegazzini" },
      { value: "Carlos Tejedor", label: "Carlos Tejedor" },
      { value: "Carlos Tomas Sourigues", label: "Carlos Tomas Sourigues" },
      { value: "Carmen De Areco", label: "Carmen De Areco" },
      { value: "Carmen De Patagones", label: "Carmen De Patagones" },
      { value: "Casalins", label: "Casalins" },
      { value: "Casbas", label: "Casbas" },
      { value: "Cascadas", label: "Cascadas" },
      { value: "Caseros", label: "Caseros" },
      { value: "Castelar", label: "Castelar" },
      { value: "Castelli", label: "Castelli" },
      { value: "Castilla", label: "Castilla" },
      { value: "Cazon", label: "Cazon" },
      { value: "Centro Guerrero", label: "Centro Guerrero" },
      { value: "Chacabuco", label: "Chacabuco" },
      { value: "Chacras De San Clemente", label: "Chacras De San Clemente" },
      { value: "Chapadmalal", label: "Chapadmalal" },
      { value: "Chascomus", label: "Chascomus" },
      { value: "Chascomus Country Club", label: "Chascomus Country Club" },
      { value: "Chasico", label: "Chasico" },
      { value: "Chiclana", label: "Chiclana" },
      { value: "Chillar", label: "Chillar" },
      { value: "Chivilcoy", label: "Chivilcoy" },
      { value: "Churruca", label: "Churruca" },
      { value: "City Bell", label: "City Bell" },
      { value: "Ciudad Evita", label: "Ciudad Evita" },
      {
        value: "Ciudad Jardin El Libertador",
        label: "Ciudad Jardin El Libertador",
      },
      {
        value: "Ciudad Jardin Lomas Del Palomar",
        label: "Ciudad Jardin Lomas Del Palomar",
      },
      { value: "Ciudadela", label: "Ciudadela" },
      { value: "Claraz", label: "Claraz" },
      { value: "Claromeco", label: "Claromeco" },
      { value: "Claypole", label: "Claypole" },
      {
        value: "Club De Campo Larena - Los Quinchos",
        label: "Club De Campo Larena - Los Quinchos",
      },
      { value: "Colon", label: "Colon" },
      { value: "Colonia Hinojo", label: "Colonia Hinojo" },
      { value: "Colonia Mauricio", label: "Colonia Mauricio" },
      { value: "Colonia Nievas", label: "Colonia Nievas" },
      { value: "Colonia San Adolfo", label: "Colonia San Adolfo" },
      { value: "Colonia San Martin", label: "Colonia San Martin" },
      { value: "Colonia San Miguel", label: "Colonia San Miguel" },
      {
        value: "Colonia San Miguel Arcangel",
        label: "Colonia San Miguel Arcangel",
      },
      {
        value: "Colonia San Ricardo (Est. Iriarte)",
        label: "Colonia San Ricardo (Est. Iriarte)",
      },
      { value: "Colonia Sere", label: "Colonia Sere" },
      {
        value: "Comandante Nicanor Otamendi",
        label: "Comandante Nicanor Otamendi",
      },
      { value: "Comodoro Py", label: "Comodoro Py" },
      { value: "Conesa", label: "Conesa" },
      { value: "Copetonas", label: "Copetonas" },
      { value: "Coronel Boerr", label: "Coronel Boerr" },
      { value: "Coronel Brandsen", label: "Coronel Brandsen" },
      { value: "Coronel Charlone", label: "Coronel Charlone" },
      { value: "Coronel Dorrego", label: "Coronel Dorrego" },
      {
        value: "Coronel Martinez De Hoz (Ap. Kilometro 322)",
        label: "Coronel Martinez De Hoz (Ap. Kilometro 322)",
      },
      {
        value: "Coronel Pringles (Est. Pringles)",
        label: "Coronel Pringles (Est. Pringles)",
      },
      { value: "Coronel Segui", label: "Coronel Segui" },
      { value: "Coronel Suarez", label: "Coronel Suarez" },
      { value: "Coronel Vidal", label: "Coronel Vidal" },
      { value: "Cortines", label: "Cortines" },
      { value: "Costa Bonita", label: "Costa Bonita" },
      {
        value: "Country Club Bosque Real - Barrio Morabo",
        label: "Country Club Bosque Real - Barrio Morabo",
      },
      { value: "Country Club El Casco", label: "Country Club El Casco" },
      { value: "Country Club El JagÜEL", label: "Country Club El JagÜEL" },
      { value: "Country Club El Rodeo", label: "Country Club El Rodeo" },
      {
        value: "Country Club Las Praderas",
        label: "Country Club Las Praderas",
      },
      { value: "Country Los Medanos", label: "Country Los Medanos" },
      { value: "Crotto", label: "Crotto" },
      { value: "Crucesita", label: "Crucesita" },
      { value: "Cuartel V", label: "Cuartel V" },
      { value: "Cucullu", label: "Cucullu" },
      { value: "Cura Malal", label: "Cura Malal" },
      { value: "Curaru", label: "Curaru" },
      { value: "Daireaux", label: "Daireaux" },
      { value: "Darregueira", label: "Darregueira" },
      { value: "De Bary", label: "De Bary" },
      { value: "De La Canal", label: "De La Canal" },
      { value: "De La Garma", label: "De La Garma" },
      { value: "Del Carril", label: "Del Carril" },
      { value: "Del Valle", label: "Del Valle" },
      { value: "Del Viso", label: "Del Viso" },
      { value: "Del Viso", label: "Del Viso" },
      { value: "Delfin Huergo", label: "Delfin Huergo" },
      { value: "Desvio Aguirre", label: "Desvio Aguirre" },
      { value: "Diego Gaynor", label: "Diego Gaynor" },
      { value: "Dique Lujan", label: "Dique Lujan" },
      { value: "Dique N 1", label: "Dique N 1" },
      { value: "Dock Sud", label: "Dock Sud" },
      { value: "Dolores", label: "Dolores" },
      { value: "Domselaar", label: "Domselaar" },
      { value: "Don Bosco", label: "Don Bosco" },
      { value: "Don Orione", label: "Don Orione" },
      { value: "Don Torcuato Este", label: "Don Torcuato Este" },
      { value: "Don Torcuato Oeste", label: "Don Torcuato Oeste" },
      { value: "D'Orbigny", label: "D'Orbigny" },
      { value: "Dudignac", label: "Dudignac" },
      { value: "Dufaur", label: "Dufaur" },
      { value: "Duggan", label: "Duggan" },
      { value: "Dunamar", label: "Dunamar" },
      { value: "El Arbolito", label: "El Arbolito" },
      { value: "El Cazador", label: "El Cazador" },
      { value: "El Divisorio", label: "El Divisorio" },
      { value: "El Dorado", label: "El Dorado" },
      { value: "El JagÜEL", label: "El JagÜEL" },
      { value: "El Libertador", label: "El Libertador" },
      { value: "El Marquesado", label: "El Marquesado" },
      { value: "El Palomar", label: "El Palomar" },
      { value: "El Paraiso", label: "El Paraiso" },
      { value: "El Pato", label: "El Pato" },
      { value: "El Pensamiento", label: "El Pensamiento" },
      {
        value: "El Perdido (Est. Jose A. Guisasola)",
        label: "El Perdido (Est. Jose A. Guisasola)",
      },
      { value: "El Remanso", label: "El Remanso" },
      { value: "El Retiro", label: "El Retiro" },
      { value: "El Talar", label: "El Talar" },
      { value: "El Trigo", label: "El Trigo" },
      { value: "El Triunfo", label: "El Triunfo" },
      { value: "Elvira", label: "Elvira" },
      { value: "Emilio Ayarza", label: "Emilio Ayarza" },
      { value: "Emilio V. Bunge", label: "Emilio V. Bunge" },
      { value: "Ensenada", label: "Ensenada" },
      { value: "Erezcano", label: "Erezcano" },
      { value: "Ernestina", label: "Ernestina" },
      { value: "Escalada", label: "Escalada" },
      { value: "Escobar", label: "Escobar" },
      { value: "Espartillar", label: "Espartillar" },
      { value: "Espartillar", label: "Espartillar" },
      { value: "Espigas", label: "Espigas" },
      { value: "Estacion Arenales", label: "Estacion Arenales" },
      { value: "Estacion Camet", label: "Estacion Camet" },
      { value: "Estacion Chapadmalal", label: "Estacion Chapadmalal" },
      {
        value: "Estanislao Severo Zeballos",
        label: "Estanislao Severo Zeballos",
      },
      { value: "Esteban Agustin Gascon", label: "Esteban Agustin Gascon" },
      { value: "Esteban Echeverria", label: "Esteban Echeverria" },
      { value: "Estela", label: "Estela" },
      { value: "Ezpeleta", label: "Ezpeleta" },
      { value: "Ezpeleta Oeste", label: "Ezpeleta Oeste" },
      { value: "Faro", label: "Faro" },
      { value: "Fatima", label: "Fatima" },
      { value: "Felipe Sola", label: "Felipe Sola" },
      { value: "Ferre", label: "Ferre" },
      { value: "Florencio Varela", label: "Florencio Varela" },
      { value: "Florentino Ameghino", label: "Florentino Ameghino" },
      { value: "Florida", label: "Florida" },
      { value: "Florida Oeste", label: "Florida Oeste" },
      { value: "Fontezuela", label: "Fontezuela" },
      { value: "Fortin Acha", label: "Fortin Acha" },
      { value: "Fortin Olavarria", label: "Fortin Olavarria" },
      { value: "Fortin Tiburcio", label: "Fortin Tiburcio" },
      { value: "Francisco Alvarez", label: "Francisco Alvarez" },
      { value: "Francisco Madero", label: "Francisco Madero" },
      { value: "Franklin", label: "Franklin" },
      { value: "Frente Mar", label: "Frente Mar" },
      { value: "Gahan", label: "Gahan" },
      { value: "Gardey", label: "Gardey" },
      { value: "Garin", label: "Garin" },
      { value: "Garre", label: "Garre" },
      { value: "General Alvear", label: "General Alvear" },
      { value: "General Arenales", label: "General Arenales" },
      { value: "General Belgrano", label: "General Belgrano" },
      { value: "General Conesa", label: "General Conesa" },
      {
        value: "General Daniel Cerri (Est. General Cerri)",
        label: "General Daniel Cerri (Est. General Cerri)",
      },
      { value: "General Guido", label: "General Guido" },
      { value: "General Hornos", label: "General Hornos" },
      { value: "General Juan Madariaga", label: "General Juan Madariaga" },
      { value: "General La Madrid", label: "General La Madrid" },
      {
        value: "General Las Heras (Est. Las Heras)",
        label: "General Las Heras (Est. Las Heras)",
      },
      { value: "General Lavalle", label: "General Lavalle" },
      {
        value: "General Mansilla (Est. Bartolome Bavio)",
        label: "General Mansilla (Est. Bartolome Bavio)",
      },
      { value: "General O'Brien", label: "General O'Brien" },
      { value: "General Pacheco", label: "General Pacheco" },
      { value: "General Pinto", label: "General Pinto" },
      { value: "General Piran", label: "General Piran" },
      { value: "General Rivas", label: "General Rivas" },
      { value: "General Rodriguez", label: "General Rodriguez" },
      { value: "General Rojo", label: "General Rojo" },
      { value: "General San Martin", label: "General San Martin" },
      {
        value: "General Villegas (Est. Villegas)",
        label: "General Villegas (Est. Villegas)",
      },
      { value: "Gerli", label: "Gerli" },
      { value: "Gerli", label: "Gerli" },
      {
        value: "Germania (Est. Mayor Jose Orellano)",
        label: "Germania (Est. Mayor Jose Orellano)",
      },
      { value: "Girodias", label: "Girodias" },
      { value: "Glew", label: "Glew" },
      { value: "Gobernador Castro", label: "Gobernador Castro" },
      {
        value: "Gobernador Julio A. Costa",
        label: "Gobernador Julio A. Costa",
      },
      { value: "Gobernador Udaondo", label: "Gobernador Udaondo" },
      { value: "Gobernador Ugarte", label: "Gobernador Ugarte" },
      { value: "Gomez", label: "Gomez" },
      { value: "Gonzalez Catan", label: "Gonzalez Catan" },
      { value: "Gonzalez Moreno", label: "Gonzalez Moreno" },
      { value: "Gorchs", label: "Gorchs" },
      { value: "Gorostiaga", label: "Gorostiaga" },
      { value: "Gowland", label: "Gowland" },
      { value: "Goyena", label: "Goyena" },
      { value: "Grand Bourg", label: "Grand Bourg" },
      { value: "Gregorio De Laferrere", label: "Gregorio De Laferrere" },
      { value: "GrÜNBEIN", label: "GrÜNBEIN" },
      { value: "Guamini", label: "Guamini" },
      { value: "Guernica", label: "Guernica" },
      { value: "Guerrico", label: "Guerrico" },
      { value: "Guillermo Enrique Hudson", label: "Guillermo Enrique Hudson" },
      { value: "Haedo", label: "Haedo" },
      { value: "Hale", label: "Hale" },
      { value: "Henderson", label: "Henderson" },
      { value: "Herrera Vegas", label: "Herrera Vegas" },
      { value: "Hilario Ascasubi", label: "Hilario Ascasubi" },
      { value: "Hinojo", label: "Hinojo" },
      { value: "Hortensia", label: "Hortensia" },
      { value: "Huanguelen", label: "Huanguelen" },
      { value: "Hurlingham", label: "Hurlingham" },
      { value: "Ignacio Correas", label: "Ignacio Correas" },
      { value: "Indio Rico", label: "Indio Rico" },
      { value: "Ines Indart", label: "Ines Indart" },
      {
        value: "Ingeniero Adolfo Sourdeaux",
        label: "Ingeniero Adolfo Sourdeaux",
      },
      { value: "Ingeniero Juan Allan", label: "Ingeniero Juan Allan" },
      { value: "Ingeniero Maschwitz", label: "Ingeniero Maschwitz" },
      { value: "Ingeniero Pablo Nogues", label: "Ingeniero Pablo Nogues" },
      { value: "Ingeniero Thompson", label: "Ingeniero Thompson" },
      { value: "Ingeniero White", label: "Ingeniero White" },
      { value: "Irala", label: "Irala" },
      { value: "Irene", label: "Irene" },
      { value: "Irineo Portela", label: "Irineo Portela" },
      { value: "Isidro Casanova", label: "Isidro Casanova" },
      { value: "Isla Santiago (Oeste)", label: "Isla Santiago (Oeste)" },
      { value: "Ituzaingo Centro", label: "Ituzaingo Centro" },
      { value: "Ituzaingo Sur", label: "Ituzaingo Sur" },
      { value: "Jeppener", label: "Jeppener" },
      { value: "Joaquin Gorina", label: "Joaquin Gorina" },
      { value: "Jose B. Casas", label: "Jose B. Casas" },
      { value: "Jose C. Paz", label: "Jose C. Paz" },
      { value: "Jose Hernandez", label: "Jose Hernandez" },
      { value: "Jose Ingenieros", label: "Jose Ingenieros" },
      { value: "Jose Juan Almeyra", label: "Jose Juan Almeyra" },
      { value: "Jose Maria Ezeiza", label: "Jose Maria Ezeiza" },
      {
        value: "Jose Maria Jauregui (Est. Jauregui)",
        label: "Jose Maria Jauregui (Est. Jauregui)",
      },
      { value: "Jose Marmol", label: "Jose Marmol" },
      { value: "Jose Melchor Romero", label: "Jose Melchor Romero" },
      { value: "Juan A. De La Peña", label: "Juan A. De La Peña" },
      { value: "Juan A. Pradere", label: "Juan A. Pradere" },
      {
        value: "Juan Anchorena (Est. Urquiza)",
        label: "Juan Anchorena (Est. Urquiza)",
      },
      {
        value: "Juan Bautista Alberdi (Est. Alberdi)",
        label: "Juan Bautista Alberdi (Est. Alberdi)",
      },
      {
        value: "Juan Couste (Est. Algarrobo)",
        label: "Juan Couste (Est. Algarrobo)",
      },
      { value: "Juan E. Barra", label: "Juan E. Barra" },
      { value: "Juan F. Ibarra", label: "Juan F. Ibarra" },
      { value: "Juan Jose Paso", label: "Juan Jose Paso" },
      { value: "Juan Maria Gutierrez", label: "Juan Maria Gutierrez" },
      { value: "Juan N. Fernandez", label: "Juan N. Fernandez" },
      { value: "Junin", label: "Junin" },
      { value: "La Angelita", label: "La Angelita" },
      { value: "La Armonia", label: "La Armonia" },
      { value: "La Aurora (Est. La Niña)", label: "La Aurora (Est. La Niña)" },
      { value: "La Baliza", label: "La Baliza" },
      { value: "La Beba", label: "La Beba" },
      { value: "La Caleta", label: "La Caleta" },
      { value: "La Capilla", label: "La Capilla" },
      { value: "La Carreta", label: "La Carreta" },
      { value: "La Choza", label: "La Choza" },
      { value: "La Colina", label: "La Colina" },
      { value: "La Constancia", label: "La Constancia" },
      { value: "La Cumbre", label: "La Cumbre" },
      { value: "La Delfina", label: "La Delfina" },
      { value: "La Emilia", label: "La Emilia" },
      { value: "La Invencible", label: "La Invencible" },
      { value: "La Larga", label: "La Larga" },
      { value: "La Limpia", label: "La Limpia" },
      { value: "La Lonja", label: "La Lonja" },
      { value: "La Lucila", label: "La Lucila" },
      { value: "La Luisa", label: "La Luisa" },
      { value: "La Manuela", label: "La Manuela" },
      { value: "La Matanza", label: "La Matanza" },
      { value: "La Pala", label: "La Pala" },
      { value: "La Plata", label: "La Plata" },
      { value: "La Reja", label: "La Reja" },
      { value: "La Rica", label: "La Rica" },
      { value: "La Sofia", label: "La Sofia" },
      { value: "La Tablada", label: "La Tablada" },
      { value: "La Trinidad", label: "La Trinidad" },
      { value: "La Union", label: "La Union" },
      { value: "La Violeta", label: "La Violeta" },
      { value: "Labarden", label: "Labarden" },
      {
        value: "Laguna Alsina (Est. Bonifacio)",
        label: "Laguna Alsina (Est. Bonifacio)",
      },
      { value: "Laguna De Lobos", label: "Laguna De Lobos" },
      { value: "Lanus Este", label: "Lanus Este" },
      { value: "Lanus Oeste", label: "Lanus Oeste" },
      { value: "Laplacette", label: "Laplacette" },
      { value: "Laprida", label: "Laprida" },
      { value: "Lartigau", label: "Lartigau" },
      { value: "Las Armas", label: "Las Armas" },
      { value: "Las Bahamas", label: "Las Bahamas" },
      { value: "Las Carabelas", label: "Las Carabelas" },
      { value: "Las Flores", label: "Las Flores" },
      { value: "Las Marianas", label: "Las Marianas" },
      { value: "Las Martinetas", label: "Las Martinetas" },
      { value: "Las Tahonas", label: "Las Tahonas" },
      { value: "Las Toninas", label: "Las Toninas" },
      { value: "Las Toscas", label: "Las Toscas" },
      { value: "Leandro N. Alem", label: "Leandro N. Alem" },
      { value: "Lezica Y Torrezuri", label: "Lezica Y Torrezuri" },
      { value: "Libano", label: "Libano" },
      { value: "Libertad", label: "Libertad" },
      { value: "Licenciado Matienzo", label: "Licenciado Matienzo" },
      { value: "Lima", label: "Lima" },
      { value: "Lin Calel", label: "Lin Calel" },
      { value: "Lincoln", label: "Lincoln" },
      { value: "Lisandro Olmos", label: "Lisandro Olmos" },
      { value: "Llavallol", label: "Llavallol" },
      { value: "Loberia", label: "Loberia" },
      { value: "Lobos", label: "Lobos" },
      { value: "Loma Hermosa", label: "Loma Hermosa" },
      { value: "Loma Verde", label: "Loma Verde" },
      { value: "Loma Verde", label: "Loma Verde" },
      { value: "Lomas De Copello", label: "Lomas De Copello" },
      { value: "Lomas De Zamora", label: "Lomas De Zamora" },
      { value: "Lomas Del Mirador", label: "Lomas Del Mirador" },
      {
        value: "Lomas Del Rio Lujan (Est. Rio Lujan)",
        label: "Lomas Del Rio Lujan (Est. Rio Lujan)",
      },
      { value: "Longchamps", label: "Longchamps" },
      { value: "Lopez", label: "Lopez" },
      { value: "Lopez Lecube", label: "Lopez Lecube" },
      { value: "Los Angeles", label: "Los Angeles" },
      { value: "Los Cachorros", label: "Los Cachorros" },
      { value: "Los Cardales", label: "Los Cardales" },
      { value: "Los Hornos", label: "Los Hornos" },
      { value: "Los Indios", label: "Los Indios" },
      { value: "Los Naranjos", label: "Los Naranjos" },
      { value: "Los Pinos", label: "Los Pinos" },
      { value: "Los Polvorines", label: "Los Polvorines" },
      { value: "Los Talas", label: "Los Talas" },
      { value: "Los Toldos", label: "Los Toldos" },
      { value: "Los Troncos Del Talar", label: "Los Troncos Del Talar" },
      { value: "Lozano", label: "Lozano" },
      { value: "Lucas Monteverde", label: "Lucas Monteverde" },
      { value: "Lucila Del Mar", label: "Lucila Del Mar" },
      { value: "Luis Guillon", label: "Luis Guillon" },
      { value: "Lujan", label: "Lujan" },
      { value: "Magdala", label: "Magdala" },
      { value: "Magdalena", label: "Magdalena" },
      { value: "Maipu", label: "Maipu" },
      { value: "Malvinas Argentinas", label: "Malvinas Argentinas" },
      { value: "Malvinas Argentinas", label: "Malvinas Argentinas" },
      { value: "Manuel B. Gonnet", label: "Manuel B. Gonnet" },
      {
        value: "Manuel B. Gonnet (Est. French)",
        label: "Manuel B. Gonnet (Est. French)",
      },
      {
        value: "Manuel J. Cobo (Est. Lezama)",
        label: "Manuel J. Cobo (Est. Lezama)",
      },
      { value: "Manuel Ocampo", label: "Manuel Ocampo" },
      { value: "Manzanares", label: "Manzanares" },
      { value: "Manzone", label: "Manzone" },
      { value: "Maquinista F. Savio Este", label: "Maquinista F. Savio Este" },
      {
        value: "Maquinista F. Savio Oeste",
        label: "Maquinista F. Savio Oeste",
      },
      { value: "Mar Azul", label: "Mar Azul" },
      { value: "Mar Chiquita", label: "Mar Chiquita" },
      { value: "Mar De Ajo", label: "Mar De Ajo" },
      { value: "Mar De Ajo Norte", label: "Mar De Ajo Norte" },
      { value: "Mar De Cobo", label: "Mar De Cobo" },
      { value: "Mar De Las Pampas", label: "Mar De Las Pampas" },
      { value: "Mar Del Plata", label: "Mar Del Plata" },
      { value: "Mar Del Sur", label: "Mar Del Sur" },
      { value: "Mar Del Tuyu", label: "Mar Del Tuyu" },
      {
        value: "Marcelino Ugarte (Est. Dennehy)",
        label: "Marcelino Ugarte (Est. Dennehy)",
      },
      { value: "Marcos Paz", label: "Marcos Paz" },
      {
        value: "Maria Ignacia (Est. Vela)",
        label: "Maria Ignacia (Est. Vela)",
      },
      { value: "Mariano Acosta", label: "Mariano Acosta" },
      { value: "Mariano Benitez", label: "Mariano Benitez" },
      {
        value: "Mariano H. Alfonzo (Est. San Patricio)",
        label: "Mariano H. Alfonzo (Est. San Patricio)",
      },
      { value: "Mariano Unzue", label: "Mariano Unzue" },
      { value: "Martin Coronado", label: "Martin Coronado" },
      { value: "Martinez", label: "Martinez" },
      { value: "Massey (Est. Elordi)", label: "Massey (Est. Elordi)" },
      { value: "Matheu", label: "Matheu" },
      { value: "Mauricio Hirsch", label: "Mauricio Hirsch" },
      {
        value: "Maximo Fernandez (Est. Juan F. Salaberry)",
        label: "Maximo Fernandez (Est. Juan F. Salaberry)",
      },
      { value: "Maximo Paz", label: "Maximo Paz" },
      { value: "Mayor Buratovich", label: "Mayor Buratovich" },
      { value: "Maza", label: "Maza" },
      { value: "Mechita", label: "Mechita" },
      { value: "Mechita (Est. Mecha)", label: "Mechita (Est. Mecha)" },
      { value: "Mechongue", label: "Mechongue" },
      { value: "Medanos", label: "Medanos" },
      { value: "Mercedes", label: "Mercedes" },
      { value: "Merlo", label: "Merlo" },
      {
        value: "Micaela Cascallares (Est. Cascallares)",
        label: "Micaela Cascallares (Est. Cascallares)",
      },
      { value: "Ministro Rivadavia", label: "Ministro Rivadavia" },
      { value: "Mira Pampa", label: "Mira Pampa" },
      { value: "Miramar", label: "Miramar" },
      { value: "Moctezuma", label: "Moctezuma" },
      { value: "Mones Cazon", label: "Mones Cazon" },
      { value: "Monte Chingolo", label: "Monte Chingolo" },
      { value: "Monte Grande", label: "Monte Grande" },
      { value: "Monte Hermoso", label: "Monte Hermoso" },
      { value: "Moquehua", label: "Moquehua" },
      { value: "Morea", label: "Morea" },
      { value: "Moreno", label: "Moreno" },
      { value: "Moron", label: "Moron" },
      { value: "Morse", label: "Morse" },
      { value: "Munro", label: "Munro" },
      { value: "Muñiz", label: "Muñiz" },
      { value: "Napaleofu", label: "Napaleofu" },
      { value: "Navarro", label: "Navarro" },
      { value: "Necochea", label: "Necochea" },
      {
        value: "Nicanor Olivera (Est. La Dulce)",
        label: "Nicanor Olivera (Est. La Dulce)",
      },
      { value: "Norberto De La Riestra", label: "Norberto De La Riestra" },
      { value: "Norumbega", label: "Norumbega" },
      { value: "Nueva Plata", label: "Nueva Plata" },
      { value: "Obligado", label: "Obligado" },
      { value: "Ochandio", label: "Ochandio" },
      { value: "O'Higgins", label: "O'Higgins" },
      { value: "Olascoaga", label: "Olascoaga" },
      { value: "Olavarria", label: "Olavarria" },
      { value: "Oliden", label: "Oliden" },
      { value: "Olivera", label: "Olivera" },
      { value: "Olivos", label: "Olivos" },
      { value: "Open Door", label: "Open Door" },
      { value: "Ordoqui", label: "Ordoqui" },
      { value: "Orense", label: "Orense" },
      { value: "Oriente", label: "Oriente" },
      { value: "Ostende", label: "Ostende" },
      { value: "Pablo Podesta", label: "Pablo Podesta" },
      { value: "Parada Orlando", label: "Parada Orlando" },
      { value: "Parada Robles", label: "Parada Robles" },
      { value: "Parada Robles - Pavon", label: "Parada Robles - Pavon" },
      { value: "Pardo", label: "Pardo" },
      { value: "Pasman", label: "Pasman" },
      { value: "Paso Del Rey", label: "Paso Del Rey" },
      { value: "Pasteur", label: "Pasteur" },
      { value: "Patricios", label: "Patricios" },
      { value: "Paula", label: "Paula" },
      { value: "Pavon", label: "Pavon" },
      { value: "Pavon", label: "Pavon" },
      { value: "Pearson", label: "Pearson" },
      { value: "Pedernales", label: "Pedernales" },
      { value: "Pedro Luro", label: "Pedro Luro" },
      { value: "Pehuajo", label: "Pehuajo" },
      { value: "Pellegrini", label: "Pellegrini" },
      { value: "Pereyra", label: "Pereyra" },
      { value: "Perez Millan", label: "Perez Millan" },
      { value: "Pergamino", label: "Pergamino" },
      { value: "Pichincha", label: "Pichincha" },
      { value: "Piedritas", label: "Piedritas" },
      { value: "Pieres", label: "Pieres" },
      { value: "PigÜE", label: "PigÜE" },
      { value: "Pila", label: "Pila" },
      { value: "Pilar", label: "Pilar" },
      { value: "Pinamar", label: "Pinamar" },
      { value: "Pinzon", label: "Pinzon" },
      { value: "Piñeyro", label: "Piñeyro" },
      { value: "Pipinas", label: "Pipinas" },
      { value: "Pirovano", label: "Pirovano" },
      { value: "Pla", label: "Pla" },
      { value: "Platanos", label: "Platanos" },
      { value: "Playa Dorada", label: "Playa Dorada" },
      { value: "Plomer", label: "Plomer" },
      { value: "Polvaredas", label: "Polvaredas" },
      { value: "Pontaut", label: "Pontaut" },
      { value: "Pontevedra", label: "Pontevedra" },
      { value: "Porvenir", label: "Porvenir" },
      { value: "Presidente Derqui", label: "Presidente Derqui" },
      { value: "Presidente Peron", label: "Presidente Peron" },
      { value: "Puan", label: "Puan" },
      { value: "Pueblo Doyle", label: "Pueblo Doyle" },
      { value: "Pueblo Gouin", label: "Pueblo Gouin" },
      { value: "Pueblo Nuevo", label: "Pueblo Nuevo" },
      { value: "Pueblo San Jorge", label: "Pueblo San Jorge" },
      { value: "Puerto Parana", label: "Puerto Parana" },
      {
        value: "Punta Alta (Est. Almirante Solier)",
        label: "Punta Alta (Est. Almirante Solier)",
      },
      { value: "Punta Indio", label: "Punta Indio" },
      { value: "Punta Lara", label: "Punta Lara" },
      { value: "Punta Mogotes", label: "Punta Mogotes" },
      { value: "Quenuma", label: "Quenuma" },
      { value: "Quequen", label: "Quequen" },
      { value: "Quilmes", label: "Quilmes" },
      { value: "Quilmes Oeste", label: "Quilmes Oeste" },
      { value: "Rafael Calzada", label: "Rafael Calzada" },
      { value: "Rafael Castillo", label: "Rafael Castillo" },
      { value: "Rafael Obligado", label: "Rafael Obligado" },
      { value: "Ramallo", label: "Ramallo" },
      { value: "Ramon Biaus", label: "Ramon Biaus" },
      { value: "Ramon Santamarina", label: "Ramon Santamarina" },
      { value: "Ramos Mejia", label: "Ramos Mejia" },
      { value: "Ramos Otero", label: "Ramos Otero" },
      { value: "Rancagua", label: "Rancagua" },
      { value: "Ranchos", label: "Ranchos" },
      { value: "Ranelagh", label: "Ranelagh" },
      { value: "Rauch", label: "Rauch" },
      { value: "Rawson", label: "Rawson" },
      { value: "Recalde", label: "Recalde" },
      { value: "Remedios De Escalada", label: "Remedios De Escalada" },
      {
        value: "Remedios Escalada De San Martin",
        label: "Remedios Escalada De San Martin",
      },
      { value: "Reta", label: "Reta" },
      { value: "Ricardo Rojas", label: "Ricardo Rojas" },
      { value: "Rincon De Milberg", label: "Rincon De Milberg" },
      { value: "Ringuelet", label: "Ringuelet" },
      { value: "Rio Tala", label: "Rio Tala" },
      { value: "Rivera", label: "Rivera" },
      { value: "Roberto Cano", label: "Roberto Cano" },
      { value: "Roberto De Vicenzo", label: "Roberto De Vicenzo" },
      { value: "Roberto J. Payro", label: "Roberto J. Payro" },
      { value: "Roberts", label: "Roberts" },
      { value: "Rojas", label: "Rojas" },
      { value: "Roosevelt", label: "Roosevelt" },
      { value: "Roque Perez", label: "Roque Perez" },
      { value: "Rufino De Elizalde", label: "Rufino De Elizalde" },
      { value: "Ruta Sol", label: "Ruta Sol" },
      { value: "Saavedra", label: "Saavedra" },
      { value: "Saenz Peña", label: "Saenz Peña" },
      { value: "Saforcada", label: "Saforcada" },
      { value: "Saladillo", label: "Saladillo" },
      { value: "Salazar", label: "Salazar" },
      { value: "Saldungaray", label: "Saldungaray" },
      { value: "Salliquelo", label: "Salliquelo" },
      { value: "Salto", label: "Salto" },
      { value: "Salvador Maria", label: "Salvador Maria" },
      { value: "Samborombon", label: "Samborombon" },
      { value: "San Agustin", label: "San Agustin" },
      { value: "San Andres De Giles", label: "San Andres De Giles" },
      { value: "San Antonio De Areco", label: "San Antonio De Areco" },
      { value: "San Antonio De Padua", label: "San Antonio De Padua" },
      { value: "San Bernardo", label: "San Bernardo" },
      {
        value: "San Bernardo (Est. Guanaco)",
        label: "San Bernardo (Est. Guanaco)",
      },
      {
        value: "San Carlos De Bolivar (Est. Bolivar)",
        label: "San Carlos De Bolivar (Est. Bolivar)",
      },
      { value: "San Cayetano", label: "San Cayetano" },
      { value: "San Clemente Del Tuyu", label: "San Clemente Del Tuyu" },
      { value: "San Emilio", label: "San Emilio" },
      { value: "San Enrique", label: "San Enrique" },
      { value: "San Fernando", label: "San Fernando" },
      { value: "San Francisco De Bellocq", label: "San Francisco De Bellocq" },
      { value: "San Francisco Solano", label: "San Francisco Solano" },
      { value: "San Francisco Solano", label: "San Francisco Solano" },
      { value: "San German", label: "San German" },
      { value: "San Isidro", label: "San Isidro" },
      { value: "San Jose", label: "San Jose" },
      { value: "San Jose", label: "San Jose" },
      { value: "San Justo", label: "San Justo" },
      { value: "San Manuel", label: "San Manuel" },
      { value: "San Mauricio", label: "San Mauricio" },
      { value: "San Mayol", label: "San Mayol" },
      { value: "San Miguel", label: "San Miguel" },
      {
        value: "San Miguel Del Monte (Est. Monte)",
        label: "San Miguel Del Monte (Est. Monte)",
      },
      {
        value: "San Nicolas De Los Arroyos",
        label: "San Nicolas De Los Arroyos",
      },
      { value: "San Pedro", label: "San Pedro" },
      { value: "San Roman", label: "San Roman" },
      { value: "San Sebastian", label: "San Sebastian" },
      { value: "San Vicente", label: "San Vicente" },
      { value: "Sansinena", label: "Sansinena" },
      { value: "Santa Clara Del Mar", label: "Santa Clara Del Mar" },
      { value: "Santa Coloma", label: "Santa Coloma" },
      { value: "Santa Elena", label: "Santa Elena" },
      { value: "Santa Eleodora", label: "Santa Eleodora" },
      { value: "Santa Lucia", label: "Santa Lucia" },
      { value: "Santa Luisa", label: "Santa Luisa" },
      { value: "Santa Maria", label: "Santa Maria" },
      { value: "Santa Regina", label: "Santa Regina" },
      { value: "Santa Rosa", label: "Santa Rosa" },
      { value: "Santa Teresa", label: "Santa Teresa" },
      { value: "Santa Teresita", label: "Santa Teresita" },
      { value: "Santa Trinidad", label: "Santa Trinidad" },
      { value: "Santo Domingo", label: "Santo Domingo" },
      { value: "Santos Lugares", label: "Santos Lugares" },
      { value: "Sarandi", label: "Sarandi" },
      { value: "Sarasa", label: "Sarasa" },
      { value: "Sevigne", label: "Sevigne" },
      { value: "Sierra Chica", label: "Sierra Chica" },
      { value: "Sierra De La Ventana", label: "Sierra De La Ventana" },
      { value: "Sierra De Los Padres", label: "Sierra De Los Padres" },
      { value: "Sierras Bayas", label: "Sierras Bayas" },
      { value: "Smith", label: "Smith" },
      { value: "Sol De Mayo", label: "Sol De Mayo" },
      { value: "Solanet", label: "Solanet" },
      { value: "Solis", label: "Solis" },
      { value: "Stroeder", label: "Stroeder" },
      { value: "Suipacha", label: "Suipacha" },
      { value: "Sundblad", label: "Sundblad" },
      { value: "Tamangueyu", label: "Tamangueyu" },
      { value: "Tandil", label: "Tandil" },
      { value: "Tapalque", label: "Tapalque" },
      { value: "Tapiales", label: "Tapiales" },
      { value: "Tedin Uriburu", label: "Tedin Uriburu" },
      { value: "Temperley", label: "Temperley" },
      { value: "Teniente Origone", label: "Teniente Origone" },
      { value: "Thames", label: "Thames" },
      { value: "Tigre", label: "Tigre" },
      { value: "Timote", label: "Timote" },
      { value: "Todd", label: "Todd" },
      { value: "Tolosa", label: "Tolosa" },
      { value: "Tomas Jofre", label: "Tomas Jofre" },
      { value: "Tornquist", label: "Tornquist" },
      { value: "Torres", label: "Torres" },
      { value: "Tortuguitas", label: "Tortuguitas" },
      { value: "Tortuguitas", label: "Tortuguitas" },
      { value: "Tortuguitas", label: "Tortuguitas" },
      { value: "Transradio", label: "Transradio" },
      { value: "Trenque Lauquen", label: "Trenque Lauquen" },
      {
        value: "Tres Algarrobos (Est. Cuenca)",
        label: "Tres Algarrobos (Est. Cuenca)",
      },
      { value: "Tres Arroyos", label: "Tres Arroyos" },
      { value: "Tres De Febrero", label: "Tres De Febrero" },
      { value: "Tres Lomas", label: "Tres Lomas" },
      { value: "Tres Picos", label: "Tres Picos" },
      { value: "Tres Sargentos", label: "Tres Sargentos" },
      { value: "Tristan Suarez", label: "Tristan Suarez" },
      { value: "Tronge", label: "Tronge" },
      { value: "Trujui", label: "Trujui" },
      { value: "Turdera", label: "Turdera" },
      { value: "Udaquiola", label: "Udaquiola" },
      { value: "Urdampilleta", label: "Urdampilleta" },
      { value: "Uribelarrea", label: "Uribelarrea" },
      { value: "Valdes", label: "Valdes" },
      { value: "Valentin Alsina", label: "Valentin Alsina" },
      { value: "Valeria Del Mar", label: "Valeria Del Mar" },
      { value: "Vasquez", label: "Vasquez" },
      { value: "Vedia", label: "Vedia" },
      { value: "Velloso", label: "Velloso" },
      { value: "Veronica", label: "Veronica" },
      { value: "Vicente Casares", label: "Vicente Casares" },
      { value: "Vicente Lopez", label: "Vicente Lopez" },
      { value: "Victoria", label: "Victoria" },
      { value: "Vieytes", label: "Vieytes" },
      { value: "Villa Eduardo Madero", label: "Villa Eduardo Madero" },
      { value: "Villa Adelina", label: "Villa Adelina" },
      { value: "Villa Adelina", label: "Villa Adelina" },
      { value: "Villa Alfredo Fortabat", label: "Villa Alfredo Fortabat" },
      {
        value: "Villa Alsina (Est. Alsina)",
        label: "Villa Alsina (Est. Alsina)",
      },
      {
        value: "Villa Angelica (Est. El Socorro)",
        label: "Villa Angelica (Est. El Socorro)",
      },
      { value: "Villa ArgÜELLO", label: "Villa ArgÜELLO" },
      { value: "Villa Arrieta", label: "Villa Arrieta" },
      { value: "Villa Astolfi", label: "Villa Astolfi" },
      { value: "Villa Ayacucho", label: "Villa Ayacucho" },
      { value: "Villa Ballester", label: "Villa Ballester" },
      {
        value: "Villa Bernardo Monteagudo",
        label: "Villa Bernardo Monteagudo",
      },
      { value: "Villa Bordeau", label: "Villa Bordeau" },
      {
        value: "Villa Bosch (Est. Juan Maria Bosch)",
        label: "Villa Bosch (Est. Juan Maria Bosch)",
      },
      { value: "Villa Brown", label: "Villa Brown" },
      {
        value: "Villa Cacique (Est. Alfredo Fortabat)",
        label: "Villa Cacique (Est. Alfredo Fortabat)",
      },
      { value: "Villa Campi", label: "Villa Campi" },
      { value: "Villa Canto", label: "Villa Canto" },
      {
        value: "Villa Castelar (Est. Erize)",
        label: "Villa Castelar (Est. Erize)",
      },
      { value: "Villa Catela", label: "Villa Catela" },
      { value: "Villa Centenario", label: "Villa Centenario" },
      { value: "Villa Chacabuco", label: "Villa Chacabuco" },
      {
        value: "Villa Coronel Jose M. Zapiola",
        label: "Villa Coronel Jose M. Zapiola",
      },
      { value: "Villa De Mayo", label: "Villa De Mayo" },
      { value: "Villa Del Mar", label: "Villa Del Mar" },
      { value: "Villa Dolores", label: "Villa Dolores" },
      { value: "Villa Dominico", label: "Villa Dominico" },
      { value: "Villa Elisa", label: "Villa Elisa" },
      { value: "Villa Elvira", label: "Villa Elvira" },
      { value: "Villa España", label: "Villa España" },
      { value: "Villa Esperanza", label: "Villa Esperanza" },
      { value: "Villa Espil", label: "Villa Espil" },
      { value: "Villa Espora", label: "Villa Espora" },
      { value: "Villa Fiorito", label: "Villa Fiorito" },
      { value: "Villa Flandria Norte", label: "Villa Flandria Norte" },
      { value: "Villa Flandria Sur", label: "Villa Flandria Sur" },
      {
        value: "Villa Francia (Est. Coronel Granada)",
        label: "Villa Francia (Est. Coronel Granada)",
      },
      { value: "Villa Garibaldi", label: "Villa Garibaldi" },
      {
        value: "Villa General Antonio J. De Sucre",
        label: "Villa General Antonio J. De Sucre",
      },
      {
        value: "Villa General Arias (Est. Kilometro 638)",
        label: "Villa General Arias (Est. Kilometro 638)",
      },
      {
        value: "Villa General Eugenio Necochea",
        label: "Villa General Eugenio Necochea",
      },
      {
        value: "Villa General Fournier (Est. 9 De Julio Sud)",
        label: "Villa General Fournier (Est. 9 De Julio Sud)",
      },
      {
        value: "Villa General Jose Tomas Guido",
        label: "Villa General Jose Tomas Guido",
      },
      {
        value: "Villa General Juan G. Las Heras",
        label: "Villa General Juan G. Las Heras",
      },
      {
        value: "Villa General Savio (Est. Sanchez)",
        label: "Villa General Savio (Est. Sanchez)",
      },
      { value: "Villa Gesell", label: "Villa Gesell" },
      { value: "Villa Gobernador Udaondo", label: "Villa Gobernador Udaondo" },
      { value: "Villa Godoy Cruz", label: "Villa Godoy Cruz" },
      {
        value: "Villa Granaderos De San Martin",
        label: "Villa Granaderos De San Martin",
      },
      { value: "Villa Gregoria Matorras", label: "Villa Gregoria Matorras" },
      {
        value: "Villa Grisolia (Est. Achupallas)",
        label: "Villa Grisolia (Est. Achupallas)",
      },
      { value: "Villa Hermosa", label: "Villa Hermosa" },
      { value: "Villa Independencia", label: "Villa Independencia" },
      { value: "Villa Iris", label: "Villa Iris" },
      { value: "Villa Jose Leon Suarez", label: "Villa Jose Leon Suarez" },
      {
        value: "Villa Juan Martin De Pueyrredon",
        label: "Villa Juan Martin De Pueyrredon",
      },
      { value: "Villa La Arcadia", label: "Villa La Arcadia" },
      { value: "Villa La Florida", label: "Villa La Florida" },
      { value: "Villa La Serrania", label: "Villa La Serrania" },
      { value: "Villa Laguna La Brava", label: "Villa Laguna La Brava" },
      { value: "Villa Las Encadenadas", label: "Villa Las Encadenadas" },
      { value: "Villa Lia", label: "Villa Lia" },
      { value: "Villa Libertad", label: "Villa Libertad" },
      { value: "Villa Luzuriaga", label: "Villa Luzuriaga" },
      { value: "Villa Lynch", label: "Villa Lynch" },
      { value: "Villa Lynch Pueyrredon", label: "Villa Lynch Pueyrredon" },
      { value: "Villa Maipu", label: "Villa Maipu" },
      { value: "Villa Manuel Pomar", label: "Villa Manuel Pomar" },
      { value: "Villa Margarita", label: "Villa Margarita" },
      { value: "Villa Maria", label: "Villa Maria" },
      {
        value: "Villa Maria Irene De Los Remedios De Escalada",
        label: "Villa Maria Irene De Los Remedios De Escalada",
      },
      {
        value: "Villa Marques Alejandro Maria De Aguado",
        label: "Villa Marques Alejandro Maria De Aguado",
      },
      { value: "Villa Martelli", label: "Villa Martelli" },
      { value: "Villa Moll (Est. Moll)", label: "Villa Moll (Est. Moll)" },
      { value: "Villa Montoro", label: "Villa Montoro" },
      { value: "Villa Nueva", label: "Villa Nueva" },
      {
        value: "Villa Ortiz (Est. Coronel Mom)",
        label: "Villa Ortiz (Est. Coronel Mom)",
      },
      { value: "Villa Parque Cecir", label: "Villa Parque Cecir" },
      { value: "Villa Parque Girado", label: "Villa Parque Girado" },
      {
        value: "Villa Parque Presidente Figueroa Alcorta",
        label: "Villa Parque Presidente Figueroa Alcorta",
      },
      { value: "Villa Parque San Lorenzo", label: "Villa Parque San Lorenzo" },
      { value: "Villa Parque Sicardi", label: "Villa Parque Sicardi" },
      { value: "Villa Porteña", label: "Villa Porteña" },
      { value: "Villa Progreso", label: "Villa Progreso" },
      { value: "Villa Raffo", label: "Villa Raffo" },
      { value: "Villa Ramallo", label: "Villa Ramallo" },
      { value: "Villa Riccio", label: "Villa Riccio" },
      { value: "Villa Roch", label: "Villa Roch" },
      {
        value: "Villa Rodriguez (Est. Barrow)",
        label: "Villa Rodriguez (Est. Barrow)",
      },
      { value: "Villa Rosa", label: "Villa Rosa" },
      {
        value: "Villa Roth (Est. Ingeniero Balbin)",
        label: "Villa Roth (Est. Ingeniero Balbin)",
      },
      { value: "Villa Ruiz", label: "Villa Ruiz" },
      { value: "Villa Saboya", label: "Villa Saboya" },
      { value: "Villa San Andres", label: "Villa San Andres" },
      { value: "Villa San Carlos", label: "Villa San Carlos" },
      { value: "Villa San Jose", label: "Villa San Jose" },
      { value: "Villa San Luis", label: "Villa San Luis" },
      { value: "Villa Santa Rosa", label: "Villa Santa Rosa" },
      { value: "Villa Santos Tesei", label: "Villa Santos Tesei" },
      { value: "Villa Sarmiento", label: "Villa Sarmiento" },
      { value: "Villa Sauze", label: "Villa Sauze" },
      { value: "Villa Serrana La Gruta", label: "Villa Serrana La Gruta" },
      { value: "Villa Vatteone", label: "Villa Vatteone" },
      { value: "Villa Ventana", label: "Villa Ventana" },
      { value: "Villa Yapeyu", label: "Villa Yapeyu" },
      { value: "Villa Zula", label: "Villa Zula" },
      { value: "Villalonga", label: "Villalonga" },
      {
        value: "Villanueva (Ap. Rio Salado)",
        label: "Villanueva (Ap. Rio Salado)",
      },
      { value: "Villars", label: "Villars" },
      { value: "Viña", label: "Viña" },
      { value: "Virrey Del Pino", label: "Virrey Del Pino" },
      { value: "Virreyes", label: "Virreyes" },
      { value: "Vivorata", label: "Vivorata" },
      { value: "Warnes", label: "Warnes" },
      { value: "Wilde", label: "Wilde" },
      { value: "William C. Morris", label: "William C. Morris" },
      { value: "Yutuyaco", label: "Yutuyaco" },
      { value: "Zarate", label: "Zarate" },
      { value: "Zavalia", label: "Zavalia" },
      { value: "Zelaya", label: "Zelaya" },
      { value: "Zenon Videla Dorna", label: "Zenon Videla Dorna" },
      {
        value: "Zona Aeropuerto Internacional Ezeiza",
        label: "Zona Aeropuerto Internacional Ezeiza",
      },
      { value: "11 De Septiembre", label: "11 De Septiembre" },
      { value: "12 De Octubre", label: "12 De Octubre" },
      { value: "16 De Julio", label: "16 De Julio" },
      { value: "17 De Agosto", label: "17 De Agosto" },
      { value: "20 De Junio", label: "20 De Junio" },
      { value: "25 De Mayo", label: "25 De Mayo" },
      { value: "30 De Agosto", label: "30 De Agosto" },
      { value: "9 De Abril", label: "9 De Abril" },
      { value: "9 De Julio", label: "9 De Julio" },
    ],
  },
  {
    id: 10,
    value: "Catamarca",
    label: "Catamarca",
    ciudades: [
      { value: "Aconquija", label: "Aconquija" },
      { value: "Adolfo E. Carranza", label: "Adolfo E. Carranza" },
      { value: "Alijilan", label: "Alijilan" },
      { value: "Alto De Las Juntas", label: "Alto De Las Juntas" },
      { value: "Amadores", label: "Amadores" },
      { value: "Amanao", label: "Amanao" },
      { value: "Ancasti", label: "Ancasti" },
      { value: "Andalgala", label: "Andalgala" },
      { value: "Andalhuala", label: "Andalhuala" },
      { value: "Anillaco", label: "Anillaco" },
      { value: "Anquincila", label: "Anquincila" },
      { value: "Antinaco", label: "Antinaco" },
      { value: "Antofagasta De La Sierra", label: "Antofagasta De La Sierra" },
      { value: "Antofalla", label: "Antofalla" },
      { value: "Apoyaco", label: "Apoyaco" },
      { value: "Balde De La Punta", label: "Balde De La Punta" },
      { value: "Banda De Lucero", label: "Banda De Lucero" },
      { value: "Bañado De Ovanta", label: "Bañado De Ovanta" },
      { value: "Barranca Larga", label: "Barranca Larga" },
      { value: "Barrio Bancario", label: "Barrio Bancario" },
      { value: "Belen", label: "Belen" },
      { value: "Buena Vista", label: "Buena Vista" },
      { value: "Capayan", label: "Capayan" },
      { value: "Casa De Piedra", label: "Casa De Piedra" },
      { value: "Casa De Piedra", label: "Casa De Piedra" },
      { value: "Caspichango", label: "Caspichango" },
      { value: "Cerro Negro", label: "Cerro Negro" },
      { value: "Chañar Punco", label: "Chañar Punco" },
      { value: "Chaquiago", label: "Chaquiago" },
      { value: "Choya", label: "Choya" },
      { value: "Chuchucaruana", label: "Chuchucaruana" },
      { value: "Chumbicha", label: "Chumbicha" },
      { value: "Colana", label: "Colana" },
      { value: "Collagasta", label: "Collagasta" },
      { value: "Colonia Del Valle", label: "Colonia Del Valle" },
      { value: "Colonia Nueva Coneta", label: "Colonia Nueva Coneta" },
      { value: "Colpes", label: "Colpes" },
      { value: "Colpes", label: "Colpes" },
      { value: "Concepcion", label: "Concepcion" },
      { value: "Condor Huasi", label: "Condor Huasi" },
      { value: "Coneta", label: "Coneta" },
      { value: "Copacabana", label: "Copacabana" },
      { value: "Cordobita", label: "Cordobita" },
      { value: "Corral Quemado", label: "Corral Quemado" },
      { value: "Costa De Reyes", label: "Costa De Reyes" },
      { value: "El Alamito", label: "El Alamito" },
      { value: "El Alamito", label: "El Alamito" },
      { value: "El Alto", label: "El Alto" },
      { value: "El Aybal", label: "El Aybal" },
      { value: "El Bañado", label: "El Bañado" },
      { value: "El Bañado", label: "El Bañado" },
      { value: "El Bañado", label: "El Bañado" },
      { value: "El Bolson", label: "El Bolson" },
      { value: "El Cajon", label: "El Cajon" },
      { value: "El Cerrito", label: "El Cerrito" },
      { value: "El Desmonte", label: "El Desmonte" },
      { value: "El Divisadero", label: "El Divisadero" },
      { value: "El Durazno", label: "El Durazno" },
      { value: "El Hueco", label: "El Hueco" },
      { value: "El Lindero", label: "El Lindero" },
      { value: "El Pajonal (Est. Poman)", label: "El Pajonal (Est. Poman)" },
      { value: "El Pantanillo", label: "El Pantanillo" },
      { value: "El Peñon", label: "El Peñon" },
      { value: "El Portezuelo", label: "El Portezuelo" },
      { value: "El Potrero", label: "El Potrero" },
      { value: "El Pueblito", label: "El Pueblito" },
      { value: "El Puesto", label: "El Puesto" },
      { value: "El Puesto", label: "El Puesto" },
      { value: "El Quimilo", label: "El Quimilo" },
      { value: "El Rodeo", label: "El Rodeo" },
      { value: "El Rosario", label: "El Rosario" },
      { value: "El Salado", label: "El Salado" },
      { value: "Esquiu", label: "Esquiu" },
      { value: "Famatanca", label: "Famatanca" },
      { value: "Farallon Negro", label: "Farallon Negro" },
      { value: "Fiambala", label: "Fiambala" },
      { value: "Fuerte Quemado", label: "Fuerte Quemado" },
      { value: "Guayamba", label: "Guayamba" },
      { value: "Hualfin", label: "Hualfin" },
      { value: "Huaycama", label: "Huaycama" },
      { value: "Huaycama", label: "Huaycama" },
      { value: "Huillapima", label: "Huillapima" },
      { value: "Icaño", label: "Icaño" },
      { value: "Infanzon", label: "Infanzon" },
      { value: "Jacipunco", label: "Jacipunco" },
      { value: "Joyango", label: "Joyango" },
      { value: "La Aguada", label: "La Aguada" },
      { value: "La Bajada", label: "La Bajada" },
      { value: "La Candelaria", label: "La Candelaria" },
      { value: "La Carrera", label: "La Carrera" },
      { value: "La Dorada", label: "La Dorada" },
      { value: "La Falda De San Antonio", label: "La Falda De San Antonio" },
      { value: "La Guardia", label: "La Guardia" },
      { value: "La Higuera", label: "La Higuera" },
      { value: "La Hoyada", label: "La Hoyada" },
      { value: "La Loma", label: "La Loma" },
      { value: "La Majada", label: "La Majada" },
      { value: "La Merced", label: "La Merced" },
      { value: "La Mesada", label: "La Mesada" },
      { value: "La Puerta", label: "La Puerta" },
      { value: "La Puntilla", label: "La Puntilla" },
      { value: "La Puntilla", label: "La Puntilla" },
      { value: "La Puntilla", label: "La Puntilla" },
      { value: "La Ramadita", label: "La Ramadita" },
      { value: "La Tercena", label: "La Tercena" },
      { value: "La Viña", label: "La Viña" },
      { value: "Lampacito", label: "Lampacito" },
      { value: "Las Cañas", label: "Las Cañas" },
      { value: "Las Chacritas", label: "Las Chacritas" },
      { value: "Las Esquinas", label: "Las Esquinas" },
      { value: "Las Juntas", label: "Las Juntas" },
      { value: "Las Juntas", label: "Las Juntas" },
      { value: "Las Lajas", label: "Las Lajas" },
      { value: "Las Mojarras", label: "Las Mojarras" },
      { value: "Las Palmitas", label: "Las Palmitas" },
      { value: "Las Tejas", label: "Las Tejas" },
      { value: "Lavalle", label: "Lavalle" },
      { value: "Londres", label: "Londres" },
      { value: "Loro Huasi", label: "Loro Huasi" },
      { value: "Los Altos", label: "Los Altos" },
      { value: "Los Angeles", label: "Los Angeles" },
      { value: "Los Balverdis", label: "Los Balverdis" },
      { value: "Los Castillos", label: "Los Castillos" },
      { value: "Los Corrales", label: "Los Corrales" },
      { value: "Los Nacimientos", label: "Los Nacimientos" },
      { value: "Los Nacimientos", label: "Los Nacimientos" },
      { value: "Los Talas", label: "Los Talas" },
      { value: "Los Varela", label: "Los Varela" },
      { value: "Manantiales", label: "Manantiales" },
      { value: "Medanitos", label: "Medanitos" },
      { value: "Medanitos", label: "Medanitos" },
      { value: "Miraflores", label: "Miraflores" },
      { value: "Monte Potrero", label: "Monte Potrero" },
      { value: "Mutquin", label: "Mutquin" },
      { value: "Palo Blanco", label: "Palo Blanco" },
      { value: "Palo Labrado", label: "Palo Labrado" },
      { value: "Palo Seco", label: "Palo Seco" },
      { value: "Pampa Blanca", label: "Pampa Blanca" },
      { value: "Polcos", label: "Polcos" },
      { value: "Poman", label: "Poman" },
      { value: "Pomancillo Este", label: "Pomancillo Este" },
      { value: "Pomancillo Oeste", label: "Pomancillo Oeste" },
      { value: "Pozo Del Mistol", label: "Pozo Del Mistol" },
      { value: "Puerta De Corral Quemado", label: "Puerta De Corral Quemado" },
      { value: "Puerta De San Jose", label: "Puerta De San Jose" },
      { value: "Punta De Balasto", label: "Punta De Balasto" },
      { value: "Punta Del Agua", label: "Punta Del Agua" },
      { value: "Quiros", label: "Quiros" },
      { value: "Ramblones", label: "Ramblones" },
      { value: "Recreo", label: "Recreo" },
      { value: "Rincon", label: "Rincon" },
      {
        value: "S. F. Del V. De Catamarca",
        label: "S. F. Del V. De Catamarca",
      },
      { value: "San Antonio", label: "San Antonio" },
      { value: "San Antonio", label: "San Antonio" },
      { value: "San Antonio", label: "San Antonio" },
      { value: "San Isidro", label: "San Isidro" },
      { value: "San Jose", label: "San Jose" },
      { value: "San Jose", label: "San Jose" },
      { value: "San Jose Banda", label: "San Jose Banda" },
      { value: "San Jose Norte", label: "San Jose Norte" },
      { value: "San Jose Villa", label: "San Jose Villa" },
      { value: "San Martin", label: "San Martin" },
      { value: "San Miguel", label: "San Miguel" },
      { value: "San Pablo", label: "San Pablo" },
      { value: "San Pedro", label: "San Pedro" },
      { value: "San Pedro", label: "San Pedro" },
      { value: "Santa Cruz", label: "Santa Cruz" },
      { value: "Santa Maria", label: "Santa Maria" },
      { value: "Santa Rosa", label: "Santa Rosa" },
      { value: "Saujil", label: "Saujil" },
      { value: "Saujil", label: "Saujil" },
      { value: "Sijan", label: "Sijan" },
      { value: "Singuil", label: "Singuil" },
      { value: "Sumalao", label: "Sumalao" },
      { value: "Tapso", label: "Tapso" },
      { value: "Taton", label: "Taton" },
      { value: "Tinogasta", label: "Tinogasta" },
      { value: "Vilisman", label: "Vilisman" },
      { value: "Villa De Balcozna", label: "Villa De Balcozna" },
      { value: "Villa Dolores", label: "Villa Dolores" },
      { value: "Villa Las Pirquitas", label: "Villa Las Pirquitas" },
      { value: "Villa Vil", label: "Villa Vil" },
      { value: "Yapes", label: "Yapes" },
    ],
  },
  {
    id: 22,
    value: "Chaco",
    label: "Chaco",
    ciudades: [
      { value: "Avia Terai", label: "Avia Terai" },
      { value: "Barranqueras", label: "Barranqueras" },
      { value: "Barrio De Los Pescadores", label: "Barrio De Los Pescadores" },
      { value: "Basail", label: "Basail" },
      { value: "Campo Largo", label: "Campo Largo" },
      { value: "Capitan Solari", label: "Capitan Solari" },
      { value: "Charadai", label: "Charadai" },
      { value: "Charata", label: "Charata" },
      { value: "Chorotis", label: "Chorotis" },
      { value: "Ciervo Petiso", label: "Ciervo Petiso" },
      { value: "Colonia Aborigen", label: "Colonia Aborigen" },
      { value: "Colonia Baranda", label: "Colonia Baranda" },
      { value: "Colonia Benitez", label: "Colonia Benitez" },
      { value: "Colonia Elisa", label: "Colonia Elisa" },
      { value: "Colonia Popular", label: "Colonia Popular" },
      { value: "Colonias Unidas", label: "Colonias Unidas" },
      { value: "Concepcion Del Bermejo", label: "Concepcion Del Bermejo" },
      { value: "Coronel Du Graty", label: "Coronel Du Graty" },
      { value: "Corzuela", label: "Corzuela" },
      { value: "Cote Lai", label: "Cote Lai" },
      { value: "El Espinillo", label: "El Espinillo" },
      { value: "El Sauzal", label: "El Sauzal" },
      { value: "El Sauzalito", label: "El Sauzalito" },
      { value: "Enrique Urien", label: "Enrique Urien" },
      {
        value: "Estacion Estacion General Obligado",
        label: "Estacion Estacion General Obligado",
      },
      { value: "Fontana", label: "Fontana" },
      { value: "Fortin Las Chuñas", label: "Fortin Las Chuñas" },
      { value: "Fortin Lavalle", label: "Fortin Lavalle" },
      { value: "Fuerte Esperanza", label: "Fuerte Esperanza" },
      { value: "Gancedo", label: "Gancedo" },
      { value: "General Capdevila", label: "General Capdevila" },
      {
        value: "General Jose De San Martin",
        label: "General Jose De San Martin",
      },
      { value: "General Pinedo", label: "General Pinedo" },
      { value: "General Vedia", label: "General Vedia" },
      { value: "Haumonia", label: "Haumonia" },
      { value: "Hermoso Campo", label: "Hermoso Campo" },
      { value: "Horquilla", label: "Horquilla" },
      { value: "Ingeniero Barbet", label: "Ingeniero Barbet" },
      { value: "Isla Del Cerrito", label: "Isla Del Cerrito" },
      { value: "Itin", label: "Itin" },
      { value: "Juan Jose Castelli", label: "Juan Jose Castelli" },
      { value: "La Clotilde", label: "La Clotilde" },
      { value: "La Eduvigis", label: "La Eduvigis" },
      { value: "La Escondida", label: "La Escondida" },
      { value: "La Leonesa", label: "La Leonesa" },
      { value: "La Sabana", label: "La Sabana" },
      { value: "La Tigra", label: "La Tigra" },
      { value: "La Verde", label: "La Verde" },
      { value: "Laguna Blanca", label: "Laguna Blanca" },
      { value: "Laguna Limpia", label: "Laguna Limpia" },
      { value: "Lapachito", label: "Lapachito" },
      { value: "Las Breñas", label: "Las Breñas" },
      { value: "Las Garcitas", label: "Las Garcitas" },
      { value: "Las Palmas", label: "Las Palmas" },
      { value: "Los Frentones", label: "Los Frentones" },
      { value: "Machagai", label: "Machagai" },
      { value: "Makalle", label: "Makalle" },
      { value: "Margarita Belen", label: "Margarita Belen" },
      { value: "Meson De Fierro", label: "Meson De Fierro" },
      { value: "Miraflores", label: "Miraflores" },
      { value: "Napalpi", label: "Napalpi" },
      { value: "Napenay", label: "Napenay" },
      { value: "Nueva Pompeya", label: "Nueva Pompeya" },
      { value: "Pampa Almiron", label: "Pampa Almiron" },
      { value: "Pampa Del Indio", label: "Pampa Del Indio" },
      { value: "Pampa Del Infierno", label: "Pampa Del Infierno" },
      { value: "Pampa Landriel", label: "Pampa Landriel" },
      { value: "Presidencia De La Plaza", label: "Presidencia De La Plaza" },
      { value: "Presidencia Roca", label: "Presidencia Roca" },
      {
        value: "Presidencia Roque Saenz Peña",
        label: "Presidencia Roque Saenz Peña",
      },
      { value: "Puerto Bermejo Nuevo", label: "Puerto Bermejo Nuevo" },
      { value: "Puerto Bermejo Viejo", label: "Puerto Bermejo Viejo" },
      { value: "Puerto Eva Peron", label: "Puerto Eva Peron" },
      { value: "Puerto Lavalle", label: "Puerto Lavalle" },
      { value: "Puerto Tirol", label: "Puerto Tirol" },
      { value: "Puerto Vilelas", label: "Puerto Vilelas" },
      { value: "Quitilipi", label: "Quitilipi" },
      { value: "Resistencia", label: "Resistencia" },
      { value: "Rio Muerto", label: "Rio Muerto" },
      { value: "Samuhu", label: "Samuhu" },
      { value: "San Bernardo", label: "San Bernardo" },
      { value: "Santa Sylvina", label: "Santa Sylvina" },
      { value: "Selvas Del Rio De Oro", label: "Selvas Del Rio De Oro" },
      { value: "Taco Pozo", label: "Taco Pozo" },
      { value: "Tres Isletas", label: "Tres Isletas" },
      { value: "Venados Grandes", label: "Venados Grandes" },
      { value: "Villa Angela", label: "Villa Angela" },
      { value: "Villa Berthet", label: "Villa Berthet" },
      { value: "Villa El Palmar", label: "Villa El Palmar" },
      { value: "Villa Rio Bermejito", label: "Villa Rio Bermejito" },
      { value: "Wichi", label: "Wichi" },
      { value: "Zaparinqui", label: "Zaparinqui" },
    ],
  },
  {
    id: 26,
    value: "Chubut",
    label: "Chubut",
    ciudades: [
      { value: "Acceso Norte", label: "Acceso Norte" },
      { value: "Aldea Apeleg", label: "Aldea Apeleg" },
      { value: "Aldea Beleiro", label: "Aldea Beleiro" },
      { value: "Aldea Epulef", label: "Aldea Epulef" },
      { value: "Aldea Escolar", label: "Aldea Escolar" },
      { value: "Alto Rio Senguer", label: "Alto Rio Senguer" },
      { value: "Arroyo Verde", label: "Arroyo Verde" },
      { value: "Astra", label: "Astra" },
      { value: "Bahia Bustamante", label: "Bahia Bustamante" },
      { value: "Barrio Caleta Cordova", label: "Barrio Caleta Cordova" },
      { value: "Barrio Caleta Olivares", label: "Barrio Caleta Olivares" },
      { value: "Barrio Castelli", label: "Barrio Castelli" },
      { value: "Barrio Ciudadela", label: "Barrio Ciudadela" },
      { value: "Barrio Gasoducto", label: "Barrio Gasoducto" },
      { value: "Barrio GÜEMES", label: "Barrio GÜEMES" },
      { value: "Barrio Laprida", label: "Barrio Laprida" },
      { value: "Barrio Manantial Rosales", label: "Barrio Manantial Rosales" },
      {
        value: "Barrio Militar - Aeropuerto Militar",
        label: "Barrio Militar - Aeropuerto Militar",
      },
      { value: "Barrio Prospero Palazzo", label: "Barrio Prospero Palazzo" },
      { value: "Barrio Restinga Ali", label: "Barrio Restinga Ali" },
      { value: "Barrio Rodriguez Peña", label: "Barrio Rodriguez Peña" },
      { value: "Barrio Saavedra", label: "Barrio Saavedra" },
      { value: "Barrio Sarmiento", label: "Barrio Sarmiento" },
      { value: "Barrio Villa S.U.P.E.", label: "Barrio Villa S.U.P.E." },
      { value: "Barrio 25 De Mayo", label: "Barrio 25 De Mayo" },
      { value: "Blancuntre", label: "Blancuntre" },
      { value: "Buen Pasto", label: "Buen Pasto" },
      { value: "Buenos Aires Chico", label: "Buenos Aires Chico" },
      { value: "Camarones", label: "Camarones" },
      { value: "Carrenleufu", label: "Carrenleufu" },
      { value: "Cerro Condor", label: "Cerro Condor" },
      { value: "Cholila", label: "Cholila" },
      { value: "Colan Conhue", label: "Colan Conhue" },
      { value: "Comodoro Rivadavia", label: "Comodoro Rivadavia" },
      { value: "Corcovado", label: "Corcovado" },
      { value: "Cushamen Centro", label: "Cushamen Centro" },
      { value: "Diadema Argentina", label: "Diadema Argentina" },
      {
        value: "Dique Florentino Ameghino",
        label: "Dique Florentino Ameghino",
      },
      { value: "Doctor Ricardo Rojas", label: "Doctor Ricardo Rojas" },
      { value: "Dolavon", label: "Dolavon" },
      { value: "El Escorial", label: "El Escorial" },
      { value: "El Hoyo", label: "El Hoyo" },
      { value: "El Maiten", label: "El Maiten" },
      { value: "El Mirasol", label: "El Mirasol" },
      { value: "Epuyen", label: "Epuyen" },
      { value: "Esquel", label: "Esquel" },
      { value: "Facundo", label: "Facundo" },
      { value: "Frontera De Rio Pico", label: "Frontera De Rio Pico" },
      { value: "Gaiman", label: "Gaiman" },
      { value: "Gan Gan", label: "Gan Gan" },
      { value: "Garayalde", label: "Garayalde" },
      { value: "Gastre", label: "Gastre" },
      { value: "Gobernador Costa", label: "Gobernador Costa" },
      { value: "Gualjaina", label: "Gualjaina" },
      { value: "Jose De San Martin", label: "Jose De San Martin" },
      { value: "Km. 11 - Cuarteles", label: "Km. 11 - Cuarteles" },
      { value: "Km. 3 - General Mosconi", label: "Km. 3 - General Mosconi" },
      { value: "Km. 5 - Presidente Ortiz", label: "Km. 5 - Presidente Ortiz" },
      { value: "Km. 8 - Don Bosco", label: "Km. 8 - Don Bosco" },
      { value: "Lago Blanco", label: "Lago Blanco" },
      { value: "Lago Epuyen", label: "Lago Epuyen" },
      { value: "Lago Puelo", label: "Lago Puelo" },
      { value: "Lago Rosario", label: "Lago Rosario" },
      { value: "Lagunita Salada", label: "Lagunita Salada" },
      { value: "Las Plumas", label: "Las Plumas" },
      { value: "Leleque", label: "Leleque" },
      { value: "Los Altares", label: "Los Altares" },
      { value: "Los Cipreses", label: "Los Cipreses" },
      { value: "Paso De Indios", label: "Paso De Indios" },
      { value: "Paso Del Sapo", label: "Paso Del Sapo" },
      { value: "Playa Magagna", label: "Playa Magagna" },
      { value: "Playa Union", label: "Playa Union" },
      { value: "Puerto Madryn", label: "Puerto Madryn" },
      { value: "Puerto Piramide", label: "Puerto Piramide" },
      { value: "Puerto Rawson", label: "Puerto Rawson" },
      { value: "Quinta El Mirador", label: "Quinta El Mirador" },
      { value: "Rada Tilly", label: "Rada Tilly" },
      { value: "Rawson", label: "Rawson" },
      { value: "Rio Mayo", label: "Rio Mayo" },
      { value: "Rio Pico", label: "Rio Pico" },
      { value: "Sarmiento", label: "Sarmiento" },
      { value: "Tecka", label: "Tecka" },
      { value: "Telsen", label: "Telsen" },
      { value: "Trelew", label: "Trelew" },
      { value: "Trevelin", label: "Trevelin" },
      { value: "Villa Futalaufquen", label: "Villa Futalaufquen" },
      { value: "Yala Laubat", label: "Yala Laubat" },
      { value: "28 De Julio", label: "28 De Julio" },
    ],
  },
  {
    id: 2,
    value: "Ciudad Autonoma De Bs As",
    label: "Ciudad Autonoma De Bs As",
    ciudades: [
      { value: "Agronomia", label: "Agronomia" },
      { value: "Almagro", label: "Almagro" },
      { value: "Balvanera", label: "Balvanera" },
      { value: "Barracas", label: "Barracas" },
      { value: "Belgrano", label: "Belgrano" },
      { value: "Boedo", label: "Boedo" },
      { value: "Caballito", label: "Caballito" },
      { value: "Chacarita", label: "Chacarita" },
      { value: "Coghlan", label: "Coghlan" },
      { value: "Colegiales", label: "Colegiales" },
      { value: "Constitucion", label: "Constitucion" },
      { value: "Flores", label: "Flores" },
      { value: "Floresta", label: "Floresta" },
      { value: "La Boca", label: "La Boca" },
      { value: "Liniers", label: "Liniers" },
      { value: "Mataderos", label: "Mataderos" },
      { value: "Monserrat", label: "Monserrat" },
      { value: "Monte Castro", label: "Monte Castro" },
      { value: "Nueva Pompeya", label: "Nueva Pompeya" },
      { value: "Nuñez", label: "Nuñez" },
      { value: "Palermo", label: "Palermo" },
      { value: "Parque Avellaneda", label: "Parque Avellaneda" },
      { value: "Parque Chacabuco", label: "Parque Chacabuco" },
      { value: "Parque Chas", label: "Parque Chas" },
      { value: "Parque Patricios", label: "Parque Patricios" },
      { value: "Paternal", label: "Paternal" },
      { value: "Puerto Madero", label: "Puerto Madero" },
      { value: "Recoleta", label: "Recoleta" },
      { value: "Retiro", label: "Retiro" },
      { value: "Saavedra", label: "Saavedra" },
      { value: "San Cristobal", label: "San Cristobal" },
      { value: "San Nicolas", label: "San Nicolas" },
      { value: "San Telmo", label: "San Telmo" },
      { value: "Velez Sarsfield", label: "Velez Sarsfield" },
      { value: "Versalles", label: "Versalles" },
      { value: "Villa Crespo", label: "Villa Crespo" },
      { value: "Villa Del Parque", label: "Villa Del Parque" },
      { value: "Villa Devoto", label: "Villa Devoto" },
      { value: "Villa Gral Mitre", label: "Villa Gral Mitre" },
      { value: "Villa Lugano", label: "Villa Lugano" },
      { value: "Villa Luro", label: "Villa Luro" },
      { value: "Villa Ortuzar", label: "Villa Ortuzar" },
      { value: "Villa Pueyrredon", label: "Villa Pueyrredon" },
      { value: "Villa Real", label: "Villa Real" },
      { value: "Villa Riachuelo", label: "Villa Riachuelo" },
      { value: "Villa Santa Rita", label: "Villa Santa Rita" },
      { value: "Villa Soldati", label: "Villa Soldati" },
      { value: "Villa Urquiza", label: "Villa Urquiza" },
    ],
  },
  {
    id: 14,
    value: "Cordoba",
    label: "Cordoba",
    ciudades: [
      { value: "Achiras", label: "Achiras" },
      { value: "Adelia Maria", label: "Adelia Maria" },
      { value: "Agua De Oro", label: "Agua De Oro" },
      { value: "Alcira (Est. Gigena)", label: "Alcira (Est. Gigena)" },
      { value: "Aldea Santa Maria", label: "Aldea Santa Maria" },
      {
        value: "Alejandro Roca (Est. Alejandro)",
        label: "Alejandro Roca (Est. Alejandro)",
      },
      { value: "Alejo Ledesma", label: "Alejo Ledesma" },
      { value: "Alicia", label: "Alicia" },
      { value: "Almafuerte", label: "Almafuerte" },
      { value: "Alpa Corral", label: "Alpa Corral" },
      { value: "Alta Gracia", label: "Alta Gracia" },
      { value: "Alto Alegre", label: "Alto Alegre" },
      { value: "Alto De Los Quebrachos", label: "Alto De Los Quebrachos" },
      {
        value: "Alto Resbaloso - El Barrial",
        label: "Alto Resbaloso - El Barrial",
      },
      { value: "Altos De Chipion", label: "Altos De Chipion" },
      { value: "Amboy", label: "Amboy" },
      { value: "Ambul", label: "Ambul" },
      { value: "Ana Zumaran", label: "Ana Zumaran" },
      { value: "Anisacate", label: "Anisacate" },
      { value: "Arias", label: "Arias" },
      { value: "Arroyito", label: "Arroyito" },
      { value: "Arroyo Algodon", label: "Arroyo Algodon" },
      { value: "Arroyo Cabral", label: "Arroyo Cabral" },
      { value: "Arroyo Los Patos", label: "Arroyo Los Patos" },
      { value: "Arroyo San Antonio", label: "Arroyo San Antonio" },
      { value: "Ascochinga", label: "Ascochinga" },
      { value: "Assunta", label: "Assunta" },
      { value: "Atahona", label: "Atahona" },
      { value: "Ausonia", label: "Ausonia" },
      { value: "Avellaneda", label: "Avellaneda" },
      { value: "Ballesteros", label: "Ballesteros" },
      { value: "Ballesteros Sur", label: "Ballesteros Sur" },
      { value: "Balnearia", label: "Balnearia" },
      { value: "Bañado De Soto", label: "Bañado De Soto" },
      { value: "Barrio Gilbert", label: "Barrio Gilbert" },
      { value: "Barrio Las Quintas", label: "Barrio Las Quintas" },
      { value: "Barrio Santa Isabel", label: "Barrio Santa Isabel" },
      { value: "Barrio Villa Del Parque", label: "Barrio Villa Del Parque" },
      { value: "Bell Ville", label: "Bell Ville" },
      { value: "Bengolea", label: "Bengolea" },
      { value: "Benjamin Gould", label: "Benjamin Gould" },
      { value: "Berrotaran", label: "Berrotaran" },
      { value: "Bialet Masse", label: "Bialet Masse" },
      { value: "Bouwer", label: "Bouwer" },
      { value: "Brinkmann", label: "Brinkmann" },
      { value: "Bulnes", label: "Bulnes" },
      { value: "Cabalango", label: "Cabalango" },
      { value: "Calchin", label: "Calchin" },
      { value: "Calchin Oeste", label: "Calchin Oeste" },
      { value: "Camilo Aldao", label: "Camilo Aldao" },
      { value: "Caminiaga", label: "Caminiaga" },
      { value: "Canals", label: "Canals" },
      { value: "Candelaria Sur", label: "Candelaria Sur" },
      { value: "Canteras El Sauce", label: "Canteras El Sauce" },
      { value: "Canteras Quilpo", label: "Canteras Quilpo" },
      { value: "Cañada De Luque", label: "Cañada De Luque" },
      { value: "Cañada De Machado", label: "Cañada De Machado" },
      { value: "Cañada De Rio Pinto", label: "Cañada De Rio Pinto" },
      { value: "Cañada Del Sauce", label: "Cañada Del Sauce" },
      { value: "Capilla De Los Remedios", label: "Capilla De Los Remedios" },
      { value: "Capilla De Siton", label: "Capilla De Siton" },
      { value: "Capilla Del Carmen", label: "Capilla Del Carmen" },
      { value: "Capilla Del Monte", label: "Capilla Del Monte" },
      { value: "Capilla Vieja", label: "Capilla Vieja" },
      {
        value: "Capitan General Bernardo O Higgins",
        label: "Capitan General Bernardo O Higgins",
      },
      { value: "Carnerillo", label: "Carnerillo" },
      { value: "Carrilobo", label: "Carrilobo" },
      { value: "Casa Bamba", label: "Casa Bamba" },
      { value: "Casa Grande", label: "Casa Grande" },
      { value: "Caseros Centro", label: "Caseros Centro" },
      { value: "Cavanagh", label: "Cavanagh" },
      { value: "Cerro Colorado", label: "Cerro Colorado" },
      { value: "Cerro Colorado", label: "Cerro Colorado" },
      { value: "Cerro Colorado", label: "Cerro Colorado" },
      { value: "Chajan", label: "Chajan" },
      { value: "Chalacea", label: "Chalacea" },
      { value: "Chancani", label: "Chancani" },
      { value: "Chañar Viejo", label: "Chañar Viejo" },
      { value: "Charbonier", label: "Charbonier" },
      { value: "Charras", label: "Charras" },
      { value: "Chazon", label: "Chazon" },
      { value: "Chilibroste", label: "Chilibroste" },
      { value: "Chucul", label: "Chucul" },
      { value: "Chuña", label: "Chuña" },
      { value: "Chuña Huasi", label: "Chuña Huasi" },
      { value: "Churqui Cañada", label: "Churqui Cañada" },
      { value: "Cienaga Del Coro", label: "Cienaga Del Coro" },
      { value: "Cintra", label: "Cintra" },
      { value: "Colazo", label: "Colazo" },
      { value: "Colonia Almada", label: "Colonia Almada" },
      { value: "Colonia Anita", label: "Colonia Anita" },
      { value: "Colonia Barge", label: "Colonia Barge" },
      { value: "Colonia Bismarck", label: "Colonia Bismarck" },
      { value: "Colonia Bremen", label: "Colonia Bremen" },
      { value: "Colonia Caroya", label: "Colonia Caroya" },
      { value: "Colonia Italiana", label: "Colonia Italiana" },
      {
        value: "Colonia Las Cuatro Esquinas",
        label: "Colonia Las Cuatro Esquinas",
      },
      { value: "Colonia Las Pichanas", label: "Colonia Las Pichanas" },
      { value: "Colonia Marina", label: "Colonia Marina" },
      { value: "Colonia Prosperidad", label: "Colonia Prosperidad" },
      { value: "Colonia San Bartolome", label: "Colonia San Bartolome" },
      { value: "Colonia San Pedro", label: "Colonia San Pedro" },
      { value: "Colonia Santa Maria", label: "Colonia Santa Maria" },
      { value: "Colonia Tirolesa", label: "Colonia Tirolesa" },
      { value: "Colonia Valtelina", label: "Colonia Valtelina" },
      { value: "Colonia Veinticinco", label: "Colonia Veinticinco" },
      { value: "Colonia Vicente AgÜERO", label: "Colonia Vicente AgÜERO" },
      { value: "Colonia Videla", label: "Colonia Videla" },
      { value: "Colonia Vignaud", label: "Colonia Vignaud" },
      { value: "Colonia 10 De Julio", label: "Colonia 10 De Julio" },
      { value: "Conlara", label: "Conlara" },
      { value: "Copacabana", label: "Copacabana" },
      { value: "Cordoba", label: "Cordoba" },
      { value: "Coronel Baigorria", label: "Coronel Baigorria" },
      { value: "Coronel Moldes", label: "Coronel Moldes" },
      { value: "Corral De Bustos", label: "Corral De Bustos" },
      { value: "Corral Quemado", label: "Corral Quemado" },
      { value: "Corralito", label: "Corralito" },
      { value: "Corralito", label: "Corralito" },
      { value: "Cosquin", label: "Cosquin" },
      { value: "Costa Azul", label: "Costa Azul" },
      { value: "Costasacate", label: "Costasacate" },
      {
        value: "Country Chacras De La Villa",
        label: "Country Chacras De La Villa",
      },
      { value: "Country San Isidro", label: "Country San Isidro" },
      { value: "Cruz Alta", label: "Cruz Alta" },
      { value: "Cruz Caña", label: "Cruz Caña" },
      { value: "Cruz De Caña", label: "Cruz De Caña" },
      { value: "Cruz Del Eje", label: "Cruz Del Eje" },
      { value: "Cuesta Blanca", label: "Cuesta Blanca" },
      { value: "Dalmacio Velez", label: "Dalmacio Velez" },
      { value: "Dean Funes", label: "Dean Funes" },
      { value: "Del Campillo", label: "Del Campillo" },
      {
        value: "Despeñaderos (Est. Dr. Lucas A. De Olmos)",
        label: "Despeñaderos (Est. Dr. Lucas A. De Olmos)",
      },
      { value: "Devoto", label: "Devoto" },
      { value: "Diego De Rojas", label: "Diego De Rojas" },
      { value: "Dique Chico", label: "Dique Chico" },
      { value: "Dos Arroyos", label: "Dos Arroyos" },
      { value: "Dumesnil", label: "Dumesnil" },
      {
        value: "El Alcalde (Est. Tala Norte)",
        label: "El Alcalde (Est. Tala Norte)",
      },
      { value: "El Arañado", label: "El Arañado" },
      { value: "El Brete", label: "El Brete" },
      { value: "El Chacho", label: "El Chacho" },
      {
        value: "El Corcovado - El Torreon",
        label: "El Corcovado - El Torreon",
      },
      { value: "El Crispin", label: "El Crispin" },
      { value: "El Diquecito", label: "El Diquecito" },
      { value: "El Fortin", label: "El Fortin" },
      { value: "El Fuertecito", label: "El Fuertecito" },
      { value: "El Huayco", label: "El Huayco" },
      { value: "El Manzano", label: "El Manzano" },
      { value: "El Pantanillo", label: "El Pantanillo" },
      { value: "El Pueblito", label: "El Pueblito" },
      { value: "El Pueblito", label: "El Pueblito" },
      { value: "El Rastreador", label: "El Rastreador" },
      { value: "El Rincon", label: "El Rincon" },
      { value: "El Rodeo", label: "El Rodeo" },
      { value: "El Tio", label: "El Tio" },
      { value: "El Tuscal", label: "El Tuscal" },
      { value: "El Valle", label: "El Valle" },
      { value: "Elena", label: "Elena" },
      { value: "Embalse", label: "Embalse" },
      { value: "Esquina", label: "Esquina" },
      { value: "Esquina Del Alambre", label: "Esquina Del Alambre" },
      {
        value: "Estacion Colonia Tirolesa",
        label: "Estacion Colonia Tirolesa",
      },
      { value: "Estacion Lecueder", label: "Estacion Lecueder" },
      { value: "Estacion Luxardo", label: "Estacion Luxardo" },
      { value: "Estancia De Guadalupe", label: "Estancia De Guadalupe" },
      { value: "Estancia Vieja", label: "Estancia Vieja" },
      { value: "Etruria", label: "Etruria" },
      { value: "Eufrasio Loza", label: "Eufrasio Loza" },
      { value: "Falda Del Cañete", label: "Falda Del Cañete" },
      { value: "Falda Del Carmen", label: "Falda Del Carmen" },
      { value: "Freyre", label: "Freyre" },
      { value: "General Baldissera", label: "General Baldissera" },
      { value: "General Cabrera", label: "General Cabrera" },
      { value: "General Deheza", label: "General Deheza" },
      { value: "General Fotheringham", label: "General Fotheringham" },
      { value: "General Levalle", label: "General Levalle" },
      { value: "General Paz", label: "General Paz" },
      { value: "General Roca", label: "General Roca" },
      { value: "Guanaco Muerto", label: "Guanaco Muerto" },
      { value: "Guasapampa", label: "Guasapampa" },
      { value: "Guatimozin", label: "Guatimozin" },
      { value: "GÜTEMBERG", label: "GÜTEMBERG" },
      { value: "Hernando", label: "Hernando" },
      { value: "Hipolito Bouchard", label: "Hipolito Bouchard" },
      { value: "Huanchillas", label: "Huanchillas" },
      { value: "Huerta Grande", label: "Huerta Grande" },
      { value: "Huinca Renanco", label: "Huinca Renanco" },
      { value: "Idiazabal", label: "Idiazabal" },
      { value: "Impira", label: "Impira" },
      { value: "Inriville", label: "Inriville" },
      { value: "Isla Verde", label: "Isla Verde" },
      { value: "Italo", label: "Italo" },
      { value: "James Craik", label: "James Craik" },
      { value: "Jardin Arenales", label: "Jardin Arenales" },
      { value: "Jesus Maria", label: "Jesus Maria" },
      { value: "Jose De La Quintana", label: "Jose De La Quintana" },
      { value: "Juarez Celman", label: "Juarez Celman" },
      { value: "Justiniano Posse", label: "Justiniano Posse" },
      { value: "Kilometro 658", label: "Kilometro 658" },
      { value: "La Banda", label: "La Banda" },
      { value: "La Batea", label: "La Batea" },
      { value: "La Boca Del Rio", label: "La Boca Del Rio" },
      { value: "La Calera", label: "La Calera" },
      { value: "La Carbonada", label: "La Carbonada" },
      { value: "La Carlota", label: "La Carlota" },
      { value: "La Carolina", label: "La Carolina" },
      { value: "La Cautiva", label: "La Cautiva" },
      { value: "La Cesira", label: "La Cesira" },
      { value: "La Cortadera", label: "La Cortadera" },
      { value: "La Cruz", label: "La Cruz" },
      { value: "La Cumbre", label: "La Cumbre" },
      { value: "La Cumbrecita", label: "La Cumbrecita" },
      { value: "La Cumbrecita", label: "La Cumbrecita" },
      { value: "La Falda", label: "La Falda" },
      { value: "La Floresta", label: "La Floresta" },
      { value: "La Francia", label: "La Francia" },
      { value: "La Gilda", label: "La Gilda" },
      { value: "La Granja", label: "La Granja" },
      { value: "La Higuera", label: "La Higuera" },
      { value: "La Laguna", label: "La Laguna" },
      { value: "La Paisanita", label: "La Paisanita" },
      { value: "La Palestina", label: "La Palestina" },
      { value: "La Pampa", label: "La Pampa" },
      { value: "La Paquita", label: "La Paquita" },
      { value: "La Para", label: "La Para" },
      { value: "La Paz", label: "La Paz" },
      { value: "La Paz", label: "La Paz" },
      { value: "La Perla", label: "La Perla" },
      { value: "La Playa", label: "La Playa" },
      { value: "La Playosa", label: "La Playosa" },
      { value: "La Poblacion", label: "La Poblacion" },
      { value: "La Posta", label: "La Posta" },
      { value: "La Puerta", label: "La Puerta" },
      { value: "La Puerta", label: "La Puerta" },
      { value: "La Quinta", label: "La Quinta" },
      { value: "La Ramada", label: "La Ramada" },
      { value: "La Rancherita", label: "La Rancherita" },
      { value: "La Rinconada", label: "La Rinconada" },
      { value: "La Serranita", label: "La Serranita" },
      { value: "La Tordilla", label: "La Tordilla" },
      { value: "La Travesia", label: "La Travesia" },
      { value: "Laborde", label: "Laborde" },
      { value: "Laboulaye", label: "Laboulaye" },
      { value: "Laguna Larga", label: "Laguna Larga" },
      { value: "Las Acequias", label: "Las Acequias" },
      { value: "Las Albahacas", label: "Las Albahacas" },
      { value: "Las Arrias", label: "Las Arrias" },
      { value: "Las Bajadas", label: "Las Bajadas" },
      { value: "Las Caleras", label: "Las Caleras" },
      { value: "Las Calles", label: "Las Calles" },
      { value: "Las Cañadas", label: "Las Cañadas" },
      { value: "Las Chacras", label: "Las Chacras" },
      { value: "Las Chacras", label: "Las Chacras" },
      { value: "Las Gramillas", label: "Las Gramillas" },
      { value: "Las Higueras", label: "Las Higueras" },
      { value: "Las Isletillas", label: "Las Isletillas" },
      { value: "Las Jarillas", label: "Las Jarillas" },
      { value: "Las Junturas", label: "Las Junturas" },
      { value: "Las Mojarras", label: "Las Mojarras" },
      { value: "Las Oscuras", label: "Las Oscuras" },
      { value: "Las Palmas", label: "Las Palmas" },
      { value: "Las Peñas", label: "Las Peñas" },
      { value: "Las Peñas", label: "Las Peñas" },
      { value: "Las Perdices", label: "Las Perdices" },
      { value: "Las Playas", label: "Las Playas" },
      { value: "Las Rabonas", label: "Las Rabonas" },
      { value: "Las Saladas", label: "Las Saladas" },
      { value: "Las Tapias", label: "Las Tapias" },
      { value: "Las Varas", label: "Las Varas" },
      { value: "Las Varillas", label: "Las Varillas" },
      { value: "Las Vertientes", label: "Las Vertientes" },
      { value: "Leguizamon", label: "Leguizamon" },
      { value: "Leones", label: "Leones" },
      { value: "Loma Bola", label: "Loma Bola" },
      { value: "Los Callejones", label: "Los Callejones" },
      { value: "Los Cedros", label: "Los Cedros" },
      { value: "Los Cerrillos", label: "Los Cerrillos" },
      { value: "Los Chañaritos", label: "Los Chañaritos" },
      { value: "Los Chañaritos", label: "Los Chañaritos" },
      { value: "Los Chañaritos", label: "Los Chañaritos" },
      { value: "Los Cisnes", label: "Los Cisnes" },
      { value: "Los Cocos", label: "Los Cocos" },
      { value: "Los Condores", label: "Los Condores" },
      { value: "Los Hornillos", label: "Los Hornillos" },
      { value: "Los Hoyos", label: "Los Hoyos" },
      { value: "Los Mistoles", label: "Los Mistoles" },
      { value: "Los Molinos", label: "Los Molinos" },
      { value: "Los Molles", label: "Los Molles" },
      { value: "Los Molles", label: "Los Molles" },
      { value: "Los Pozos", label: "Los Pozos" },
      { value: "Los Reartes", label: "Los Reartes" },
      { value: "Los Romeros", label: "Los Romeros" },
      { value: "Los Surgentes", label: "Los Surgentes" },
      { value: "Los Talares", label: "Los Talares" },
      { value: "Los Zorros", label: "Los Zorros" },
      { value: "Lozada", label: "Lozada" },
      { value: "Luca", label: "Luca" },
      { value: "Lucio V. Mansilla", label: "Lucio V. Mansilla" },
      { value: "Luque", label: "Luque" },
      { value: "Lutti", label: "Lutti" },
      { value: "Luyaba", label: "Luyaba" },
      { value: "Malagueño", label: "Malagueño" },
      { value: "Malena", label: "Malena" },
      { value: "Mallin", label: "Mallin" },
      { value: "Malvinas Argentinas", label: "Malvinas Argentinas" },
      { value: "Manfredi", label: "Manfredi" },
      { value: "Maquinista Gallini", label: "Maquinista Gallini" },
      { value: "Marcos Juarez", label: "Marcos Juarez" },
      { value: "Marull", label: "Marull" },
      { value: "Matorrales", label: "Matorrales" },
      { value: "Mattaldi", label: "Mattaldi" },
      { value: "Mayu Sumaj", label: "Mayu Sumaj" },
      { value: "Media Naranja", label: "Media Naranja" },
      { value: "Melo", label: "Melo" },
      { value: "Mendiolaza", label: "Mendiolaza" },
      { value: "Mi Granja", label: "Mi Granja" },
      { value: "Mi Valle", label: "Mi Valle" },
      { value: "Mina Clavero", label: "Mina Clavero" },
      { value: "Miramar", label: "Miramar" },
      { value: "Monte Buey", label: "Monte Buey" },
      { value: "Monte De Los Gauchos", label: "Monte De Los Gauchos" },
      { value: "Monte Del Rosario", label: "Monte Del Rosario" },
      { value: "Monte Leña", label: "Monte Leña" },
      { value: "Monte Maiz", label: "Monte Maiz" },
      { value: "Monte Ralo", label: "Monte Ralo" },
      { value: "Monte Ralo", label: "Monte Ralo" },
      { value: "Montecristo", label: "Montecristo" },
      { value: "Morrison", label: "Morrison" },
      { value: "Morteros", label: "Morteros" },
      { value: "Mussi", label: "Mussi" },
      { value: "Nicolas Bruzzone", label: "Nicolas Bruzzone" },
      { value: "Noetinger", label: "Noetinger" },
      { value: "Noetinger", label: "Noetinger" },
      { value: "Nono", label: "Nono" },
      { value: "Obispo Trejo", label: "Obispo Trejo" },
      { value: "Olaeta", label: "Olaeta" },
      { value: "Oliva", label: "Oliva" },
      { value: "Olivares De San Nicolas", label: "Olivares De San Nicolas" },
      { value: "Onagoity", label: "Onagoity" },
      { value: "Oncativo", label: "Oncativo" },
      { value: "Ordoñez", label: "Ordoñez" },
      { value: "Pacheco De Melo", label: "Pacheco De Melo" },
      { value: "Pajas Blancas", label: "Pajas Blancas" },
      { value: "Pampayasta Norte", label: "Pampayasta Norte" },
      { value: "Pampayasta Sur", label: "Pampayasta Sur" },
      { value: "Panaholma", label: "Panaholma" },
      { value: "Parque Calmayo", label: "Parque Calmayo" },
      { value: "Parque Norte", label: "Parque Norte" },
      { value: "Pascanas", label: "Pascanas" },
      { value: "Pasco", label: "Pasco" },
      { value: "Paso Del Durazno", label: "Paso Del Durazno" },
      { value: "Paso Del Durazno", label: "Paso Del Durazno" },
      { value: "Paso Viejo", label: "Paso Viejo" },
      { value: "Pilar", label: "Pilar" },
      { value: "Pincen", label: "Pincen" },
      { value: "Piquillin", label: "Piquillin" },
      { value: "Plaza De Mercedes", label: "Plaza De Mercedes" },
      { value: "Plaza Luxardo", label: "Plaza Luxardo" },
      { value: "Plaza San Francisco", label: "Plaza San Francisco" },
      { value: "Porteña", label: "Porteña" },
      { value: "Potrero De Garay", label: "Potrero De Garay" },
      { value: "Pozo Del Molle", label: "Pozo Del Molle" },
      { value: "Pozo Nuevo", label: "Pozo Nuevo" },
      { value: "Pueblo Comechingones", label: "Pueblo Comechingones" },
      { value: "Pueblo Italiano", label: "Pueblo Italiano" },
      { value: "Puesto De Castro", label: "Puesto De Castro" },
      { value: "Punta Del Agua", label: "Punta Del Agua" },
      { value: "Quebracho Herrado", label: "Quebracho Herrado" },
      { value: "Quebracho Ladeado", label: "Quebracho Ladeado" },
      { value: "Quebrada De Los Pozos", label: "Quebrada De Los Pozos" },
      { value: "Quebrada De Luna", label: "Quebrada De Luna" },
      { value: "Quilino", label: "Quilino" },
      { value: "Rafael Garcia", label: "Rafael Garcia" },
      { value: "Ramon J. Carcano", label: "Ramon J. Carcano" },
      { value: "Ranqueles", label: "Ranqueles" },
      { value: "Rayo Cortado", label: "Rayo Cortado" },
      { value: "Rincon", label: "Rincon" },
      { value: "Rio Bamba", label: "Rio Bamba" },
      { value: "Rio Ceballos", label: "Rio Ceballos" },
      { value: "Rio Cuarto", label: "Rio Cuarto" },
      { value: "Rio De Los Sauces", label: "Rio De Los Sauces" },
      { value: "Rio Primero", label: "Rio Primero" },
      { value: "Rio Segundo", label: "Rio Segundo" },
      { value: "Rio Tercero", label: "Rio Tercero" },
      { value: "Rosales", label: "Rosales" },
      { value: "Rosario Del Saladillo", label: "Rosario Del Saladillo" },
      { value: "Sacanta", label: "Sacanta" },
      { value: "Sagrada Familia", label: "Sagrada Familia" },
      { value: "Saira", label: "Saira" },
      { value: "Saladillo", label: "Saladillo" },
      { value: "Saldan", label: "Saldan" },
      { value: "Salsacate", label: "Salsacate" },
      { value: "Salsipuedes", label: "Salsipuedes" },
      { value: "Sampacho", label: "Sampacho" },
      { value: "San Agustin", label: "San Agustin" },
      { value: "San Antonio De Arredondo", label: "San Antonio De Arredondo" },
      { value: "San Antonio De Litin", label: "San Antonio De Litin" },
      { value: "San Basilio", label: "San Basilio" },
      { value: "San Carlos Minas", label: "San Carlos Minas" },
      { value: "San Clemente", label: "San Clemente" },
      { value: "San Esteban", label: "San Esteban" },
      { value: "San Francisco", label: "San Francisco" },
      { value: "San Francisco Del Chañar", label: "San Francisco Del Chañar" },
      { value: "San Geronimo", label: "San Geronimo" },
      { value: "San Huberto", label: "San Huberto" },
      {
        value: "San Ignacio (Loteo San Javier)",
        label: "San Ignacio (Loteo San Javier)",
      },
      {
        value: "San Ignacio (Loteo Velez Crespo)",
        label: "San Ignacio (Loteo Velez Crespo)",
      },
      { value: "San Javier Y Yacanto", label: "San Javier Y Yacanto" },
      { value: "San Joaquin", label: "San Joaquin" },
      { value: "San Jose", label: "San Jose" },
      { value: "San Jose De La Dormida", label: "San Jose De La Dormida" },
      { value: "San Jose De Las Salinas", label: "San Jose De Las Salinas" },
      { value: "San Lorenzo", label: "San Lorenzo" },
      { value: "San Marcos", label: "San Marcos" },
      { value: "San Marcos Sierra", label: "San Marcos Sierra" },
      { value: "San Martin", label: "San Martin" },
      { value: "San Nicolas", label: "San Nicolas" },
      { value: "San Pedro", label: "San Pedro" },
      { value: "San Pedro De GÜTEMBERG", label: "San Pedro De GÜTEMBERG" },
      { value: "San Pedro De Toyos", label: "San Pedro De Toyos" },
      { value: "San Pedro Norte", label: "San Pedro Norte" },
      { value: "San Roque", label: "San Roque" },
      { value: "San Severo", label: "San Severo" },
      { value: "San Vicente", label: "San Vicente" },
      { value: "Sanabria", label: "Sanabria" },
      { value: "Santa Catalina", label: "Santa Catalina" },
      {
        value: "Santa Catalina (Est. Holmberg)",
        label: "Santa Catalina (Est. Holmberg)",
      },
      { value: "Santa Elena", label: "Santa Elena" },
      { value: "Santa Elena", label: "Santa Elena" },
      { value: "Santa Eufemia", label: "Santa Eufemia" },
      {
        value: "Santa Magdalena (Est. Jovita)",
        label: "Santa Magdalena (Est. Jovita)",
      },
      { value: "Santa Maria De Punilla", label: "Santa Maria De Punilla" },
      { value: "Santa Monica", label: "Santa Monica" },
      {
        value: "Santa Rosa De Calamuchita",
        label: "Santa Rosa De Calamuchita",
      },
      {
        value: "Santa Rosa De Rio Primero (Est. Villa Santa Rosa)",
        label: "Santa Rosa De Rio Primero (Est. Villa Santa Rosa)",
      },
      { value: "Santiago Temple", label: "Santiago Temple" },
      { value: "Sarmiento", label: "Sarmiento" },
      { value: "Saturnino Maria Laspiur", label: "Saturnino Maria Laspiur" },
      { value: "Sauce Arriba", label: "Sauce Arriba" },
      { value: "Sebastian Elcano", label: "Sebastian Elcano" },
      { value: "Seeber", label: "Seeber" },
      { value: "Segunda Usina", label: "Segunda Usina" },
      { value: "Serrano", label: "Serrano" },
      { value: "Serrezuela", label: "Serrezuela" },
      { value: "Silvio Pellico", label: "Silvio Pellico" },
      { value: "Simbolar", label: "Simbolar" },
      { value: "Sinsacate", label: "Sinsacate" },
      { value: "Socavones", label: "Socavones" },
      { value: "Solar De Los Molinos", label: "Solar De Los Molinos" },
      { value: "Suco", label: "Suco" },
      { value: "Tala Cañada", label: "Tala Cañada" },
      { value: "Tala Huasi", label: "Tala Huasi" },
      { value: "Talaini", label: "Talaini" },
      { value: "Tancacha", label: "Tancacha" },
      { value: "Taninga", label: "Taninga" },
      { value: "Tanti", label: "Tanti" },
      { value: "Tasna", label: "Tasna" },
      { value: "Ticino", label: "Ticino" },
      { value: "Tinoco", label: "Tinoco" },
      { value: "Tio Pujio", label: "Tio Pujio" },
      { value: "Toledo", label: "Toledo" },
      { value: "Toro Pujio", label: "Toro Pujio" },
      { value: "Tosno", label: "Tosno" },
      { value: "Tosquitas", label: "Tosquitas" },
      { value: "Transito", label: "Transito" },
      { value: "Trinchera", label: "Trinchera" },
      { value: "Tuclame", label: "Tuclame" },
      { value: "Ucacha", label: "Ucacha" },
      { value: "Unquillo", label: "Unquillo" },
      { value: "Valle Alegre", label: "Valle Alegre" },
      { value: "Valle De Anisacate", label: "Valle De Anisacate" },
      { value: "Valle Hermoso", label: "Valle Hermoso" },
      { value: "Viamonte", label: "Viamonte" },
      { value: "Vicuña Mackenna", label: "Vicuña Mackenna" },
      { value: "Villa La Bolsa", label: "Villa La Bolsa" },
      { value: "Villa Los Aromos", label: "Villa Los Aromos" },
      { value: "Villa Albertina", label: "Villa Albertina" },
      { value: "Villa Allende", label: "Villa Allende" },
      { value: "Villa Alpina", label: "Villa Alpina" },
      { value: "Villa Amancay", label: "Villa Amancay" },
      { value: "Villa Ascasubi", label: "Villa Ascasubi" },
      { value: "Villa Berna", label: "Villa Berna" },
      { value: "Villa Candelaria Norte", label: "Villa Candelaria Norte" },
      { value: "Villa Carlos Paz", label: "Villa Carlos Paz" },
      { value: "Villa Cerro Azul", label: "Villa Cerro Azul" },
      {
        value: "Villa Ciudad De America (Loteo Diego De Rojas)",
        label: "Villa Ciudad De America (Loteo Diego De Rojas)",
      },
      {
        value: "Villa Ciudad Parque Los Reartes (1A. Seccion)",
        label: "Villa Ciudad Parque Los Reartes (1A. Seccion)",
      },
      {
        value: "Villa Ciudad Parque Los Reartes (3A. Seccion)",
        label: "Villa Ciudad Parque Los Reartes (3A. Seccion)",
      },
      { value: "Villa Concepcion Del Tio", label: "Villa Concepcion Del Tio" },
      { value: "Villa Cura Brochero", label: "Villa Cura Brochero" },
      { value: "Villa De Las Rosas", label: "Villa De Las Rosas" },
      { value: "Villa De Maria", label: "Villa De Maria" },
      { value: "Villa De Pocho", label: "Villa De Pocho" },
      { value: "Villa De Soto", label: "Villa De Soto" },
      { value: "Villa Del Dique", label: "Villa Del Dique" },
      { value: "Villa Del Prado", label: "Villa Del Prado" },
      { value: "Villa Del Rosario", label: "Villa Del Rosario" },
      { value: "Villa Del Totoral", label: "Villa Del Totoral" },
      { value: "Villa Del Transito", label: "Villa Del Transito" },
      { value: "Villa Dolores", label: "Villa Dolores" },
      { value: "Villa El Chacay", label: "Villa El Chacay" },
      { value: "Villa El Fachinal", label: "Villa El Fachinal" },
      { value: "Villa El Tala", label: "Villa El Tala" },
      { value: "Villa Elisa", label: "Villa Elisa" },
      { value: "Villa Flor Serrana", label: "Villa Flor Serrana" },
      { value: "Villa Fontana", label: "Villa Fontana" },
      { value: "Villa General Belgrano", label: "Villa General Belgrano" },
      { value: "Villa Giardino", label: "Villa Giardino" },
      { value: "Villa Gutierrez", label: "Villa Gutierrez" },
      {
        value: "Villa Huidobro (Est. Cañada Verde)",
        label: "Villa Huidobro (Est. Cañada Verde)",
      },
      { value: "Villa La Donosa", label: "Villa La Donosa" },
      { value: "Villa La Rivera", label: "Villa La Rivera" },
      { value: "Villa La Viña", label: "Villa La Viña" },
      { value: "Villa Lago Azul", label: "Villa Lago Azul" },
      { value: "Villa Los Llanos", label: "Villa Los Llanos" },
      { value: "Villa Los Patos", label: "Villa Los Patos" },
      { value: "Villa Maria", label: "Villa Maria" },
      { value: "Villa Nueva", label: "Villa Nueva" },
      { value: "Villa Oeste", label: "Villa Oeste" },
      { value: "Villa Parque Santa Ana", label: "Villa Parque Santa Ana" },
      { value: "Villa Parque Siquiman", label: "Villa Parque Siquiman" },
      { value: "Villa Quilino", label: "Villa Quilino" },
      { value: "Villa Quillinzo", label: "Villa Quillinzo" },
      { value: "Villa Reduccion", label: "Villa Reduccion" },
      { value: "Villa Rio Icho Cruz", label: "Villa Rio Icho Cruz" },
      { value: "Villa Rossi", label: "Villa Rossi" },
      { value: "Villa Rumipal", label: "Villa Rumipal" },
      { value: "Villa San Esteban", label: "Villa San Esteban" },
      { value: "Villa San Isidro", label: "Villa San Isidro" },
      { value: "Villa San Jose", label: "Villa San Jose" },
      { value: "Villa San Miguel", label: "Villa San Miguel" },
      {
        value: "Villa Santa Cruz Del Lago",
        label: "Villa Santa Cruz Del Lago",
      },
      { value: "Villa Santa Eugenia", label: "Villa Santa Eugenia" },
      { value: "Villa Sarmiento", label: "Villa Sarmiento" },
      { value: "Villa Sarmiento", label: "Villa Sarmiento" },
      { value: "Villa Sierras De Oro", label: "Villa Sierras De Oro" },
      { value: "Villa Tulumba", label: "Villa Tulumba" },
      { value: "Villa Valeria", label: "Villa Valeria" },
      { value: "Villa Yacanto", label: "Villa Yacanto" },
      { value: "Washington", label: "Washington" },
      { value: "Wenceslao Escalante", label: "Wenceslao Escalante" },
      { value: "Yocsina", label: "Yocsina" },
    ],
  },
  {
    id: 18,
    value: "Corrientes",
    label: "Corrientes",
    ciudades: [
      { value: "Alvear", label: "Alvear" },
      { value: "Barrio Esperanza", label: "Barrio Esperanza" },
      { value: "Bella Vista", label: "Bella Vista" },
      { value: "Beron De Astrada", label: "Beron De Astrada" },
      { value: "Bonpland", label: "Bonpland" },
      { value: "Cazadores Correntinos", label: "Cazadores Correntinos" },
      { value: "Chavarria", label: "Chavarria" },
      {
        value: "Colonia Carlos Pellegrini",
        label: "Colonia Carlos Pellegrini",
      },
      { value: "Colonia Carolina", label: "Colonia Carolina" },
      { value: "Colonia Libertad", label: "Colonia Libertad" },
      { value: "Colonia Liebig'S", label: "Colonia Liebig'S" },
      { value: "Colonia Pando", label: "Colonia Pando" },
      { value: "Concepcion", label: "Concepcion" },
      { value: "Corrientes", label: "Corrientes" },
      { value: "Cruz De Los Milagros", label: "Cruz De Los Milagros" },
      { value: "Curuzu Cuatia", label: "Curuzu Cuatia" },
      { value: "El Sombrero", label: "El Sombrero" },
      { value: "Empedrado", label: "Empedrado" },
      { value: "Esquina", label: "Esquina" },
      { value: "Estacion Libertad", label: "Estacion Libertad" },
      { value: "Estacion Torrent", label: "Estacion Torrent" },
      { value: "Felipe Yofre", label: "Felipe Yofre" },
      { value: "Garruchos", label: "Garruchos" },
      {
        value: "Gobernador Igr. Valentin Virasoro",
        label: "Gobernador Igr. Valentin Virasoro",
      },
      {
        value: "Gobernador Juan E. Martinez",
        label: "Gobernador Juan E. Martinez",
      },
      { value: "Goya", label: "Goya" },
      { value: "Guaviravi", label: "Guaviravi" },
      {
        value: "Ingenio Primer Correntino",
        label: "Ingenio Primer Correntino",
      },
      { value: "Ita Ibate", label: "Ita Ibate" },
      { value: "Itati", label: "Itati" },
      { value: "Ituzaingo", label: "Ituzaingo" },
      {
        value: "Jose Rafael Gomez (Garabi)",
        label: "Jose Rafael Gomez (Garabi)",
      },
      { value: "Juan Pujol", label: "Juan Pujol" },
      { value: "La Cruz", label: "La Cruz" },
      { value: "Laguna Brava", label: "Laguna Brava" },
      { value: "Lavalle", label: "Lavalle" },
      { value: "Lomas De Vallejos", label: "Lomas De Vallejos" },
      { value: "Loreto", label: "Loreto" },
      {
        value: "Mariano I. Loza (Est. Justino Solari)",
        label: "Mariano I. Loza (Est. Justino Solari)",
      },
      { value: "Mburucuya", label: "Mburucuya" },
      { value: "Mercedes", label: "Mercedes" },
      { value: "Mocoreta", label: "Mocoreta" },
      { value: "Monte Caseros", label: "Monte Caseros" },
      {
        value: "Nuestra Señora Del Rosario De Caa Cati",
        label: "Nuestra Señora Del Rosario De Caa Cati",
      },
      { value: "Palmar Grande", label: "Palmar Grande" },
      { value: "Parada Acuña", label: "Parada Acuña" },
      { value: "Parada Pucheta", label: "Parada Pucheta" },
      { value: "Paso De La Patria", label: "Paso De La Patria" },
      { value: "Paso De Los Libres", label: "Paso De Los Libres" },
      {
        value: "Pedro R. Fernandez (Est. Manuel F. Mantilla)",
        label: "Pedro R. Fernandez (Est. Manuel F. Mantilla)",
      },
      { value: "Perugorria", label: "Perugorria" },
      { value: "Ramada Paso", label: "Ramada Paso" },
      { value: "Riachuelo", label: "Riachuelo" },
      { value: "Saladas", label: "Saladas" },
      { value: "San Antonio", label: "San Antonio" },
      { value: "San Carlos", label: "San Carlos" },
      { value: "San Cayetano", label: "San Cayetano" },
      { value: "San Cosme", label: "San Cosme" },
      { value: "San Lorenzo", label: "San Lorenzo" },
      { value: "San Luis Del Palmar", label: "San Luis Del Palmar" },
      { value: "San Miguel", label: "San Miguel" },
      { value: "San Roque", label: "San Roque" },
      { value: "Santa Ana", label: "Santa Ana" },
      { value: "Santa Lucia", label: "Santa Lucia" },
      { value: "Santa Rosa", label: "Santa Rosa" },
      { value: "Santo Tome", label: "Santo Tome" },
      { value: "Sauce", label: "Sauce" },
      { value: "Tabay", label: "Tabay" },
      { value: "Tapebicua", label: "Tapebicua" },
      { value: "Villa Cordoba", label: "Villa Cordoba" },
      { value: "Villa Olivari", label: "Villa Olivari" },
      { value: "Yahape", label: "Yahape" },
      { value: "Yapeyu", label: "Yapeyu" },
      {
        value: "9 De Julio (Est. Pueblo 9 De Julio)",
        label: "9 De Julio (Est. Pueblo 9 De Julio)",
      },
    ],
  },
  {
    id: 30,
    value: "Entre Rios",
    label: "Entre Rios",
    ciudades: [
      { value: "Aldea Asuncion", label: "Aldea Asuncion" },
      { value: "Aldea Brasilera", label: "Aldea Brasilera" },
      { value: "Aldea Maria Luisa", label: "Aldea Maria Luisa" },
      { value: "Aldea Protestante", label: "Aldea Protestante" },
      { value: "Aldea Salto", label: "Aldea Salto" },
      { value: "Aldea San Antonio", label: "Aldea San Antonio" },
      { value: "Aldea San Francisco", label: "Aldea San Francisco" },
      { value: "Aldea San Juan", label: "Aldea San Juan" },
      { value: "Aldea San Rafael", label: "Aldea San Rafael" },
      { value: "Aldea Santa Maria", label: "Aldea Santa Maria" },
      { value: "Aldea Santa Rosa", label: "Aldea Santa Rosa" },
      { value: "Aldea Spatzenkutter", label: "Aldea Spatzenkutter" },
      { value: "Aldea Valle Maria", label: "Aldea Valle Maria" },
      { value: "Altamirano Sur", label: "Altamirano Sur" },
      { value: "Antelo", label: "Antelo" },
      { value: "Aranguren", label: "Aranguren" },
      { value: "Arroyo Baru", label: "Arroyo Baru" },
      { value: "Arroyo Martinez", label: "Arroyo Martinez" },
      { value: "Basavilbaso", label: "Basavilbaso" },
      { value: "Benito Legeren", label: "Benito Legeren" },
      { value: "Betbeder", label: "Betbeder" },
      { value: "Bovril", label: "Bovril" },
      { value: "Calabacilla", label: "Calabacilla" },
      { value: "Caseros", label: "Caseros" },
      { value: "Ceibas", label: "Ceibas" },
      { value: "Cerrito", label: "Cerrito" },
      { value: "Chajari", label: "Chajari" },
      { value: "CharigÜE", label: "CharigÜE" },
      { value: "Clodomiro Ledesma", label: "Clodomiro Ledesma" },
      { value: "Colon", label: "Colon" },
      { value: "Colonia Alemana", label: "Colonia Alemana" },
      { value: "Colonia Avellaneda", label: "Colonia Avellaneda" },
      { value: "Colonia Avigdor", label: "Colonia Avigdor" },
      { value: "Colonia Ayui", label: "Colonia Ayui" },
      { value: "Colonia Elia", label: "Colonia Elia" },
      { value: "Colonia Ensanche Sauce", label: "Colonia Ensanche Sauce" },
      { value: "Colonia Ensayo", label: "Colonia Ensayo" },
      { value: "Colonia General Roca", label: "Colonia General Roca" },
      { value: "Colonia Hugues", label: "Colonia Hugues" },
      { value: "Colonia La Argentina", label: "Colonia La Argentina" },
      { value: "Colonia Peña", label: "Colonia Peña" },
      { value: "Colonia Santa Maria", label: "Colonia Santa Maria" },
      { value: "Colonia Yerua", label: "Colonia Yerua" },
      { value: "Concepcion Del Uruguay", label: "Concepcion Del Uruguay" },
      { value: "Concordia", label: "Concordia" },
      { value: "Conscripto Bernardi", label: "Conscripto Bernardi" },
      { value: "Crespo", label: "Crespo" },
      { value: "Diamante", label: "Diamante" },
      { value: "Don Cristobal", label: "Don Cristobal" },
      { value: "Durazno", label: "Durazno" },
      { value: "El Brillante", label: "El Brillante" },
      { value: "El Cimarron", label: "El Cimarron" },
      { value: "El Colorado", label: "El Colorado" },
      { value: "El Palenque", label: "El Palenque" },
      { value: "El Pingo", label: "El Pingo" },
      { value: "El Redomon", label: "El Redomon" },
      { value: "El Solar", label: "El Solar" },
      { value: "Enrique Carbo", label: "Enrique Carbo" },
      { value: "Estacion Arroyo Cle", label: "Estacion Arroyo Cle" },
      { value: "Estacion Camps", label: "Estacion Camps" },
      { value: "Estacion Lazo", label: "Estacion Lazo" },
      { value: "Estacion Puiggari", label: "Estacion Puiggari" },
      { value: "Estacion Raices", label: "Estacion Raices" },
      { value: "Estacion Yerua", label: "Estacion Yerua" },
      { value: "Estancia San Pedro", label: "Estancia San Pedro" },
      { value: "Faustino M. Parera", label: "Faustino M. Parera" },
      { value: "Febre", label: "Febre" },
      { value: "Federacion", label: "Federacion" },
      { value: "Federal", label: "Federal" },
      { value: "General Almada", label: "General Almada" },
      { value: "General Alvear", label: "General Alvear" },
      { value: "General Campos", label: "General Campos" },
      { value: "General Galarza", label: "General Galarza" },
      { value: "General Racedo", label: "General Racedo" },
      { value: "General Ramirez", label: "General Ramirez" },
      { value: "Gilbert", label: "Gilbert" },
      { value: "Gobernador EchagÜE", label: "Gobernador EchagÜE" },
      { value: "Gobernador Mansilla", label: "Gobernador Mansilla" },
      { value: "Gobernador Sola", label: "Gobernador Sola" },
      { value: "Gualeguay", label: "Gualeguay" },
      { value: "Gualeguaychu", label: "Gualeguaychu" },
      { value: "Guardamonte", label: "Guardamonte" },
      { value: "Hambis", label: "Hambis" },
      { value: "Hasenkamp", label: "Hasenkamp" },
      { value: "Hernandarias", label: "Hernandarias" },
      { value: "Hernandez", label: "Hernandez" },
      { value: "Herrera", label: "Herrera" },
      { value: "Hocker", label: "Hocker" },
      { value: "Ibicuy", label: "Ibicuy" },
      {
        value: "Ingeniero Miguel Sajaroff",
        label: "Ingeniero Miguel Sajaroff",
      },
      { value: "Irazusta", label: "Irazusta" },
      { value: "Jubileo", label: "Jubileo" },
      { value: "La Clarita", label: "La Clarita" },
      { value: "La Criolla", label: "La Criolla" },
      { value: "La Paz", label: "La Paz" },
      { value: "La Picada", label: "La Picada" },
      { value: "Laguna Del Pescado", label: "Laguna Del Pescado" },
      { value: "Larroque", label: "Larroque" },
      { value: "Las Guachas", label: "Las Guachas" },
      { value: "Las Moscas", label: "Las Moscas" },
      { value: "Las Tejas", label: "Las Tejas" },
      { value: "Las Tunas", label: "Las Tunas" },
      { value: "Libaros", label: "Libaros" },
      { value: "Los Charruas", label: "Los Charruas" },
      { value: "Los Conquistadores", label: "Los Conquistadores" },
      { value: "Lucas Gonzalez", label: "Lucas Gonzalez" },
      { value: "Macia", label: "Macia" },
      { value: "Maria Grande", label: "Maria Grande" },
      { value: "Medanos", label: "Medanos" },
      { value: "Mojones Norte", label: "Mojones Norte" },
      { value: "Molino Doll", label: "Molino Doll" },
      { value: "Nogoya", label: "Nogoya" },
      { value: "Nueva Escocia", label: "Nueva Escocia" },
      { value: "Nueva Vizcaya", label: "Nueva Vizcaya" },
      { value: "ñANCAY", label: "ñANCAY" },
      { value: "Oro Verde", label: "Oro Verde" },
      { value: "Osvaldo Magnasco", label: "Osvaldo Magnasco" },
      { value: "Paraje La Virgen", label: "Paraje La Virgen" },
      { value: "Parana", label: "Parana" },
      { value: "Paso De La Laguna", label: "Paso De La Laguna" },
      { value: "Pedernal", label: "Pedernal" },
      { value: "Piedras Blancas", label: "Piedras Blancas" },
      { value: "Pronunciamiento", label: "Pronunciamiento" },
      { value: "Pueblo Arrua", label: "Pueblo Arrua" },
      {
        value: "Pueblo Bellocq (Est. Las Garzas)",
        label: "Pueblo Bellocq (Est. Las Garzas)",
      },
      { value: "Pueblo Brugo", label: "Pueblo Brugo" },
      { value: "Pueblo Cazes", label: "Pueblo Cazes" },
      { value: "Pueblo General Belgrano", label: "Pueblo General Belgrano" },
      {
        value: "Pueblo General San Martin",
        label: "Pueblo General San Martin",
      },
      { value: "Pueblo Liebig'S", label: "Pueblo Liebig'S" },
      { value: "Pueblo Moreno", label: "Pueblo Moreno" },
      { value: "Puerto Las Cuevas", label: "Puerto Las Cuevas" },
      { value: "Puerto Ruiz", label: "Puerto Ruiz" },
      { value: "Puerto Yerua", label: "Puerto Yerua" },
      { value: "Rincon De Nogoya", label: "Rincon De Nogoya" },
      { value: "Rocamora", label: "Rocamora" },
      { value: "Rosario Del Tala", label: "Rosario Del Tala" },
      { value: "San Benito", label: "San Benito" },
      { value: "San Gustavo", label: "San Gustavo" },
      { value: "San Jaime De La Frontera", label: "San Jaime De La Frontera" },
      { value: "San Jose", label: "San Jose" },
      { value: "San Jose De Feliciano", label: "San Jose De Feliciano" },
      { value: "San Pedro", label: "San Pedro" },
      { value: "San Salvador", label: "San Salvador" },
      { value: "San Victor", label: "San Victor" },
      { value: "Santa Ana", label: "Santa Ana" },
      { value: "Santa Anita", label: "Santa Anita" },
      { value: "Santa Elena", label: "Santa Elena" },
      { value: "Sauce De Luna", label: "Sauce De Luna" },
      { value: "Sauce Montrull", label: "Sauce Montrull" },
      { value: "Sauce Pinto", label: "Sauce Pinto" },
      { value: "Segui", label: "Segui" },
      { value: "Sir Leonard", label: "Sir Leonard" },
      { value: "Sosa", label: "Sosa" },
      { value: "Strobel", label: "Strobel" },
      { value: "Tabossi", label: "Tabossi" },
      { value: "Tezanos Pinto", label: "Tezanos Pinto" },
      { value: "Ubajay", label: "Ubajay" },
      { value: "Urdinarrain", label: "Urdinarrain" },
      { value: "Viale", label: "Viale" },
      { value: "Victoria", label: "Victoria" },
      { value: "Villa Elisa", label: "Villa Elisa" },
      { value: "Villa Adela", label: "Villa Adela" },
      { value: "Villa Clara", label: "Villa Clara" },
      { value: "Villa Del Rosario", label: "Villa Del Rosario" },
      { value: "Villa Dominguez", label: "Villa Dominguez" },
      { value: "Villa Fontana", label: "Villa Fontana" },
      {
        value: "Villa Gdor. Luis F. Etchevehere",
        label: "Villa Gdor. Luis F. Etchevehere",
      },
      {
        value: "Villa Libertador San Martin",
        label: "Villa Libertador San Martin",
      },
      { value: "Villa Mantero", label: "Villa Mantero" },
      { value: "Villa Paranacito", label: "Villa Paranacito" },
      { value: "Villa San Justo", label: "Villa San Justo" },
      {
        value: "Villa San Marcial (Est. Gobernador Urquiza)",
        label: "Villa San Marcial (Est. Gobernador Urquiza)",
      },
      { value: "Villa Urquiza", label: "Villa Urquiza" },
      { value: "Villa Zorraquin", label: "Villa Zorraquin" },
      { value: "Villaguay", label: "Villaguay" },
      { value: "Xx De Setiembre", label: "Xx De Setiembre" },
      { value: "1º De Mayo", label: "1º De Mayo" },
    ],
  },
  {
    id: 34,
    value: "Formosa",
    label: "Formosa",
    ciudades: [
      { value: "Banco Payagua", label: "Banco Payagua" },
      { value: "Bartolome De Las Casas", label: "Bartolome De Las Casas" },
      { value: "Buena Vista", label: "Buena Vista" },
      { value: "Clorinda", label: "Clorinda" },
      { value: "Colonia Campo Villafañe", label: "Colonia Campo Villafañe" },
      { value: "Colonia Pastoril", label: "Colonia Pastoril" },
      { value: "Colonia Sarmiento", label: "Colonia Sarmiento" },
      { value: "Comandante Fontana", label: "Comandante Fontana" },
      { value: "El Colorado", label: "El Colorado" },
      { value: "El Espinillo", label: "El Espinillo" },
      { value: "El Potrillo", label: "El Potrillo" },
      { value: "El Recreo", label: "El Recreo" },
      { value: "Estanislao Del Campo", label: "Estanislao Del Campo" },
      { value: "Formosa", label: "Formosa" },
      { value: "Fortin Cabo 1º Lugones", label: "Fortin Cabo 1º Lugones" },
      { value: "Fortin Sargento 1º Leyes", label: "Fortin Sargento 1º Leyes" },
      {
        value: "General Lucio V. Mansilla",
        label: "General Lucio V. Mansilla",
      },
      { value: "General Mosconi", label: "General Mosconi" },
      { value: "Gran Guardia", label: "Gran Guardia" },
      { value: "Herradura", label: "Herradura" },
      { value: "Ibarreta", label: "Ibarreta" },
      {
        value: "Ingeniero Guillermo N. Juarez",
        label: "Ingeniero Guillermo N. Juarez",
      },
      { value: "Juan G. Bazan", label: "Juan G. Bazan" },
      { value: "Laguna Blanca", label: "Laguna Blanca" },
      { value: "Laguna Gallo", label: "Laguna Gallo" },
      { value: "Laguna Naick-Neck", label: "Laguna Naick-Neck" },
      { value: "Laguna Yema", label: "Laguna Yema" },
      { value: "Las Lomitas", label: "Las Lomitas" },
      { value: "Los Chiriguanos", label: "Los Chiriguanos" },
      { value: "Mariano Boedo", label: "Mariano Boedo" },
      { value: "Mision Tacaagle", label: "Mision Tacaagle" },
      { value: "Mojon De Fierro", label: "Mojon De Fierro" },
      { value: "Palma Sola", label: "Palma Sola" },
      { value: "Palo Santo", label: "Palo Santo" },
      { value: "Pirane", label: "Pirane" },
      { value: "Porton Negro", label: "Porton Negro" },
      { value: "Posta Cambio Zalazar", label: "Posta Cambio Zalazar" },
      { value: "Pozo De Maza", label: "Pozo De Maza" },
      { value: "Pozo Del Mortero", label: "Pozo Del Mortero" },
      { value: "Pozo Del Tigre", label: "Pozo Del Tigre" },
      { value: "Puerto Pilcomayo", label: "Puerto Pilcomayo" },
      { value: "Riacho He-He", label: "Riacho He-He" },
      { value: "Riacho Negro", label: "Riacho Negro" },
      { value: "San Francisco De Laishi", label: "San Francisco De Laishi" },
      { value: "San Hilario", label: "San Hilario" },
      { value: "San Martin I", label: "San Martin I" },
      { value: "San Martin Ii", label: "San Martin Ii" },
      { value: "Siete Palmas", label: "Siete Palmas" },
      { value: "Subteniente Perin", label: "Subteniente Perin" },
      { value: "Tatane", label: "Tatane" },
      { value: "Tres Lagunas", label: "Tres Lagunas" },
      { value: "Villa Del Carmen", label: "Villa Del Carmen" },
      { value: "Villa Escolar", label: "Villa Escolar" },
      { value: "Villa General GÜEMES", label: "Villa General GÜEMES" },
      {
        value: "Villa General Manuel Belgrano",
        label: "Villa General Manuel Belgrano",
      },
      { value: "Villa Kilometro 213", label: "Villa Kilometro 213" },
      { value: "Villa Trinidad", label: "Villa Trinidad" },
    ],
  },
  {
    id: 38,
    value: "Jujuy",
    label: "Jujuy",
    ciudades: [
      { value: "Abdon Castro Tolay", label: "Abdon Castro Tolay" },
      { value: "Abra Pampa", label: "Abra Pampa" },
      { value: "Abralaite", label: "Abralaite" },
      { value: "Aguas Calientes", label: "Aguas Calientes" },
      { value: "Arrayanal", label: "Arrayanal" },
      { value: "Arroyo Colorado", label: "Arroyo Colorado" },
      { value: "Bananal", label: "Bananal" },
      { value: "Barcena", label: "Barcena" },
      { value: "Barrio El Milagro", label: "Barrio El Milagro" },
      { value: "Barrio La Union", label: "Barrio La Union" },
      { value: "Barrio Tabacaleros", label: "Barrio Tabacaleros" },
      { value: "Barrios", label: "Barrios" },
      { value: "Bermejito", label: "Bermejito" },
      { value: "Caimancito", label: "Caimancito" },
      { value: "Calilegua", label: "Calilegua" },
      { value: "Cangrejillos", label: "Cangrejillos" },
      { value: "Carahunco", label: "Carahunco" },
      { value: "Casabindo", label: "Casabindo" },
      { value: "Casira", label: "Casira" },
      { value: "Caspala", label: "Caspala" },
      { value: "Catua", label: "Catua" },
      { value: "Centro Forestal", label: "Centro Forestal" },
      { value: "Chalican", label: "Chalican" },
      { value: "Cienega", label: "Cienega" },
      { value: "Cieneguillas", label: "Cieneguillas" },
      { value: "Cochinoca", label: "Cochinoca" },
      { value: "Coctaca", label: "Coctaca" },
      { value: "Colonia San Jose", label: "Colonia San Jose" },
      { value: "Coranzuli", label: "Coranzuli" },
      { value: "Cusi Cusi", label: "Cusi Cusi" },
      { value: "Don Emilio", label: "Don Emilio" },
      { value: "El Acheral", label: "El Acheral" },
      { value: "El Aguilar", label: "El Aguilar" },
      { value: "El Carmen", label: "El Carmen" },
      { value: "El Ceibal", label: "El Ceibal" },
      { value: "El Condor", label: "El Condor" },
      { value: "El Fuerte", label: "El Fuerte" },
      { value: "El Moreno", label: "El Moreno" },
      { value: "El Piquete", label: "El Piquete" },
      { value: "El Puesto", label: "El Puesto" },
      { value: "El Quemado", label: "El Quemado" },
      { value: "El Talar", label: "El Talar" },
      { value: "El Toro", label: "El Toro" },
      { value: "Fleming", label: "Fleming" },
      { value: "Fraile Pintado", label: "Fraile Pintado" },
      { value: "Guerrero", label: "Guerrero" },
      {
        value: "Hipolito Yrigoyen (Est. Iturbe)",
        label: "Hipolito Yrigoyen (Est. Iturbe)",
      },
      { value: "Huacalera", label: "Huacalera" },
      { value: "Huancar", label: "Huancar" },
      { value: "Humahuaca", label: "Humahuaca" },
      { value: "Juella", label: "Juella" },
      { value: "La Almona", label: "La Almona" },
      { value: "La Cienega", label: "La Cienega" },
      { value: "La Esperanza", label: "La Esperanza" },
      { value: "La Intermedia", label: "La Intermedia" },
      { value: "La Manga", label: "La Manga" },
      { value: "La Mendieta", label: "La Mendieta" },
      { value: "La Quiaca", label: "La Quiaca" },
      { value: "Lagunillas De Farallon", label: "Lagunillas De Farallon" },
      { value: "Leon", label: "Leon" },
      { value: "Libertad", label: "Libertad" },
      {
        value: "Libertador Grl. San Martin (Est. Ledesma)",
        label: "Libertador Grl. San Martin (Est. Ledesma)",
      },
      { value: "Liviara", label: "Liviara" },
      { value: "Llulluchayoc", label: "Llulluchayoc" },
      {
        value: "Los Lapachos (Est. Maquinista Veron)",
        label: "Los Lapachos (Est. Maquinista Veron)",
      },
      { value: "Los Nogales", label: "Los Nogales" },
      { value: "Loteo Navea", label: "Loteo Navea" },
      { value: "Lozano", label: "Lozano" },
      { value: "Maimara", label: "Maimara" },
      { value: "Maiz Negro", label: "Maiz Negro" },
      { value: "Manantiales", label: "Manantiales" },
      { value: "Mina Providencia", label: "Mina Providencia" },
      { value: "Mina 9 De Octubre", label: "Mina 9 De Octubre" },
      { value: "Miraflores", label: "Miraflores" },
      { value: "Misarrumi", label: "Misarrumi" },
      { value: "Monterrico", label: "Monterrico" },
      { value: "Nuevo Pirquitas", label: "Nuevo Pirquitas" },
      { value: "Ocloyas", label: "Ocloyas" },
      { value: "Olaroz Chico", label: "Olaroz Chico" },
      { value: "Oratorio", label: "Oratorio" },
      { value: "Paicone", label: "Paicone" },
      { value: "Palma Sola", label: "Palma Sola" },
      { value: "Palos Blancos", label: "Palos Blancos" },
      { value: "Palpala", label: "Palpala" },
      { value: "Pampa Blanca", label: "Pampa Blanca" },
      { value: "Pampichuela", label: "Pampichuela" },
      { value: "Parapeti", label: "Parapeti" },
      { value: "Pastos Chicos", label: "Pastos Chicos" },
      { value: "Paulina", label: "Paulina" },
      { value: "Perico", label: "Perico" },
      { value: "Piedritas", label: "Piedritas" },
      { value: "Pila Pardo", label: "Pila Pardo" },
      { value: "Puente Lavayen", label: "Puente Lavayen" },
      { value: "Puesto Del Marquez", label: "Puesto Del Marquez" },
      { value: "Puesto Sey", label: "Puesto Sey" },
      { value: "Puesto Viejo", label: "Puesto Viejo" },
      { value: "Pumahuasi", label: "Pumahuasi" },
      { value: "Purmamarca", label: "Purmamarca" },
      { value: "Rinconada", label: "Rinconada" },
      { value: "Rinconadillas", label: "Rinconadillas" },
      { value: "Rio Blanco", label: "Rio Blanco" },
      { value: "Rodeito", label: "Rodeito" },
      { value: "Rosario De Rio Grande", label: "Rosario De Rio Grande" },
      { value: "San Antonio", label: "San Antonio" },
      { value: "San Antonio", label: "San Antonio" },
      { value: "San Francisco", label: "San Francisco" },
      {
        value: "San Francisco De Alfarcito",
        label: "San Francisco De Alfarcito",
      },
      { value: "San Isidro", label: "San Isidro" },
      { value: "San Jose", label: "San Jose" },
      { value: "San Juan De Oros", label: "San Juan De Oros" },
      { value: "San Juan De Quillaques", label: "San Juan De Quillaques" },
      { value: "San Juancito", label: "San Juancito" },
      { value: "San Lucas", label: "San Lucas" },
      { value: "San Pablo De Reyes", label: "San Pablo De Reyes" },
      {
        value: "San Pedro (Est. San Pedro De Jujuy)",
        label: "San Pedro (Est. San Pedro De Jujuy)",
      },
      {
        value: "San Salvador De Jujuy (Est. Jujuy)",
        label: "San Salvador De Jujuy (Est. Jujuy)",
      },
      { value: "Santa Ana", label: "Santa Ana" },
      { value: "Santa Catalina", label: "Santa Catalina" },
      { value: "Santa Clara", label: "Santa Clara" },
      { value: "Santuario De Tres Pozos", label: "Santuario De Tres Pozos" },
      { value: "Sauzal", label: "Sauzal" },
      { value: "Susques", label: "Susques" },
      { value: "Tilcara", label: "Tilcara" },
      { value: "Tres Cruces", label: "Tres Cruces" },
      { value: "Tumbaya", label: "Tumbaya" },
      { value: "Tusaquillas", label: "Tusaquillas" },
      {
        value: "Uquia (Est. Senador Perez)",
        label: "Uquia (Est. Senador Perez)",
      },
      { value: "Valle Colorado", label: "Valle Colorado" },
      { value: "Valle Grande", label: "Valle Grande" },
      { value: "Vinalito", label: "Vinalito" },
      { value: "Volcan", label: "Volcan" },
      { value: "Yacoraite", label: "Yacoraite" },
      { value: "Yala", label: "Yala" },
      { value: "Yavi", label: "Yavi" },
      { value: "Yavi Chico", label: "Yavi Chico" },
      { value: "Yoscaba", label: "Yoscaba" },
      { value: "Yuto", label: "Yuto" },
    ],
  },
  {
    id: 42,
    value: "La Pampa",
    label: "La Pampa",
    ciudades: [
      { value: "Abramo", label: "Abramo" },
      { value: "Adolfo Van Praet", label: "Adolfo Van Praet" },
      { value: "Agustoni", label: "Agustoni" },
      { value: "Algarrobo Del Aguila", label: "Algarrobo Del Aguila" },
      { value: "Alpachiri", label: "Alpachiri" },
      { value: "Alta Italia", label: "Alta Italia" },
      { value: "Anguil", label: "Anguil" },
      { value: "Anzoategui", label: "Anzoategui" },
      { value: "Arata", label: "Arata" },
      { value: "Ataliva Roca", label: "Ataliva Roca" },
      { value: "Bernardo Larroude", label: "Bernardo Larroude" },
      { value: "Bernasconi", label: "Bernasconi" },
      { value: "Caleufu", label: "Caleufu" },
      { value: "Carro Quemado", label: "Carro Quemado" },
      { value: "Catrilo", label: "Catrilo" },
      { value: "Ceballos", label: "Ceballos" },
      { value: "Chacharramendi", label: "Chacharramendi" },
      { value: "Colonia Baron", label: "Colonia Baron" },
      { value: "Colonia San Jose", label: "Colonia San Jose" },
      { value: "Colonia Santa Maria", label: "Colonia Santa Maria" },
      { value: "Conhelo", label: "Conhelo" },
      {
        value: "Coronel Hilario Lagos (Est. Aguas Buenas)",
        label: "Coronel Hilario Lagos (Est. Aguas Buenas)",
      },
      { value: "Cuchillo Co", label: "Cuchillo Co" },
      {
        value: "Damian Maisonave (Est. Simson)",
        label: "Damian Maisonave (Est. Simson)",
      },
      { value: "Doblas", label: "Doblas" },
      { value: "Dorila", label: "Dorila" },
      { value: "Eduardo Castex", label: "Eduardo Castex" },
      { value: "Embajador Martini", label: "Embajador Martini" },
      { value: "Falucho", label: "Falucho" },
      { value: "General Acha", label: "General Acha" },
      { value: "General Manuel J. Campos", label: "General Manuel J. Campos" },
      { value: "General Pico", label: "General Pico" },
      {
        value: "General San Martin (Est. Villa Alba)",
        label: "General San Martin (Est. Villa Alba)",
      },
      { value: "Gobernador Duval", label: "Gobernador Duval" },
      { value: "Guatrache", label: "Guatrache" },
      { value: "Hucal", label: "Hucal" },
      { value: "Ingeniero Foster", label: "Ingeniero Foster" },
      { value: "Ingeniero Luiggi", label: "Ingeniero Luiggi" },
      { value: "Intendente Alvear", label: "Intendente Alvear" },
      { value: "Jacinto Arauz", label: "Jacinto Arauz" },
      { value: "La Adela", label: "La Adela" },
      { value: "La Gloria", label: "La Gloria" },
      { value: "La Humada", label: "La Humada" },
      { value: "La Maruja", label: "La Maruja" },
      { value: "La Reforma", label: "La Reforma" },
      { value: "Limay Mahuida", label: "Limay Mahuida" },
      { value: "Lonquimay", label: "Lonquimay" },
      { value: "Loventue", label: "Loventue" },
      { value: "Luan Toro", label: "Luan Toro" },
      { value: "Macachin", label: "Macachin" },
      { value: "Mauricio Mayer", label: "Mauricio Mayer" },
      { value: "Metileo", label: "Metileo" },
      { value: "Miguel Cane", label: "Miguel Cane" },
      { value: "Miguel Riglos", label: "Miguel Riglos" },
      { value: "Monte Nievas", label: "Monte Nievas" },
      { value: "Naico", label: "Naico" },
      { value: "Ojeda", label: "Ojeda" },
      { value: "Parera", label: "Parera" },
      { value: "Peru", label: "Peru" },
      { value: "Pichi Huinca", label: "Pichi Huinca" },
      { value: "Puelches", label: "Puelches" },
      { value: "Puelen", label: "Puelen" },
      { value: "Quehue", label: "Quehue" },
      { value: "Quemu Quemu", label: "Quemu Quemu" },
      { value: "Quetrequen", label: "Quetrequen" },
      { value: "Rancul", label: "Rancul" },
      { value: "Realico", label: "Realico" },
      { value: "Relmo", label: "Relmo" },
      { value: "Rolon", label: "Rolon" },
      { value: "Rucanelo", label: "Rucanelo" },
      { value: "Santa Isabel", label: "Santa Isabel" },
      { value: "Santa Rosa", label: "Santa Rosa" },
      { value: "Santa Teresa", label: "Santa Teresa" },
      { value: "Sarah", label: "Sarah" },
      { value: "Speluzzi", label: "Speluzzi" },
      { value: "Telen", label: "Telen" },
      { value: "Toay", label: "Toay" },
      { value: "Tomas M. Anchorena", label: "Tomas M. Anchorena" },
      { value: "Trebolares", label: "Trebolares" },
      { value: "Trenel", label: "Trenel" },
      { value: "Unanue", label: "Unanue" },
      { value: "Uriburu", label: "Uriburu" },
      { value: "Vertiz", label: "Vertiz" },
      { value: "Victorica", label: "Victorica" },
      { value: "Villa Mirasol", label: "Villa Mirasol" },
      { value: "Winifreda", label: "Winifreda" },
      { value: "25 De Mayo", label: "25 De Mayo" },
    ],
  },
  {
    id: 46,
    value: "La Rioja",
    label: "La Rioja",
    ciudades: [
      { value: "Aicuña", label: "Aicuña" },
      { value: "Aimogasta", label: "Aimogasta" },
      { value: "Alpasinche", label: "Alpasinche" },
      { value: "Alto Carrizal", label: "Alto Carrizal" },
      { value: "Alto JagÜE", label: "Alto JagÜE" },
      { value: "Amana", label: "Amana" },
      { value: "Ambil", label: "Ambil" },
      { value: "Aminga", label: "Aminga" },
      { value: "Amuschina", label: "Amuschina" },
      { value: "Andolucas", label: "Andolucas" },
      { value: "Anguinan", label: "Anguinan" },
      { value: "Angulos", label: "Angulos" },
      { value: "Anillaco", label: "Anillaco" },
      { value: "Anjullon", label: "Anjullon" },
      { value: "Antinaco", label: "Antinaco" },
      { value: "Arauco", label: "Arauco" },
      { value: "Bajo Carrizal", label: "Bajo Carrizal" },
      { value: "Bajo JagÜE", label: "Bajo JagÜE" },
      { value: "Banda Florida", label: "Banda Florida" },
      { value: "Bañado De Los Pantanos", label: "Bañado De Los Pantanos" },
      { value: "Campanas", label: "Campanas" },
      { value: "Castro Barros", label: "Castro Barros" },
      { value: "Chamical", label: "Chamical" },
      { value: "Chañar", label: "Chañar" },
      { value: "Chañarmuyo", label: "Chañarmuyo" },
      { value: "Chaupihuasi", label: "Chaupihuasi" },
      { value: "Chepes", label: "Chepes" },
      { value: "Chilecito", label: "Chilecito" },
      { value: "Chuquis", label: "Chuquis" },
      { value: "Colonia Anguinan", label: "Colonia Anguinan" },
      { value: "Colonia Catinzaco", label: "Colonia Catinzaco" },
      { value: "Colonia Malligasta", label: "Colonia Malligasta" },
      { value: "Colonia Ortiz De Ocampo", label: "Colonia Ortiz De Ocampo" },
      { value: "Colonia Vichigasta", label: "Colonia Vichigasta" },
      { value: "Cuipan", label: "Cuipan" },
      { value: "Desiderio Tello", label: "Desiderio Tello" },
      { value: "Estacion Mazan", label: "Estacion Mazan" },
      { value: "Famatina", label: "Famatina" },
      { value: "Guanchin", label: "Guanchin" },
      { value: "Guandacol", label: "Guandacol" },
      { value: "JagÜE", label: "JagÜE" },
      { value: "La Cuadra", label: "La Cuadra" },
      { value: "La Puntilla", label: "La Puntilla" },
      { value: "La Rioja", label: "La Rioja" },
      { value: "Las Talas", label: "Las Talas" },
      { value: "Loma Blanca", label: "Loma Blanca" },
      { value: "Los Molinos", label: "Los Molinos" },
      { value: "Los Palacios", label: "Los Palacios" },
      { value: "Los Robles", label: "Los Robles" },
      { value: "Los Sarmientos", label: "Los Sarmientos" },
      { value: "Machigasta", label: "Machigasta" },
      { value: "Malanzan", label: "Malanzan" },
      { value: "Malligasta", label: "Malligasta" },
      { value: "Milagro", label: "Milagro" },
      { value: "Miranda", label: "Miranda" },
      { value: "Nacate", label: "Nacate" },
      { value: "Nonogasta", label: "Nonogasta" },
      { value: "Olpas", label: "Olpas" },
      { value: "Olta", label: "Olta" },
      { value: "Pagancillo", label: "Pagancillo" },
      { value: "Patquia", label: "Patquia" },
      { value: "Pinchas", label: "Pinchas" },
      { value: "Pituil", label: "Pituil" },
      { value: "Plaza Vieja", label: "Plaza Vieja" },
      { value: "Polco", label: "Polco" },
      { value: "Portezuelo", label: "Portezuelo" },
      { value: "Punta De Los Llanos", label: "Punta De Los Llanos" },
      { value: "Salicas", label: "Salicas" },
      { value: "San Antonio", label: "San Antonio" },
      { value: "San Antonio", label: "San Antonio" },
      { value: "San Blas", label: "San Blas" },
      { value: "San Miguel", label: "San Miguel" },
      { value: "San Nicolas", label: "San Nicolas" },
      { value: "San Pedro", label: "San Pedro" },
      { value: "Santa Clara", label: "Santa Clara" },
      { value: "Santa Cruz", label: "Santa Cruz" },
      { value: "Santa Florentina", label: "Santa Florentina" },
      { value: "Santa Rita De Catuna", label: "Santa Rita De Catuna" },
      { value: "Santa Vera Cruz", label: "Santa Vera Cruz" },
      { value: "Santo Domingo", label: "Santo Domingo" },
      { value: "Sañogasta", label: "Sañogasta" },
      { value: "Shaqui", label: "Shaqui" },
      { value: "Suriyaco", label: "Suriyaco" },
      { value: "Tama", label: "Tama" },
      { value: "Tilimuqui", label: "Tilimuqui" },
      { value: "Tuyubil", label: "Tuyubil" },
      { value: "Ulapes", label: "Ulapes" },
      { value: "Vichigasta", label: "Vichigasta" },
      { value: "Villa Castelli", label: "Villa Castelli" },
      { value: "Villa Mazan", label: "Villa Mazan" },
      {
        value: "Villa San Jose De Vinchina",
        label: "Villa San Jose De Vinchina",
      },
      { value: "Villa Sanagasta", label: "Villa Sanagasta" },
      { value: "Villa Union", label: "Villa Union" },
    ],
  },
  {
    id: 50,
    value: "Mendoza",
    label: "Mendoza",
    ciudades: [
      { value: "Agrelo", label: "Agrelo" },
      { value: "Agua Escondida", label: "Agua Escondida" },
      { value: "Alto Salvador", label: "Alto Salvador" },
      { value: "Alto Verde", label: "Alto Verde" },
      { value: "Andrade", label: "Andrade" },
      { value: "Bardas Blancas", label: "Bardas Blancas" },
      { value: "Barrancas", label: "Barrancas" },
      { value: "Barrio Adina I Y Ii", label: "Barrio Adina I Y Ii" },
      { value: "Barrio Alto Del Olvido", label: "Barrio Alto Del Olvido" },
      { value: "Barrio Belgrano Norte", label: "Barrio Belgrano Norte" },
      {
        value: "Barrio Campos El Toledano",
        label: "Barrio Campos El Toledano",
      },
      { value: "Barrio Chivilcoy", label: "Barrio Chivilcoy" },
      {
        value: "Barrio Cooperativa Los Campamentos",
        label: "Barrio Cooperativa Los Campamentos",
      },
      { value: "Barrio Echeverria", label: "Barrio Echeverria" },
      { value: "Barrio El Cepillo", label: "Barrio El Cepillo" },
      { value: "Barrio El Nevado", label: "Barrio El Nevado" },
      { value: "Barrio Emanuel", label: "Barrio Emanuel" },
      {
        value: "Barrio Empleados De Comercio",
        label: "Barrio Empleados De Comercio",
      },
      { value: "Barrio Intendencia", label: "Barrio Intendencia" },
      { value: "Barrio Jesus De Nazaret", label: "Barrio Jesus De Nazaret" },
      { value: "Barrio Jocoli Ii", label: "Barrio Jocoli Ii" },
      { value: "Barrio La Esperanza", label: "Barrio La Esperanza" },
      { value: "Barrio La Palmera", label: "Barrio La Palmera" },
      { value: "Barrio La Pega", label: "Barrio La Pega" },
      {
        value: "Barrio Lagunas De Bartoluzzi",
        label: "Barrio Lagunas De Bartoluzzi",
      },
      { value: "Barrio Las Rosas", label: "Barrio Las Rosas" },
      { value: "Barrio Los Charabones", label: "Barrio Los Charabones" },
      { value: "Barrio Los Jarilleros", label: "Barrio Los Jarilleros" },
      { value: "Barrio Los Olivos", label: "Barrio Los Olivos" },
      { value: "Barrio Maria Auxiliadora", label: "Barrio Maria Auxiliadora" },
      { value: "Barrio Molina Cabrera", label: "Barrio Molina Cabrera" },
      { value: "Barrio Perdriel Iv", label: "Barrio Perdriel Iv" },
      { value: "Barrio Primavera", label: "Barrio Primavera" },
      { value: "Barrio Rivadavia", label: "Barrio Rivadavia" },
      { value: "Barrio San Cayetano", label: "Barrio San Cayetano" },
      { value: "Barrio 12 De Octubre", label: "Barrio 12 De Octubre" },
      { value: "Bermejo", label: "Bermejo" },
      { value: "Blanco Encalada", label: "Blanco Encalada" },
      { value: "Bowen", label: "Bowen" },
      { value: "Buena Nueva", label: "Buena Nueva" },
      { value: "Cacheuta", label: "Cacheuta" },
      { value: "Campo Los Andes", label: "Campo Los Andes" },
      { value: "Capdevila", label: "Capdevila" },
      { value: "Capdeville", label: "Capdeville" },
      { value: "Capilla Del Rosario", label: "Capilla Del Rosario" },
      { value: "Capitan Montoya", label: "Capitan Montoya" },
      { value: "Carmensa", label: "Carmensa" },
      { value: "Carrodilla", label: "Carrodilla" },
      { value: "Chacras De Coria", label: "Chacras De Coria" },
      { value: "Chapanay", label: "Chapanay" },
      { value: "Chilecito", label: "Chilecito" },
      { value: "Chivilcoy", label: "Chivilcoy" },
      { value: "Ciudad De San Martin", label: "Ciudad De San Martin" },
      { value: "Ciudad De San Rafael", label: "Ciudad De San Rafael" },
      { value: "Colonia Las Rosas", label: "Colonia Las Rosas" },
      {
        value: "Colonia Segovia (Est. Amigorena)",
        label: "Colonia Segovia (Est. Amigorena)",
      },
      { value: "Coquimbito", label: "Coquimbito" },
      { value: "Cordon Del Plata", label: "Cordon Del Plata" },
      { value: "Costa De Araujo", label: "Costa De Araujo" },
      { value: "Costa Flores", label: "Costa Flores" },
      { value: "Cruz De Piedra", label: "Cruz De Piedra" },
      { value: "Cuadro Benegas", label: "Cuadro Benegas" },
      { value: "Cuadro Nacional", label: "Cuadro Nacional" },
      { value: "Cuadro Ortega", label: "Cuadro Ortega" },
      { value: "Desaguadero", label: "Desaguadero" },
      { value: "Dorrego", label: "Dorrego" },
      { value: "El Algarrobal", label: "El Algarrobal" },
      { value: "El Borbollon", label: "El Borbollon" },
      { value: "El Carmelo", label: "El Carmelo" },
      { value: "El Carrizal", label: "El Carrizal" },
      { value: "El Challao", label: "El Challao" },
      { value: "El Manzano", label: "El Manzano" },
      { value: "El Mirador", label: "El Mirador" },
      { value: "El Nihuil", label: "El Nihuil" },
      { value: "El Paramillo", label: "El Paramillo" },
      { value: "El Pastal", label: "El Pastal" },
      { value: "El Pedregal", label: "El Pedregal" },
      { value: "El Peral", label: "El Peral" },
      { value: "El Plumerillo", label: "El Plumerillo" },
      { value: "El Resguardo", label: "El Resguardo" },
      { value: "El Salto", label: "El Salto" },
      { value: "El Sauce", label: "El Sauce" },
      { value: "El Sosneado", label: "El Sosneado" },
      { value: "El Tropezon", label: "El Tropezon" },
      { value: "El Vergel", label: "El Vergel" },
      { value: "El Zapallar", label: "El Zapallar" },
      { value: "Eugenio Bustos", label: "Eugenio Bustos" },
      { value: "Fray Luis Beltran", label: "Fray Luis Beltran" },
      {
        value: "General Alvear (Est.Colonia Alvear Norte)",
        label: "General Alvear (Est.Colonia Alvear Norte)",
      },
      { value: "General Belgrano", label: "General Belgrano" },
      { value: "General Gutierrez", label: "General Gutierrez" },
      { value: "Gobernador Benegas", label: "Gobernador Benegas" },
      { value: "Godoy Cruz", label: "Godoy Cruz" },
      { value: "Goudge", label: "Goudge" },
      { value: "Guaymallen", label: "Guaymallen" },
      { value: "Ingeniero Giagnoni", label: "Ingeniero Giagnoni" },
      { value: "Ingeniero Gustavo Andre", label: "Ingeniero Gustavo Andre" },
      { value: "Jaime Prats", label: "Jaime Prats" },
      { value: "Jesus Nazareno", label: "Jesus Nazareno" },
      { value: "Jocoli", label: "Jocoli" },
      { value: "Jocoli", label: "Jocoli" },
      { value: "Jocoli Viejo", label: "Jocoli Viejo" },
      { value: "Junin", label: "Junin" },
      { value: "La Arboleda", label: "La Arboleda" },
      { value: "La Central", label: "La Central" },
      { value: "La Cieneguita", label: "La Cieneguita" },
      { value: "La Colonia", label: "La Colonia" },
      { value: "La Consulta", label: "La Consulta" },
      { value: "La Dormida", label: "La Dormida" },
      { value: "La Esperanza", label: "La Esperanza" },
      { value: "La Florida", label: "La Florida" },
      { value: "La Libertad", label: "La Libertad" },
      { value: "La Llave Nueva", label: "La Llave Nueva" },
      { value: "La Paz", label: "La Paz" },
      { value: "La Primavera", label: "La Primavera" },
      { value: "La Puntilla", label: "La Puntilla" },
      { value: "Las Cañas", label: "Las Cañas" },
      { value: "Las Carditas", label: "Las Carditas" },
      {
        value: "Las Catitas (Est. J. N. Lencinas)",
        label: "Las Catitas (Est. J. N. Lencinas)",
      },
      { value: "Las Compuertas", label: "Las Compuertas" },
      { value: "Las Cuevas", label: "Las Cuevas" },
      { value: "Las Heras", label: "Las Heras" },
      { value: "Las Leñas", label: "Las Leñas" },
      { value: "Las Malvinas", label: "Las Malvinas" },
      { value: "Las Paredes", label: "Las Paredes" },
      { value: "Las Tortugas", label: "Las Tortugas" },
      { value: "Las Vegas", label: "Las Vegas" },
      { value: "Las Violetas", label: "Las Violetas" },
      { value: "Los Arboles", label: "Los Arboles" },
      { value: "Los Barriales", label: "Los Barriales" },
      { value: "Los Campamentos", label: "Los Campamentos" },
      { value: "Los Compartos", label: "Los Compartos" },
      { value: "Los Corralitos", label: "Los Corralitos" },
      { value: "Los Manantiales", label: "Los Manantiales" },
      { value: "Los Penitentes", label: "Los Penitentes" },
      { value: "Los Reyunos", label: "Los Reyunos" },
      { value: "Los Sauces", label: "Los Sauces" },
      { value: "Lujan De Cuyo", label: "Lujan De Cuyo" },
      { value: "Luzuriaga", label: "Luzuriaga" },
      { value: "Maipu", label: "Maipu" },
      { value: "MalargÜE", label: "MalargÜE" },
      { value: "Mayor Drummond", label: "Mayor Drummond" },
      { value: "Medrano", label: "Medrano" },
      { value: "Medrano", label: "Medrano" },
      { value: "Mendoza", label: "Mendoza" },
      { value: "Monte Coman", label: "Monte Coman" },
      { value: "Montecaseros", label: "Montecaseros" },
      { value: "Mundo Nuevo", label: "Mundo Nuevo" },
      {
        value: "Nueva California (Est. Moluches)",
        label: "Nueva California (Est. Moluches)",
      },
      { value: "Nueva Ciudad", label: "Nueva Ciudad" },
      { value: "Palmira", label: "Palmira" },
      { value: "Panquehua", label: "Panquehua" },
      { value: "Papagayos", label: "Papagayos" },
      { value: "Pareditas", label: "Pareditas" },
      { value: "Pedro Molina", label: "Pedro Molina" },
      { value: "Perdriel", label: "Perdriel" },
      { value: "Phillips", label: "Phillips" },
      { value: "Piedras Blancas", label: "Piedras Blancas" },
      { value: "Pobre Diablo", label: "Pobre Diablo" },
      { value: "Polvaredas", label: "Polvaredas" },
      { value: "Potrerillos", label: "Potrerillos" },
      { value: "Presidente Sarmiento", label: "Presidente Sarmiento" },
      { value: "Puente De Hierro", label: "Puente De Hierro" },
      { value: "Puente Del Inca", label: "Puente Del Inca" },
      { value: "Punta De Vacas", label: "Punta De Vacas" },
      { value: "Punta Del Agua", label: "Punta Del Agua" },
      { value: "Rama Caida", label: "Rama Caida" },
      { value: "Real Del Padre", label: "Real Del Padre" },
      { value: "Reduccion De Abajo", label: "Reduccion De Abajo" },
      { value: "Rivadavia", label: "Rivadavia" },
      { value: "Rodeo De La Cruz", label: "Rodeo De La Cruz" },
      { value: "Rodeo Del Medio", label: "Rodeo Del Medio" },
      { value: "Rodriguez Peña", label: "Rodriguez Peña" },
      { value: "Russell", label: "Russell" },
      { value: "Salto De Las Rosas", label: "Salto De Las Rosas" },
      { value: "San Carlos", label: "San Carlos" },
      { value: "San Francisco Del Monte", label: "San Francisco Del Monte" },
      { value: "San Francisco Del Monte", label: "San Francisco Del Monte" },
      { value: "San Jose", label: "San Jose" },
      { value: "San Jose", label: "San Jose" },
      { value: "San Roque", label: "San Roque" },
      { value: "Santa Maria De Oro", label: "Santa Maria De Oro" },
      { value: "Santa Rosa", label: "Santa Rosa" },
      { value: "Tres Esquinas", label: "Tres Esquinas" },
      { value: "Tres Porteñas", label: "Tres Porteñas" },
      { value: "Tunuyan", label: "Tunuyan" },
      { value: "Tupungato", label: "Tupungato" },
      { value: "Ugarteche", label: "Ugarteche" },
      { value: "Uspallata", label: "Uspallata" },
      { value: "Valle Del Sol", label: "Valle Del Sol" },
      { value: "Villa Antigua", label: "Villa Antigua" },
      { value: "Villa Atuel", label: "Villa Atuel" },
      { value: "Villa Atuel Norte", label: "Villa Atuel Norte" },
      { value: "Villa Bastias", label: "Villa Bastias" },
      { value: "Villa El Refugio", label: "Villa El Refugio" },
      { value: "Villa Nueva", label: "Villa Nueva" },
      { value: "Villa Teresa", label: "Villa Teresa" },
      { value: "Villa Tulumaya", label: "Villa Tulumaya" },
      { value: "Vista Flores", label: "Vista Flores" },
      { value: "Vistalba", label: "Vistalba" },
      { value: "25 De Mayo", label: "25 De Mayo" },
      { value: "3 De Mayo", label: "3 De Mayo" },
    ],
  },
  {
    id: 54,
    value: "Misiones",
    label: "Misiones",
    ciudades: [
      { value: "Alba Posse", label: "Alba Posse" },
      { value: "Alberdi", label: "Alberdi" },
      { value: "Almafuerte", label: "Almafuerte" },
      { value: "Almirante Brown", label: "Almirante Brown" },
      { value: "Apostoles", label: "Apostoles" },
      { value: "Aristobulo Del Valle", label: "Aristobulo Del Valle" },
      { value: "Arroyo Del Medio", label: "Arroyo Del Medio" },
      { value: "Azara", label: "Azara" },
      { value: "Barra Concepcion", label: "Barra Concepcion" },
      { value: "Bernardo De Irigoyen", label: "Bernardo De Irigoyen" },
      { value: "Bonpland", label: "Bonpland" },
      { value: "Caa - Yari", label: "Caa - Yari" },
      { value: "Campo Grande", label: "Campo Grande" },
      { value: "Campo Ramon", label: "Campo Ramon" },
      { value: "Campo Viera", label: "Campo Viera" },
      { value: "Candelaria", label: "Candelaria" },
      { value: "Capiovi", label: "Capiovi" },
      { value: "Caraguatay", label: "Caraguatay" },
      { value: "Cerro Azul", label: "Cerro Azul" },
      { value: "Cerro Cora", label: "Cerro Cora" },
      { value: "Colonia Alicia", label: "Colonia Alicia" },
      { value: "Colonia Aurora", label: "Colonia Aurora" },
      { value: "Colonia Polana", label: "Colonia Polana" },
      { value: "Colonia Victoria", label: "Colonia Victoria" },
      { value: "Concepcion De La Sierra", label: "Concepcion De La Sierra" },
      { value: "Corpus", label: "Corpus" },
      { value: "Cruce Caballero", label: "Cruce Caballero" },
      { value: "Domingo Savio", label: "Domingo Savio" },
      { value: "Dos Arroyos", label: "Dos Arroyos" },
      { value: "Dos De Mayo Nucleo I", label: "Dos De Mayo Nucleo I" },
      { value: "Dos De Mayo Nucleo Ii", label: "Dos De Mayo Nucleo Ii" },
      { value: "Dos De Mayo Nucleo Iii", label: "Dos De Mayo Nucleo Iii" },
      { value: "Dos Hermanas", label: "Dos Hermanas" },
      { value: "El Alcazar", label: "El Alcazar" },
      { value: "El Salto", label: "El Salto" },
      { value: "El Soberbio", label: "El Soberbio" },
      { value: "Eldorado", label: "Eldorado" },
      { value: "Esperanza", label: "Esperanza" },
      { value: "Estacion Apostoles", label: "Estacion Apostoles" },
      { value: "Florentino Ameghino", label: "Florentino Ameghino" },
      { value: "Fracran", label: "Fracran" },
      { value: "Garuhape", label: "Garuhape" },
      { value: "Garupa", label: "Garupa" },
      { value: "General Alvear", label: "General Alvear" },
      { value: "General Urquiza", label: "General Urquiza" },
      { value: "Gobernador Lopez", label: "Gobernador Lopez" },
      { value: "Gobernador Roca", label: "Gobernador Roca" },
      { value: "Guarani", label: "Guarani" },
      { value: "Helvecia", label: "Helvecia" },
      { value: "Hipolito Yrigoyen", label: "Hipolito Yrigoyen" },
      { value: "Integracion", label: "Integracion" },
      { value: "Itacaruare", label: "Itacaruare" },
      { value: "Jardin America", label: "Jardin America" },
      { value: "La Corita", label: "La Corita" },
      { value: "Laharrague", label: "Laharrague" },
      { value: "Leandro N. Alem", label: "Leandro N. Alem" },
      { value: "Libertad", label: "Libertad" },
      { value: "Loreto", label: "Loreto" },
      { value: "Los Helechos", label: "Los Helechos" },
      { value: "Maria Magdalena", label: "Maria Magdalena" },
      { value: "Martires", label: "Martires" },
      { value: "Mbopicua", label: "Mbopicua" },
      { value: "Mojon Grande", label: "Mojon Grande" },
      { value: "Montecarlo", label: "Montecarlo" },
      { value: "Nemesio Parma", label: "Nemesio Parma" },
      { value: "Oasis", label: "Oasis" },
      { value: "Obera", label: "Obera" },
      { value: "Olegario V. Andrade", label: "Olegario V. Andrade" },
      { value: "Panambi", label: "Panambi" },
      { value: "Panambi Kilometro 8", label: "Panambi Kilometro 8" },
      { value: "Paraiso", label: "Paraiso" },
      { value: "Pindapoy", label: "Pindapoy" },
      { value: "Piñalito Sur", label: "Piñalito Sur" },
      { value: "Piray Kilometro 18", label: "Piray Kilometro 18" },
      { value: "Posadas", label: "Posadas" },
      { value: "Profundidad", label: "Profundidad" },
      { value: "Pueblo Illia", label: "Pueblo Illia" },
      { value: "Puerto Iguazu", label: "Puerto Iguazu" },
      { value: "Puerto Leoni", label: "Puerto Leoni" },
      { value: "Puerto Mado", label: "Puerto Mado" },
      { value: "Puerto Pinares", label: "Puerto Pinares" },
      { value: "Puerto Piray", label: "Puerto Piray" },
      { value: "Puerto Rico", label: "Puerto Rico" },
      { value: "Puerto Santa Ana", label: "Puerto Santa Ana" },
      { value: "Rincon De Azara", label: "Rincon De Azara" },
      { value: "Roca Chica", label: "Roca Chica" },
      { value: "Ruiz De Montoya", label: "Ruiz De Montoya" },
      { value: "Salto Encantado", label: "Salto Encantado" },
      { value: "San Alberto", label: "San Alberto" },
      { value: "San Antonio", label: "San Antonio" },
      { value: "San Francisco De Asis", label: "San Francisco De Asis" },
      { value: "San Gotardo", label: "San Gotardo" },
      { value: "San Ignacio", label: "San Ignacio" },
      { value: "San Javier", label: "San Javier" },
      { value: "San Jose", label: "San Jose" },
      { value: "San Martin", label: "San Martin" },
      { value: "San Miguel", label: "San Miguel" },
      { value: "San Pedro", label: "San Pedro" },
      { value: "San Vicente", label: "San Vicente" },
      { value: "Santa Ana", label: "Santa Ana" },
      { value: "Santa Maria", label: "Santa Maria" },
      { value: "Santa Rita", label: "Santa Rita" },
      { value: "Santiago De Liniers", label: "Santiago De Liniers" },
      { value: "Santo Pipo", label: "Santo Pipo" },
      { value: "Taruma", label: "Taruma" },
      { value: "Tobuna", label: "Tobuna" },
      { value: "Tres Capones", label: "Tres Capones" },
      { value: "Valle Hermoso", label: "Valle Hermoso" },
      { value: "Villa Akerman", label: "Villa Akerman" },
      { value: "Villa Bonita", label: "Villa Bonita" },
      { value: "Villa Libertad", label: "Villa Libertad" },
      { value: "Villa Parodi", label: "Villa Parodi" },
      { value: "Villa Roulet", label: "Villa Roulet" },
      { value: "Wanda", label: "Wanda" },
      { value: "1º De Mayo", label: "1º De Mayo" },
      { value: "25 De Mayo", label: "25 De Mayo" },
      { value: "9 De Julio", label: "9 De Julio" },
      { value: "9 De Julio Kilometro 20", label: "9 De Julio Kilometro 20" },
    ],
  },
  {
    id: 58,
    value: "Neuquen",
    label: "Neuquen",
    ciudades: [
      { value: "Alumine", label: "Alumine" },
      { value: "Andacollo", label: "Andacollo" },
      { value: "Añelo", label: "Añelo" },
      { value: "Arroyito", label: "Arroyito" },
      { value: "Bajada Del Agrio", label: "Bajada Del Agrio" },
      { value: "Barrancas", label: "Barrancas" },
      { value: "Barrio Ruca Luhe", label: "Barrio Ruca Luhe" },
      { value: "Buta Ranquil", label: "Buta Ranquil" },
      { value: "Caviahue", label: "Caviahue" },
      { value: "Centenario", label: "Centenario" },
      { value: "Chorriaca", label: "Chorriaca" },
      { value: "Chos Malal", label: "Chos Malal" },
      { value: "Copahue", label: "Copahue" },
      { value: "Covunco Centro", label: "Covunco Centro" },
      { value: "Cutral Co", label: "Cutral Co" },
      { value: "El Chocon", label: "El Chocon" },
      { value: "El Cholar", label: "El Cholar" },
      { value: "El Huecu", label: "El Huecu" },
      { value: "Huinganco", label: "Huinganco" },
      { value: "Junin De Los Andes", label: "Junin De Los Andes" },
      { value: "La Buitrera", label: "La Buitrera" },
      { value: "Las Coloradas", label: "Las Coloradas" },
      { value: "Las Lajas", label: "Las Lajas" },
      { value: "Las Ovejas", label: "Las Ovejas" },
      { value: "Loncopue", label: "Loncopue" },
      { value: "Los Catutos", label: "Los Catutos" },
      { value: "Los Miches", label: "Los Miches" },
      { value: "Manzano Amargo", label: "Manzano Amargo" },
      { value: "Mari Menuco", label: "Mari Menuco" },
      { value: "Mariano Moreno", label: "Mariano Moreno" },
      { value: "Neuquen", label: "Neuquen" },
      { value: "Octavio Pico", label: "Octavio Pico" },
      { value: "Paso Aguerre", label: "Paso Aguerre" },
      { value: "Picun Leufu", label: "Picun Leufu" },
      { value: "Piedra Del Aguila", label: "Piedra Del Aguila" },
      { value: "Plaza Huincul", label: "Plaza Huincul" },
      { value: "Plottier", label: "Plottier" },
      { value: "Quili Malal", label: "Quili Malal" },
      { value: "Ramon M. Castro", label: "Ramon M. Castro" },
      { value: "Rincon De Los Sauces", label: "Rincon De Los Sauces" },
      { value: "San Martin De Los Andes", label: "San Martin De Los Andes" },
      { value: "San Patricio Del Chañar", label: "San Patricio Del Chañar" },
      { value: "Santo Tomas", label: "Santo Tomas" },
      { value: "Senillosa", label: "Senillosa" },
      { value: "Taquimilan", label: "Taquimilan" },
      { value: "Tricao Malal", label: "Tricao Malal" },
      { value: "Varvarco", label: "Varvarco" },
      { value: "Villa Del Curi Leuvu", label: "Villa Del Curi Leuvu" },
      { value: "Villa El Chocon", label: "Villa El Chocon" },
      { value: "Villa La Angostura", label: "Villa La Angostura" },
      { value: "Villa Pehuenia", label: "Villa Pehuenia" },
      { value: "Villa Traful", label: "Villa Traful" },
      { value: "Vista Alegre Norte", label: "Vista Alegre Norte" },
      { value: "Vista Alegre Sur", label: "Vista Alegre Sur" },
      { value: "Zapala", label: "Zapala" },
      { value: "11 De Octubre", label: "11 De Octubre" },
    ],
  },
  {
    id: 62,
    value: "Rio Negro",
    label: "Rio Negro",
    ciudades: [
      { value: "Aguada Cecilio", label: "Aguada Cecilio" },
      { value: "Aguada De Guerra", label: "Aguada De Guerra" },
      { value: "Aguada Guzman", label: "Aguada Guzman" },
      { value: "Allen", label: "Allen" },
      { value: "Arroyo Los Berros", label: "Arroyo Los Berros" },
      { value: "Arroyo Ventana", label: "Arroyo Ventana" },
      { value: "Bahia Creek", label: "Bahia Creek" },
      { value: "Bajo San Cayetano", label: "Bajo San Cayetano" },
      { value: "Barda Del Medio", label: "Barda Del Medio" },
      { value: "Barrio Blanco", label: "Barrio Blanco" },
      { value: "Barrio Calle Ciega Nº 10", label: "Barrio Calle Ciega Nº 10" },
      { value: "Barrio Calle Ciega Nº 6", label: "Barrio Calle Ciega Nº 6" },
      { value: "Barrio Canale", label: "Barrio Canale" },
      { value: "Barrio Chacra Monte", label: "Barrio Chacra Monte" },
      { value: "Barrio Colonia Conesa", label: "Barrio Colonia Conesa" },
      { value: "Barrio Costa Este", label: "Barrio Costa Este" },
      { value: "Barrio Costa Linda", label: "Barrio Costa Linda" },
      { value: "Barrio Costa Oeste", label: "Barrio Costa Oeste" },
      { value: "Barrio El Labrador", label: "Barrio El Labrador" },
      { value: "Barrio El Maruchito", label: "Barrio El Maruchito" },
      { value: "Barrio El Petroleo", label: "Barrio El Petroleo" },
      { value: "Barrio El Pilar", label: "Barrio El Pilar" },
      { value: "Barrio El Treinta", label: "Barrio El Treinta" },
      { value: "Barrio Frontera", label: "Barrio Frontera" },
      { value: "Barrio Goretti", label: "Barrio Goretti" },
      { value: "Barrio Guerrico", label: "Barrio Guerrico" },
      { value: "Barrio Isla 10", label: "Barrio Isla 10" },
      { value: "Barrio La Barda", label: "Barrio La Barda" },
      { value: "Barrio La Costa", label: "Barrio La Costa" },
      { value: "Barrio La Defensa", label: "Barrio La Defensa" },
      { value: "Barrio La Lor", label: "Barrio La Lor" },
      {
        value: "Barrio La Ribera - Barrio Apycar",
        label: "Barrio La Ribera - Barrio Apycar",
      },
      { value: "Barrio Las Angustias", label: "Barrio Las Angustias" },
      { value: "Barrio Mar Del Plata", label: "Barrio Mar Del Plata" },
      { value: "Barrio Maria Elvira", label: "Barrio Maria Elvira" },
      { value: "Barrio Mosconi", label: "Barrio Mosconi" },
      { value: "Barrio Norte", label: "Barrio Norte" },
      { value: "Barrio Pino Azul", label: "Barrio Pino Azul" },
      { value: "Barrio Porvenir", label: "Barrio Porvenir" },
      { value: "Barrio Presidente Peron", label: "Barrio Presidente Peron" },
      { value: "Barrio Puente De Madera", label: "Barrio Puente De Madera" },
      { value: "Barrio Puente 83", label: "Barrio Puente 83" },
      { value: "Barrio Santa Rita", label: "Barrio Santa Rita" },
      { value: "Barrio Tres Luces", label: "Barrio Tres Luces" },
      { value: "Barrio Union", label: "Barrio Union" },
      { value: "Barrio Union", label: "Barrio Union" },
      { value: "Barrio Virgen De Lujan", label: "Barrio Virgen De Lujan" },
      { value: "Catriel", label: "Catriel" },
      { value: "Cerro Policia", label: "Cerro Policia" },
      { value: "Cervantes", label: "Cervantes" },
      { value: "Chelforo", label: "Chelforo" },
      { value: "Chichinales", label: "Chichinales" },
      { value: "Chimpay", label: "Chimpay" },
      { value: "Choele Choel", label: "Choele Choel" },
      { value: "Cinco Saltos", label: "Cinco Saltos" },
      { value: "Cipolletti", label: "Cipolletti" },
      { value: "Clemente Onelli", label: "Clemente Onelli" },
      { value: "Colan Conhue", label: "Colan Conhue" },
      { value: "Colonia Julia Y Echarren", label: "Colonia Julia Y Echarren" },
      { value: "Colonia Suiza", label: "Colonia Suiza" },
      { value: "Comallo", label: "Comallo" },
      { value: "Comico", label: "Comico" },
      { value: "Cona Niyeu", label: "Cona Niyeu" },
      { value: "Contralmirante Cordero", label: "Contralmirante Cordero" },
      { value: "Coronel Belisle", label: "Coronel Belisle" },
      { value: "Darwin", label: "Darwin" },
      { value: "Dina Huapi", label: "Dina Huapi" },
      { value: "El Bolson", label: "El Bolson" },
      { value: "El Cain", label: "El Cain" },
      { value: "El Condor", label: "El Condor" },
      { value: "El Cuy", label: "El Cuy" },
      { value: "El Foyel", label: "El Foyel" },
      { value: "El Juncal", label: "El Juncal" },
      { value: "Ferri", label: "Ferri" },
      { value: "General Conesa", label: "General Conesa" },
      { value: "General Enrique Godoy", label: "General Enrique Godoy" },
      { value: "General Fernandez Oro", label: "General Fernandez Oro" },
      { value: "General Roca", label: "General Roca" },
      { value: "Guardia Mitre", label: "Guardia Mitre" },
      { value: "Ingeniero Jacobacci", label: "Ingeniero Jacobacci" },
      { value: "Ingeniero Luis A. Huergo", label: "Ingeniero Luis A. Huergo" },
      { value: "Ingeniero Otto Krause", label: "Ingeniero Otto Krause" },
      { value: "La Loberia", label: "La Loberia" },
      { value: "Laguna Blanca", label: "Laguna Blanca" },
      { value: "Lamarque", label: "Lamarque" },
      { value: "Las Bayas", label: "Las Bayas" },
      { value: "Las Grutas", label: "Las Grutas" },
      { value: "Las Perlas", label: "Las Perlas" },
      { value: "Los Menucos", label: "Los Menucos" },
      { value: "Loteo Costa De Rio", label: "Loteo Costa De Rio" },
      { value: "Luis Beltran", label: "Luis Beltran" },
      { value: "Mainque", label: "Mainque" },
      { value: "Mamuel Choique", label: "Mamuel Choique" },
      { value: "Maquinchao", label: "Maquinchao" },
      { value: "Mencue", label: "Mencue" },
      { value: "Mina Santa Teresita", label: "Mina Santa Teresita" },
      { value: "Ministro Ramos Mexia", label: "Ministro Ramos Mexia" },
      { value: "Nahuel Niyeu", label: "Nahuel Niyeu" },
      { value: "Naupa Huen", label: "Naupa Huen" },
      { value: "ñIRIHUAU", label: "ñIRIHUAU" },
      { value: "ñORQUINCO", label: "ñORQUINCO" },
      { value: "Ojos De Agua", label: "Ojos De Agua" },
      { value: "Paso Cordova", label: "Paso Cordova" },
      { value: "Paso Cordova", label: "Paso Cordova" },
      { value: "Paso Flores", label: "Paso Flores" },
      { value: "Peninsula Ruca Co", label: "Peninsula Ruca Co" },
      { value: "Peñas Blancas", label: "Peñas Blancas" },
      { value: "Pilcaniyeu", label: "Pilcaniyeu" },
      { value: "Pilquiniyeu", label: "Pilquiniyeu" },
      { value: "Pilquiniyeu Del Limay", label: "Pilquiniyeu Del Limay" },
      { value: "Playas Doradas", label: "Playas Doradas" },
      { value: "Pomona", label: "Pomona" },
      { value: "Pozo Salado", label: "Pozo Salado" },
      { value: "Prahuaniyeu", label: "Prahuaniyeu" },
      { value: "Puerto San Antonio Este", label: "Puerto San Antonio Este" },
      { value: "Punta Colorada", label: "Punta Colorada" },
      {
        value: "Rio Chico (Est. Cerro Mesa)",
        label: "Rio Chico (Est. Cerro Mesa)",
      },
      { value: "Rio Colorado", label: "Rio Colorado" },
      { value: "Rio Villegas", label: "Rio Villegas" },
      { value: "San Antonio Oeste", label: "San Antonio Oeste" },
      { value: "San Carlos De Bariloche", label: "San Carlos De Bariloche" },
      { value: "San Javier", label: "San Javier" },
      { value: "Sargento Vidal", label: "Sargento Vidal" },
      { value: "Sierra Colorada", label: "Sierra Colorada" },
      { value: "Sierra Grande", label: "Sierra Grande" },
      { value: "Sierra Paileman", label: "Sierra Paileman" },
      { value: "Treneta", label: "Treneta" },
      { value: "Valcheta", label: "Valcheta" },
      { value: "Valle Azul", label: "Valle Azul" },
      { value: "Viedma", label: "Viedma" },
      { value: "Villa Alberdi", label: "Villa Alberdi" },
      { value: "Villa Campanario", label: "Villa Campanario" },
      { value: "Villa Catedral", label: "Villa Catedral" },
      { value: "Villa Del Parque", label: "Villa Del Parque" },
      { value: "Villa Llanquin", label: "Villa Llanquin" },
      { value: "Villa Llao Llao", label: "Villa Llao Llao" },
      { value: "Villa Los Coihues", label: "Villa Los Coihues" },
      { value: "Villa Manzano", label: "Villa Manzano" },
      { value: "Villa Regina", label: "Villa Regina" },
      { value: "Villa San Isidro", label: "Villa San Isidro" },
      { value: "Yaminue", label: "Yaminue" },
    ],
  },
  {
    id: 66,
    value: "Salta",
    label: "Salta",
    ciudades: [
      { value: "Acoyte", label: "Acoyte" },
      { value: "Aguaray", label: "Aguaray" },
      { value: "Aguas Blancas", label: "Aguas Blancas" },
      { value: "Alto De La Sierra", label: "Alto De La Sierra" },
      { value: "Ampascachi", label: "Ampascachi" },
      { value: "Angastaco", label: "Angastaco" },
      { value: "Animana", label: "Animana" },
      { value: "Antilla", label: "Antilla" },
      { value: "Apolinario Saravia", label: "Apolinario Saravia" },
      { value: "Atocha", label: "Atocha" },
      {
        value: "Barrio El Jardin De San Martin",
        label: "Barrio El Jardin De San Martin",
      },
      { value: "Barrio El Milagro", label: "Barrio El Milagro" },
      { value: "Barrio San Rafael", label: "Barrio San Rafael" },
      { value: "Cabra Corral", label: "Cabra Corral" },
      { value: "Cachi", label: "Cachi" },
      { value: "Cafayate", label: "Cafayate" },
      { value: "Campamento Vespucio", label: "Campamento Vespucio" },
      { value: "Campichuelo", label: "Campichuelo" },
      { value: "Campo Blanco", label: "Campo Blanco" },
      { value: "Campo Duran", label: "Campo Duran" },
      { value: "Campo La Cruz", label: "Campo La Cruz" },
      { value: "Campo Quijano", label: "Campo Quijano" },
      { value: "Campo Santo", label: "Campo Santo" },
      { value: "Capiazuti", label: "Capiazuti" },
      { value: "Capitan Juan Page", label: "Capitan Juan Page" },
      { value: "Carboncito", label: "Carboncito" },
      { value: "Ceibalito", label: "Ceibalito" },
      { value: "Centro 25 De Junio", label: "Centro 25 De Junio" },
      { value: "Cerrillos", label: "Cerrillos" },
      { value: "Chicoana", label: "Chicoana" },
      { value: "Cobos", label: "Cobos" },
      { value: "Cobres", label: "Cobres" },
      { value: "Colonia Santa Rosa", label: "Colonia Santa Rosa" },
      { value: "Copo Quile", label: "Copo Quile" },
      { value: "Coronel Cornejo", label: "Coronel Cornejo" },
      {
        value: "Coronel Juan Sola (Est. Morillo)",
        label: "Coronel Juan Sola (Est. Morillo)",
      },
      { value: "Coronel Moldes", label: "Coronel Moldes" },
      { value: "Coronel Mollinedo", label: "Coronel Mollinedo" },
      { value: "Coronel Olleros", label: "Coronel Olleros" },
      { value: "Country Club El Tipal", label: "Country Club El Tipal" },
      { value: "Country Club La Almudena", label: "Country Club La Almudena" },
      { value: "Dragones", label: "Dragones" },
      { value: "El Barrial", label: "El Barrial" },
      { value: "El Bordo", label: "El Bordo" },
      { value: "El Carril", label: "El Carril" },
      {
        value: "El Galpon (Est. Foguista J. F. Juarez)",
        label: "El Galpon (Est. Foguista J. F. Juarez)",
      },
      { value: "El Jardin", label: "El Jardin" },
      { value: "El Naranjo", label: "El Naranjo" },
      {
        value: "El Potrero (Apeadero Cochabamba)",
        label: "El Potrero (Apeadero Cochabamba)",
      },
      { value: "El Quebrachal", label: "El Quebrachal" },
      { value: "El Tabacal", label: "El Tabacal" },
      {
        value: "El Tala (Est. Ruiz De Los Llanos)",
        label: "El Tala (Est. Ruiz De Los Llanos)",
      },
      { value: "El Tunal", label: "El Tunal" },
      { value: "Embarcacion", label: "Embarcacion" },
      { value: "Gaona", label: "Gaona" },
      { value: "General Ballivian", label: "General Ballivian" },
      {
        value: "General GÜEMES (Est. GÜEMES)",
        label: "General GÜEMES (Est. GÜEMES)",
      },
      {
        value: "General Mosconi (Est. Vespucio)",
        label: "General Mosconi (Est. Vespucio)",
      },
      { value: "General Pizarro", label: "General Pizarro" },
      { value: "Guachipas", label: "Guachipas" },
      { value: "Hickman", label: "Hickman" },
      {
        value: "Hipolito Yrigoyen (Est. Tabacal)",
        label: "Hipolito Yrigoyen (Est. Tabacal)",
      },
      { value: "Iruya", label: "Iruya" },
      { value: "Isla De Cañas", label: "Isla De Cañas" },
      { value: "Joaquin V. Gonzalez", label: "Joaquin V. Gonzalez" },
      { value: "La Caldera", label: "La Caldera" },
      { value: "La Candelaria", label: "La Candelaria" },
      { value: "La Cienaga", label: "La Cienaga" },
      { value: "La Merced", label: "La Merced" },
      { value: "La Mision", label: "La Mision" },
      { value: "La Poma", label: "La Poma" },
      { value: "La Puerta", label: "La Puerta" },
      { value: "La Silleta", label: "La Silleta" },
      { value: "La Union", label: "La Union" },
      { value: "La Viña", label: "La Viña" },
      { value: "Las Costas", label: "Las Costas" },
      { value: "Las Lajitas", label: "Las Lajitas" },
      { value: "Los Blancos", label: "Los Blancos" },
      { value: "Los Toldos", label: "Los Toldos" },
      { value: "Luis Burela", label: "Luis Burela" },
      { value: "Lumbreras", label: "Lumbreras" },
      { value: "Macapillo", label: "Macapillo" },
      { value: "Metan Viejo", label: "Metan Viejo" },
      { value: "Mision Chaqueña", label: "Mision Chaqueña" },
      { value: "Mision Curva El Talar", label: "Mision Curva El Talar" },
      { value: "Mision El Cruce", label: "Mision El Cruce" },
      { value: "Mision El Siwok", label: "Mision El Siwok" },
      { value: "Mision Kilometro 6", label: "Mision Kilometro 6" },
      { value: "Mision La Mora", label: "Mision La Mora" },
      { value: "Mision Lapacho I", label: "Mision Lapacho I" },
      { value: "Mision Lapacho Ii", label: "Mision Lapacho Ii" },
      { value: "Mision Tierras Fiscales", label: "Mision Tierras Fiscales" },
      { value: "Molinos", label: "Molinos" },
      { value: "Nazareno", label: "Nazareno" },
      {
        value: "Nuestra Señora De Talavera",
        label: "Nuestra Señora De Talavera",
      },
      { value: "Olacapato", label: "Olacapato" },
      { value: "Pacara", label: "Pacara" },
      { value: "Padre Lozano", label: "Padre Lozano" },
      { value: "Payogasta", label: "Payogasta" },
      { value: "Pichanal", label: "Pichanal" },
      { value: "Piquete Cabado", label: "Piquete Cabado" },
      { value: "Piquirenda", label: "Piquirenda" },
      { value: "Pluma De Pato", label: "Pluma De Pato" },
      { value: "Poscaya", label: "Poscaya" },
      { value: "Presa El Tunal", label: "Presa El Tunal" },
      {
        value: "Profesor Salvador Mazza (Est. Pocitos)",
        label: "Profesor Salvador Mazza (Est. Pocitos)",
      },
      { value: "Pueblo Viejo", label: "Pueblo Viejo" },
      { value: "Recaredo", label: "Recaredo" },
      { value: "Rio Del Valle", label: "Rio Del Valle" },
      { value: "Rio Piedras", label: "Rio Piedras" },
      { value: "Rivadavia", label: "Rivadavia" },
      { value: "Rosario De La Frontera", label: "Rosario De La Frontera" },
      { value: "Rosario De Lerma", label: "Rosario De Lerma" },
      { value: "Salta", label: "Salta" },
      { value: "San Agustin", label: "San Agustin" },
      {
        value: "San Antonio De Los Cobres",
        label: "San Antonio De Los Cobres",
      },
      { value: "San Carlos", label: "San Carlos" },
      { value: "San Felipe", label: "San Felipe" },
      {
        value: "San Jose De Metan (Est. Metan)",
        label: "San Jose De Metan (Est. Metan)",
      },
      { value: "San Jose De Orquera", label: "San Jose De Orquera" },
      { value: "San Marcos", label: "San Marcos" },
      {
        value: "San Ramon De La Nueva Oran (Est. Oran)",
        label: "San Ramon De La Nueva Oran (Est. Oran)",
      },
      { value: "Santa Maria", label: "Santa Maria" },
      { value: "Santa Rosa", label: "Santa Rosa" },
      {
        value: "Santa Rosa De Los Pastos Grandes",
        label: "Santa Rosa De Los Pastos Grandes",
      },
      { value: "Santa Victoria", label: "Santa Victoria" },
      { value: "Santa Victoria Este", label: "Santa Victoria Este" },
      { value: "Seclantas", label: "Seclantas" },
      { value: "Talapampa", label: "Talapampa" },
      { value: "Tartagal", label: "Tartagal" },
      { value: "Tobantirenda", label: "Tobantirenda" },
      { value: "Tolar Grande", label: "Tolar Grande" },
      { value: "Tolloche", label: "Tolloche" },
      { value: "Tolombon", label: "Tolombon" },
      { value: "Tranquitas", label: "Tranquitas" },
      { value: "Urundel", label: "Urundel" },
      { value: "Vaqueros", label: "Vaqueros" },
      { value: "Villa Los Alamos", label: "Villa Los Alamos" },
      { value: "Villa San Lorenzo", label: "Villa San Lorenzo" },
      { value: "Yacuy", label: "Yacuy" },
    ],
  },
  {
    id: 70,
    value: "San Juan",
    label: "San Juan",
    ciudades: [
      { value: "Alto De Sierra", label: "Alto De Sierra" },
      { value: "Alto De Sierra", label: "Alto De Sierra" },
      { value: "Angualasto", label: "Angualasto" },
      { value: "Astica", label: "Astica" },
      { value: "Balde Del Rosario", label: "Balde Del Rosario" },
      { value: "Barreal", label: "Barreal" },
      { value: "Barrio Ruta 40", label: "Barrio Ruta 40" },
      { value: "Barrio Sadop", label: "Barrio Sadop" },
      { value: "Bella Vista", label: "Bella Vista" },
      { value: "Bermejo", label: "Bermejo" },
      { value: "Calingasta", label: "Calingasta" },
      { value: "Campo Afuera", label: "Campo Afuera" },
      { value: "Cañada Honda", label: "Cañada Honda" },
      { value: "Carpinteria", label: "Carpinteria" },
      { value: "Caucete", label: "Caucete" },
      { value: "Chimbas", label: "Chimbas" },
      { value: "Chucuma", label: "Chucuma" },
      { value: "Cienaguita", label: "Cienaguita" },
      { value: "Colonia Fiorito", label: "Colonia Fiorito" },
      { value: "Colonia Fiscal", label: "Colonia Fiscal" },
      { value: "Colonia Gutierrez", label: "Colonia Gutierrez" },
      { value: "Colonia Gutierrez", label: "Colonia Gutierrez" },
      { value: "Divisadero", label: "Divisadero" },
      {
        value: "Dos Acequias (Est. Los Angacos)",
        label: "Dos Acequias (Est. Los Angacos)",
      },
      { value: "El Encon", label: "El Encon" },
      { value: "El Fiscal", label: "El Fiscal" },
      { value: "El Medanito", label: "El Medanito" },
      { value: "El Medano", label: "El Medano" },
      { value: "El Mogote", label: "El Mogote" },
      { value: "El Rincon", label: "El Rincon" },
      { value: "El Rincon", label: "El Rincon" },
      { value: "Gran China", label: "Gran China" },
      { value: "Guanacache", label: "Guanacache" },
      { value: "Huaco", label: "Huaco" },
      { value: "Iglesia", label: "Iglesia" },
      { value: "La Cañada", label: "La Cañada" },
      { value: "La Chimbera", label: "La Chimbera" },
      { value: "La Falda", label: "La Falda" },
      { value: "La Rinconada", label: "La Rinconada" },
      { value: "Las Chacritas", label: "Las Chacritas" },
      { value: "Las Flores", label: "Las Flores" },
      { value: "Las Lagunas", label: "Las Lagunas" },
      { value: "Las Talas", label: "Las Talas" },
      { value: "Las Tapias", label: "Las Tapias" },
      { value: "Los Baldecitos", label: "Los Baldecitos" },
      { value: "Los Berros", label: "Los Berros" },
      { value: "Los Medanos", label: "Los Medanos" },
      { value: "Marayes", label: "Marayes" },
      { value: "Mogna", label: "Mogna" },
      { value: "Niquivil", label: "Niquivil" },
      { value: "Pampa Vieja", label: "Pampa Vieja" },
      { value: "Pedernal", label: "Pedernal" },
      { value: "Pie De Palo", label: "Pie De Palo" },
      { value: "Pismanta", label: "Pismanta" },
      { value: "Punta Del Medano", label: "Punta Del Medano" },
      { value: "Quinto Cuartel", label: "Quinto Cuartel" },
      { value: "Rawson", label: "Rawson" },
      { value: "Rivadavia", label: "Rivadavia" },
      { value: "Rodeo", label: "Rodeo" },
      { value: "San Isidro", label: "San Isidro" },
      {
        value: "San Isidro (Est. Los Angacos)",
        label: "San Isidro (Est. Los Angacos)",
      },
      { value: "San Jose De Jachal", label: "San Jose De Jachal" },
      { value: "San Juan", label: "San Juan" },
      { value: "Santa Lucia", label: "Santa Lucia" },
      { value: "Tamberias", label: "Tamberias" },
      { value: "Tamberias", label: "Tamberias" },
      { value: "Tudcum", label: "Tudcum" },
      { value: "Tupeli", label: "Tupeli" },
      { value: "Usno", label: "Usno" },
      { value: "Vallecito", label: "Vallecito" },
      { value: "Villa Aberastain", label: "Villa Aberastain" },
      { value: "Villa Ampacama", label: "Villa Ampacama" },
      { value: "Villa Barboza", label: "Villa Barboza" },
      { value: "Villa Basilio Nievas", label: "Villa Basilio Nievas" },
      { value: "Villa Bolaños", label: "Villa Bolaños" },
      { value: "Villa Borjas", label: "Villa Borjas" },
      { value: "Villa Centenario", label: "Villa Centenario" },
      {
        value: "Villa Dominguito (Est. Puntilla Blanca)",
        label: "Villa Dominguito (Est. Puntilla Blanca)",
      },
      {
        value: "Villa Don Bosco (Est. Angaco Sud)",
        label: "Villa Don Bosco (Est. Angaco Sud)",
      },
      { value: "Villa El Salvador", label: "Villa El Salvador" },
      { value: "Villa El Salvador", label: "Villa El Salvador" },
      { value: "Villa El Tango", label: "Villa El Tango" },
      { value: "Villa General San Martin", label: "Villa General San Martin" },
      { value: "Villa Ibañez", label: "Villa Ibañez" },
      { value: "Villa Independencia", label: "Villa Independencia" },
      { value: "Villa Krause", label: "Villa Krause" },
      {
        value: "Villa Malvinas Argentinas",
        label: "Villa Malvinas Argentinas",
      },
      { value: "Villa Media Agua", label: "Villa Media Agua" },
      { value: "Villa Mercedes", label: "Villa Mercedes" },
      { value: "Villa Nacusi", label: "Villa Nacusi" },
      { value: "Villa Paula Albarracin", label: "Villa Paula Albarracin" },
      { value: "Villa Pituil", label: "Villa Pituil" },
      { value: "Villa San Agustin", label: "Villa San Agustin" },
      { value: "Villa San Martin", label: "Villa San Martin" },
      { value: "Villa Santa Rosa", label: "Villa Santa Rosa" },
      { value: "Villa Sefair Talacasto", label: "Villa Sefair Talacasto" },
      { value: "Villa Tacu", label: "Villa Tacu" },
      { value: "9 De Julio", label: "9 De Julio" },
    ],
  },
  {
    id: 74,
    value: "San Luis",
    label: "San Luis",
    ciudades: [
      { value: "Alto Pelado", label: "Alto Pelado" },
      { value: "Alto Pencoso", label: "Alto Pencoso" },
      { value: "Anchorena", label: "Anchorena" },
      { value: "Arizona", label: "Arizona" },
      { value: "Bagual", label: "Bagual" },
      { value: "Balde", label: "Balde" },
      { value: "Batavia", label: "Batavia" },
      { value: "Beazley", label: "Beazley" },
      { value: "Buena Esperanza", label: "Buena Esperanza" },
      { value: "Candelaria", label: "Candelaria" },
      { value: "Carolina", label: "Carolina" },
      { value: "Carpinteria", label: "Carpinteria" },
      { value: "Cazador", label: "Cazador" },
      { value: "Cerro Colorado", label: "Cerro Colorado" },
      { value: "Cerro De Oro", label: "Cerro De Oro" },
      { value: "Chosmes", label: "Chosmes" },
      { value: "Concaran", label: "Concaran" },
      { value: "Cortaderas", label: "Cortaderas" },
      { value: "Cruz De Piedra", label: "Cruz De Piedra" },
      { value: "Desaguadero", label: "Desaguadero" },
      { value: "El Chorrillo", label: "El Chorrillo" },
      { value: "El Trapiche", label: "El Trapiche" },
      { value: "El Volcan", label: "El Volcan" },
      { value: "Fortin El Patria", label: "Fortin El Patria" },
      { value: "Fortuna", label: "Fortuna" },
      { value: "Fraga", label: "Fraga" },
      { value: "Jarilla", label: "Jarilla" },
      { value: "Juan Jorba", label: "Juan Jorba" },
      { value: "Juan Llerena", label: "Juan Llerena" },
      { value: "Juana Koslay", label: "Juana Koslay" },
      { value: "Justo Daract", label: "Justo Daract" },
      { value: "La Bajada", label: "La Bajada" },
      { value: "La Calera", label: "La Calera" },
      { value: "La Florida", label: "La Florida" },
      { value: "La Majada", label: "La Majada" },
      { value: "La Maroma", label: "La Maroma" },
      { value: "La Punilla", label: "La Punilla" },
      { value: "La Punta", label: "La Punta" },
      { value: "La Toma", label: "La Toma" },
      { value: "La Vertiente", label: "La Vertiente" },
      { value: "Lafinur", label: "Lafinur" },
      { value: "Las Aguadas", label: "Las Aguadas" },
      { value: "Las Chacras", label: "Las Chacras" },
      { value: "Las Chacras", label: "Las Chacras" },
      { value: "Las Lagunas", label: "Las Lagunas" },
      { value: "Lavaisse", label: "Lavaisse" },
      { value: "Leandro N. Alem", label: "Leandro N. Alem" },
      { value: "Los Cajones", label: "Los Cajones" },
      { value: "Los Molles", label: "Los Molles" },
      { value: "Los Overos", label: "Los Overos" },
      { value: "Lujan", label: "Lujan" },
      { value: "Martin De Loyola", label: "Martin De Loyola" },
      { value: "Merlo", label: "Merlo" },
      { value: "Mosmota", label: "Mosmota" },
      { value: "Nahuel Mapa", label: "Nahuel Mapa" },
      { value: "Naschel", label: "Naschel" },
      { value: "Navia", label: "Navia" },
      { value: "Nogoli", label: "Nogoli" },
      { value: "Nueva Galia", label: "Nueva Galia" },
      { value: "Papagayos", label: "Papagayos" },
      { value: "Paso Grande", label: "Paso Grande" },
      { value: "Potrerillo", label: "Potrerillo" },
      { value: "Potrero De Los Funes", label: "Potrero De Los Funes" },
      { value: "Quines", label: "Quines" },
      { value: "Renca", label: "Renca" },
      { value: "Rio Grande", label: "Rio Grande" },
      { value: "Rio Juan Gomez", label: "Rio Juan Gomez" },
      { value: "Riocito", label: "Riocito" },
      { value: "Saladillo", label: "Saladillo" },
      { value: "Salinas Del Bebedero", label: "Salinas Del Bebedero" },
      {
        value: "San Francisco Del Monte De Oro",
        label: "San Francisco Del Monte De Oro",
      },
      { value: "San Jeronimo", label: "San Jeronimo" },
      { value: "San Jose Del Morro", label: "San Jose Del Morro" },
      { value: "San Luis", label: "San Luis" },
      { value: "San Martin", label: "San Martin" },
      { value: "San Pablo", label: "San Pablo" },
      { value: "San Roque", label: "San Roque" },
      { value: "Santa Rosa Del Conlara", label: "Santa Rosa Del Conlara" },
      { value: "Talita", label: "Talita" },
      { value: "Tilisarao", label: "Tilisarao" },
      { value: "Union", label: "Union" },
      { value: "Villa De La Quebrada", label: "Villa De La Quebrada" },
      { value: "Villa De Praga", label: "Villa De Praga" },
      { value: "Villa Del Carmen", label: "Villa Del Carmen" },
      { value: "Villa General Roca", label: "Villa General Roca" },
      { value: "Villa Larca", label: "Villa Larca" },
      { value: "Villa Mercedes", label: "Villa Mercedes" },
      { value: "Villa Reynolds", label: "Villa Reynolds" },
      { value: "Villa Salles", label: "Villa Salles" },
      { value: "Zanjitas", label: "Zanjitas" },
    ],
  },
  {
    id: 78,
    value: "Santa Cruz",
    label: "Santa Cruz",
    ciudades: [
      { value: "Bajo Caracoles", label: "Bajo Caracoles" },
      { value: "Caleta Olivia", label: "Caleta Olivia" },
      { value: "Cañadon Seco", label: "Cañadon Seco" },
      {
        value: "Comandante Luis Piedrabuena",
        label: "Comandante Luis Piedrabuena",
      },
      { value: "El Calafate", label: "El Calafate" },
      { value: "El Chalten", label: "El Chalten" },
      {
        value: "El Turbio (Est. Gobernador Mayer)",
        label: "El Turbio (Est. Gobernador Mayer)",
      },
      { value: "Fitz Roy", label: "Fitz Roy" },
      { value: "Gobernador Gregores", label: "Gobernador Gregores" },
      { value: "Hipolito Yrigoyen", label: "Hipolito Yrigoyen" },
      { value: "Jaramillo", label: "Jaramillo" },
      { value: "Julia Dufour", label: "Julia Dufour" },
      { value: "Koluel Kaike", label: "Koluel Kaike" },
      { value: "Las Heras", label: "Las Heras" },
      { value: "Los Antiguos", label: "Los Antiguos" },
      { value: "Mina 3", label: "Mina 3" },
      { value: "Perito Moreno", label: "Perito Moreno" },
      { value: "Pico Truncado", label: "Pico Truncado" },
      { value: "Puerto Deseado", label: "Puerto Deseado" },
      { value: "Puerto San Julian", label: "Puerto San Julian" },
      { value: "Puerto Santa Cruz", label: "Puerto Santa Cruz" },
      { value: "Rio Gallegos", label: "Rio Gallegos" },
      { value: "Rospentek", label: "Rospentek" },
      { value: "Tellier", label: "Tellier" },
      { value: "Tres Lagos", label: "Tres Lagos" },
      { value: "Yacimientos Rio Turbio", label: "Yacimientos Rio Turbio" },
      { value: "28 De Noviembre", label: "28 De Noviembre" },
    ],
  },
  {
    id: 82,
    value: "Santa Fe",
    label: "Santa Fe",
    ciudades: [
      {
        value: "Aaron Castellanos (Est. Castellanos)",
        label: "Aaron Castellanos (Est. Castellanos)",
      },
      { value: "Acebal", label: "Acebal" },
      { value: "Aguara Grande", label: "Aguara Grande" },
      { value: "Albarellos", label: "Albarellos" },
      { value: "Alcorta", label: "Alcorta" },
      { value: "Aldao", label: "Aldao" },
      { value: "Aldao (Est. Casablanca)", label: "Aldao (Est. Casablanca)" },
      { value: "Alejandra", label: "Alejandra" },
      { value: "Álvarez", label: "Álvarez" },
      { value: "Alvear", label: "Alvear" },
      { value: "Ambrosetti", label: "Ambrosetti" },
      { value: "Amenabar", label: "Amenabar" },
      { value: "Angel Gallardo", label: "Angel Gallardo" },
      { value: "Angelica", label: "Angelica" },
      { value: "Angeloni", label: "Angeloni" },
      { value: "Arbilla", label: "Arbilla" },
      { value: "Arequito", label: "Arequito" },
      { value: "Arminda", label: "Arminda" },
      { value: "Armstrong", label: "Armstrong" },
      { value: "Arocena", label: "Arocena" },
      { value: "Arroyo Aguiar", label: "Arroyo Aguiar" },
      { value: "Arroyo Ceibal", label: "Arroyo Ceibal" },
      { value: "Arroyo Leyes", label: "Arroyo Leyes" },
      { value: "Arroyo Seco", label: "Arroyo Seco" },
      { value: "Arrufo", label: "Arrufo" },
      { value: "Arteaga", label: "Arteaga" },
      { value: "Ataliva", label: "Ataliva" },
      { value: "Aurelia", label: "Aurelia" },
      { value: "Avellaneda (Est. Ewald)", label: "Avellaneda (Est. Ewald)" },
      { value: "Balneario La Verde", label: "Balneario La Verde" },
      { value: "Balneario Monje", label: "Balneario Monje" },
      { value: "Barrancas", label: "Barrancas" },
      { value: "Barrio Arroyo Del Medio", label: "Barrio Arroyo Del Medio" },
      { value: "Barrio Caima", label: "Barrio Caima" },
      { value: "Barrio Cicarelli", label: "Barrio Cicarelli" },
      {
        value: "Barrio El Pacaa - Barrio Comipini",
        label: "Barrio El Pacaa - Barrio Comipini",
      },
      { value: "Barrio Mitre", label: "Barrio Mitre" },
      {
        value: "Barrios Acapulco Y Veracruz",
        label: "Barrios Acapulco Y Veracruz",
      },
      { value: "Bauer Y Sigel", label: "Bauer Y Sigel" },
      { value: "Bella Italia", label: "Bella Italia" },
      { value: "Beravebu", label: "Beravebu" },
      { value: "Berna", label: "Berna" },
      {
        value: "Bernardo De Irigoyen (Est. Irigoyen)",
        label: "Bernardo De Irigoyen (Est. Irigoyen)",
      },
      { value: "Bigand", label: "Bigand" },
      { value: "Bombal", label: "Bombal" },
      { value: "Bouquet", label: "Bouquet" },
      { value: "Bustinza", label: "Bustinza" },
      { value: "Cabal", label: "Cabal" },
      { value: "Cacique Ariacaiquin", label: "Cacique Ariacaiquin" },
      { value: "Cafferata", label: "Cafferata" },
      { value: "Calchaqui", label: "Calchaqui" },
      { value: "Campo Andino", label: "Campo Andino" },
      { value: "Candioti", label: "Candioti" },
      { value: "Cañada De Gomez", label: "Cañada De Gomez" },
      { value: "Cañada Del Ucle", label: "Cañada Del Ucle" },
      { value: "Cañada Ombu", label: "Cañada Ombu" },
      { value: "Cañada Rica", label: "Cañada Rica" },
      { value: "Cañada Rosquin", label: "Cañada Rosquin" },
      { value: "Capitan Bermudez", label: "Capitan Bermudez" },
      { value: "Capivara", label: "Capivara" },
      { value: "Carcaraña", label: "Carcaraña" },
      { value: "Carlos Pellegrini", label: "Carlos Pellegrini" },
      { value: "Carmen", label: "Carmen" },
      { value: "Carmen Del Sauce", label: "Carmen Del Sauce" },
      { value: "Carreras", label: "Carreras" },
      { value: "Carrizales (Est. Clarke)", label: "Carrizales (Est. Clarke)" },
      { value: "Casalegno", label: "Casalegno" },
      { value: "Casas", label: "Casas" },
      { value: "Casilda", label: "Casilda" },
      { value: "Castelar", label: "Castelar" },
      { value: "Castellanos", label: "Castellanos" },
      { value: "Cavour", label: "Cavour" },
      { value: "Cayasta", label: "Cayasta" },
      { value: "Cayastacito", label: "Cayastacito" },
      { value: "Centeno", label: "Centeno" },
      { value: "Cepeda", label: "Cepeda" },
      { value: "Ceres", label: "Ceres" },
      { value: "Chabas", label: "Chabas" },
      { value: "Chañar Ladeado", label: "Chañar Ladeado" },
      { value: "Chapuy", label: "Chapuy" },
      { value: "Chovet", label: "Chovet" },
      { value: "Christophersen", label: "Christophersen" },
      { value: "Classon", label: "Classon" },
      { value: "Colmena", label: "Colmena" },
      { value: "Colonia Ana", label: "Colonia Ana" },
      { value: "Colonia Belgrano", label: "Colonia Belgrano" },
      { value: "Colonia Bicha", label: "Colonia Bicha" },
      { value: "Colonia Bossi", label: "Colonia Bossi" },
      { value: "Colonia Cello", label: "Colonia Cello" },
      { value: "Colonia Dolores", label: "Colonia Dolores" },
      { value: "Colonia Duran", label: "Colonia Duran" },
      { value: "Colonia Margarita", label: "Colonia Margarita" },
      { value: "Colonia Medici", label: "Colonia Medici" },
      { value: "Colonia Raquel", label: "Colonia Raquel" },
      { value: "Colonia Rosa", label: "Colonia Rosa" },
      { value: "Constanza", label: "Constanza" },
      { value: "Coronda", label: "Coronda" },
      { value: "Coronel Arnold", label: "Coronel Arnold" },
      { value: "Coronel Bogado", label: "Coronel Bogado" },
      { value: "Coronel Fraga", label: "Coronel Fraga" },
      {
        value: "Coronel Rodolfo S. Dominguez",
        label: "Coronel Rodolfo S. Dominguez",
      },
      { value: "Correa", label: "Correa" },
      { value: "Crispi", label: "Crispi" },
      { value: "Cuatro Esquinas", label: "Cuatro Esquinas" },
      { value: "Cululu", label: "Cululu" },
      { value: "Curupayti", label: "Curupayti" },
      { value: "Desvio Arijon", label: "Desvio Arijon" },
      { value: "Diaz", label: "Diaz" },
      { value: "Diego De Alvear", label: "Diego De Alvear" },
      { value: "Egusquiza", label: "Egusquiza" },
      { value: "El Araza", label: "El Araza" },
      { value: "El Caramelo", label: "El Caramelo" },
      { value: "El Rabon", label: "El Rabon" },
      { value: "El Trebol", label: "El Trebol" },
      { value: "Elisa", label: "Elisa" },
      { value: "Elortondo", label: "Elortondo" },
      { value: "Emilia", label: "Emilia" },
      { value: "Empalme San Carlos", label: "Empalme San Carlos" },
      {
        value: "Empalme Villa Constitucion",
        label: "Empalme Villa Constitucion",
      },
      { value: "Esmeralda", label: "Esmeralda" },
      { value: "Esperanza", label: "Esperanza" },
      { value: "Estacion Clucellas", label: "Estacion Clucellas" },
      { value: "Estacion Presidente Roca", label: "Estacion Presidente Roca" },
      { value: "Estacion Saguier", label: "Estacion Saguier" },
      { value: "Esteban Rams", label: "Esteban Rams" },
      { value: "Esther", label: "Esther" },
      { value: "Eusebia Y Carolina", label: "Eusebia Y Carolina" },
      { value: "Eustolia", label: "Eustolia" },
      { value: "Felicia", label: "Felicia" },
      { value: "Fighiera", label: "Fighiera" },
      { value: "Firmat", label: "Firmat" },
      { value: "Firmat", label: "Firmat" },
      { value: "Florencia", label: "Florencia" },
      { value: "Fortin Olmos", label: "Fortin Olmos" },
      { value: "Franck", label: "Franck" },
      { value: "Fray Luis Beltran", label: "Fray Luis Beltran" },
      { value: "Frontera", label: "Frontera" },
      { value: "Fuentes", label: "Fuentes" },
      { value: "Funes", label: "Funes" },
      { value: "Gaboto", label: "Gaboto" },
      { value: "Galvez", label: "Galvez" },
      { value: "Garabato", label: "Garabato" },
      { value: "Garibaldi", label: "Garibaldi" },
      { value: "Gato Colorado", label: "Gato Colorado" },
      { value: "General Gelly", label: "General Gelly" },
      { value: "General Lagos", label: "General Lagos" },
      { value: "Gessler", label: "Gessler" },
      { value: "Gobernador Crespo", label: "Gobernador Crespo" },
      { value: "GÖDEKEN", label: "GÖDEKEN" },
      { value: "Godoy", label: "Godoy" },
      { value: "Golondrina", label: "Golondrina" },
      { value: "Granadero Baigorria", label: "Granadero Baigorria" },
      {
        value: "Gregoria Perez De Denis (Est. El Nochero)",
        label: "Gregoria Perez De Denis (Est. El Nochero)",
      },
      { value: "Grutly", label: "Grutly" },
      { value: "Guadalupe Norte", label: "Guadalupe Norte" },
      { value: "Helvecia", label: "Helvecia" },
      { value: "Hersilia", label: "Hersilia" },
      { value: "Hipatia", label: "Hipatia" },
      { value: "Huanqueros", label: "Huanqueros" },
      { value: "Hughes", label: "Hughes" },
      { value: "Humberto Primo", label: "Humberto Primo" },
      { value: "Humboldt", label: "Humboldt" },
      { value: "Ibarlucea", label: "Ibarlucea" },
      { value: "Ingeniero Chanourdie", label: "Ingeniero Chanourdie" },
      { value: "Intiyaco", label: "Intiyaco" },
      { value: "Irigoyen", label: "Irigoyen" },
      { value: "Jacinto L. Arauz", label: "Jacinto L. Arauz" },
      { value: "Josefina", label: "Josefina" },
      { value: "Juan B. Molina", label: "Juan B. Molina" },
      { value: "Juncal", label: "Juncal" },
      { value: "Kilometro 101", label: "Kilometro 101" },
      { value: "Kilometro 115", label: "Kilometro 115" },
      { value: "La Brava", label: "La Brava" },
      { value: "La Cabral", label: "La Cabral" },
      { value: "La Chispa", label: "La Chispa" },
      {
        value: "La Criolla (Est. Cañadita)",
        label: "La Criolla (Est. Cañadita)",
      },
      { value: "La Gallareta", label: "La Gallareta" },
      { value: "La Isleta", label: "La Isleta" },
      { value: "La Pelada", label: "La Pelada" },
      { value: "La Penca Y Caraguata", label: "La Penca Y Caraguata" },
      { value: "La Rubia", label: "La Rubia" },
      { value: "La Sarita", label: "La Sarita" },
      { value: "La Vanguardia", label: "La Vanguardia" },
      { value: "Labordeboy", label: "Labordeboy" },
      { value: "Laguna Paiva", label: "Laguna Paiva" },
      { value: "Landeta", label: "Landeta" },
      { value: "Lanteri", label: "Lanteri" },
      { value: "Larguia", label: "Larguia" },
      { value: "Larrechea", label: "Larrechea" },
      { value: "Las Avispas", label: "Las Avispas" },
      { value: "Las Bandurrias", label: "Las Bandurrias" },
      { value: "Las Garzas", label: "Las Garzas" },
      { value: "Las Palmeras", label: "Las Palmeras" },
      { value: "Las Parejas", label: "Las Parejas" },
      { value: "Las Petacas", label: "Las Petacas" },
      { value: "Las Rosas", label: "Las Rosas" },
      { value: "Las Toscas", label: "Las Toscas" },
      { value: "Las Tunas", label: "Las Tunas" },
      { value: "Lazzarino", label: "Lazzarino" },
      { value: "Lehmann", label: "Lehmann" },
      { value: "Llambi Campbell", label: "Llambi Campbell" },
      { value: "Logroño", label: "Logroño" },
      { value: "Loma Alta", label: "Loma Alta" },
      {
        value: "Lopez (Est. San Martin De Tours)",
        label: "Lopez (Est. San Martin De Tours)",
      },
      { value: "Los Amores", label: "Los Amores" },
      { value: "Los Cardos", label: "Los Cardos" },
      { value: "Los Laureles", label: "Los Laureles" },
      { value: "Los Molinos", label: "Los Molinos" },
      {
        value: "Los Muchachos - La Alborada",
        label: "Los Muchachos - La Alborada",
      },
      { value: "Los Nogales", label: "Los Nogales" },
      { value: "Los Quirquinchos", label: "Los Quirquinchos" },
      { value: "Los Zapallos", label: "Los Zapallos" },
      { value: "Lucio V. Lopez", label: "Lucio V. Lopez" },
      {
        value: "Luis Palacios (Est. La Salada)",
        label: "Luis Palacios (Est. La Salada)",
      },
      { value: "Maciel", label: "Maciel" },
      { value: "Maggiolo", label: "Maggiolo" },
      { value: "Malabrigo", label: "Malabrigo" },
      { value: "Marcelino Escalada", label: "Marcelino Escalada" },
      { value: "Margarita", label: "Margarita" },
      { value: "Maria Juana", label: "Maria Juana" },
      { value: "Maria Luisa", label: "Maria Luisa" },
      { value: "Maria Susana", label: "Maria Susana" },
      { value: "Maria Teresa", label: "Maria Teresa" },
      { value: "Matilde", label: "Matilde" },
      { value: "Maximo Paz (Est. Paz)", label: "Maximo Paz (Est. Paz)" },
      {
        value: "Melincue (Est. San Urbano)",
        label: "Melincue (Est. San Urbano)",
      },
      { value: "Miguel Torres", label: "Miguel Torres" },
      { value: "Moises Ville", label: "Moises Ville" },
      { value: "Monigotes", label: "Monigotes" },
      { value: "Monje", label: "Monje" },
      { value: "Monte Flores", label: "Monte Flores" },
      { value: "Monte Vera", label: "Monte Vera" },
      { value: "Montefiore", label: "Montefiore" },
      { value: "Montes De Oca", label: "Montes De Oca" },
      { value: "Murphy", label: "Murphy" },
      { value: "Nare", label: "Nare" },
      { value: "Nelson", label: "Nelson" },
      { value: "Nueva Lehmann", label: "Nueva Lehmann" },
      { value: "Nuevo Torino", label: "Nuevo Torino" },
      { value: "ñANDUCITA", label: "ñANDUCITA" },
      { value: "Oliveros", label: "Oliveros" },
      { value: "Palacios", label: "Palacios" },
      { value: "Paraje Chaco Chico", label: "Paraje Chaco Chico" },
      { value: "Paraje La Costa", label: "Paraje La Costa" },
      { value: "Paraje San Manuel", label: "Paraje San Manuel" },
      { value: "Paraje 29", label: "Paraje 29" },
      { value: "Pavon", label: "Pavon" },
      { value: "Pavon Arriba", label: "Pavon Arriba" },
      { value: "Pedro Gomez Cello", label: "Pedro Gomez Cello" },
      { value: "Perez", label: "Perez" },
      { value: "Peyrano", label: "Peyrano" },
      { value: "Piamonte", label: "Piamonte" },
      { value: "Pilar", label: "Pilar" },
      { value: "Piñero (Est. Erasto)", label: "Piñero (Est. Erasto)" },
      { value: "Plaza Clucellas", label: "Plaza Clucellas" },
      { value: "Plaza Matilde", label: "Plaza Matilde" },
      { value: "Plaza Saguier", label: "Plaza Saguier" },
      { value: "Pozo Borrado", label: "Pozo Borrado" },
      { value: "Pozo De Los Indios", label: "Pozo De Los Indios" },
      { value: "Presidente Roca", label: "Presidente Roca" },
      { value: "Progreso", label: "Progreso" },
      { value: "Providencia", label: "Providencia" },
      { value: "Pueblo Andino", label: "Pueblo Andino" },
      { value: "Pueblo Esther", label: "Pueblo Esther" },
      { value: "Pueblo Marini", label: "Pueblo Marini" },
      {
        value: "Pueblo Muñoz (Est. Bernard)",
        label: "Pueblo Muñoz (Est. Bernard)",
      },
      { value: "Pueblo Santa Lucia", label: "Pueblo Santa Lucia" },
      { value: "Pueblo Uranga", label: "Pueblo Uranga" },
      { value: "Puerto Aragon", label: "Puerto Aragon" },
      { value: "Puerto Arroyo Seco", label: "Puerto Arroyo Seco" },
      {
        value: "Puerto General San Martin",
        label: "Puerto General San Martin",
      },
      { value: "Puerto Reconquista", label: "Puerto Reconquista" },
      { value: "Pujato", label: "Pujato" },
      { value: "Rafaela", label: "Rafaela" },
      { value: "Ramayon", label: "Ramayon" },
      { value: "Ramona", label: "Ramona" },
      { value: "Reconquista", label: "Reconquista" },
      { value: "Recreo", label: "Recreo" },
      { value: "Ricardone", label: "Ricardone" },
      { value: "Rincon Norte", label: "Rincon Norte" },
      { value: "Rincon Potrero", label: "Rincon Potrero" },
      { value: "Roldan", label: "Roldan" },
      { value: "Romang", label: "Romang" },
      { value: "Rosario", label: "Rosario" },
      { value: "Rueda", label: "Rueda" },
      { value: "Rufino", label: "Rufino" },
      { value: "Sa Pereyra", label: "Sa Pereyra" },
      { value: "Saladero Mariano Cabal", label: "Saladero Mariano Cabal" },
      { value: "Salto Grande", label: "Salto Grande" },
      { value: "San Agustin", label: "San Agustin" },
      { value: "San Antonio", label: "San Antonio" },
      { value: "San Antonio De Obligado", label: "San Antonio De Obligado" },
      { value: "San Bernardo", label: "San Bernardo" },
      { value: "San Carlos Centro", label: "San Carlos Centro" },
      { value: "San Carlos Norte", label: "San Carlos Norte" },
      { value: "San Carlos Sud", label: "San Carlos Sud" },
      { value: "San Cristobal", label: "San Cristobal" },
      { value: "San Eduardo", label: "San Eduardo" },
      { value: "San Eugenio", label: "San Eugenio" },
      { value: "San Fabian", label: "San Fabian" },
      {
        value: "San Francisco De Santa Fe",
        label: "San Francisco De Santa Fe",
      },
      { value: "San Genaro", label: "San Genaro" },
      { value: "San Genaro Norte", label: "San Genaro Norte" },
      { value: "San Gregorio", label: "San Gregorio" },
      { value: "San Guillermo", label: "San Guillermo" },
      { value: "San Javier", label: "San Javier" },
      { value: "San Jeronimo Del Sauce", label: "San Jeronimo Del Sauce" },
      { value: "San Jeronimo Norte", label: "San Jeronimo Norte" },
      { value: "San Jeronimo Sud", label: "San Jeronimo Sud" },
      { value: "San Jorge", label: "San Jorge" },
      { value: "San Jose De La Esquina", label: "San Jose De La Esquina" },
      { value: "San Jose Del Rincon", label: "San Jose Del Rincon" },
      { value: "San Justo", label: "San Justo" },
      { value: "San Lorenzo", label: "San Lorenzo" },
      { value: "San Mariano", label: "San Mariano" },
      {
        value: "San Martin De Las Escobas",
        label: "San Martin De Las Escobas",
      },
      { value: "San Martin Norte", label: "San Martin Norte" },
      { value: "San Vicente", label: "San Vicente" },
      { value: "Sancti Spiritu", label: "Sancti Spiritu" },
      { value: "Sanford", label: "Sanford" },
      {
        value: "Santa Clara De Buena Vista",
        label: "Santa Clara De Buena Vista",
      },
      { value: "Santa Clara De Saguier", label: "Santa Clara De Saguier" },
      { value: "Santa Fe", label: "Santa Fe" },
      { value: "Santa Isabel", label: "Santa Isabel" },
      { value: "Santa Margarita", label: "Santa Margarita" },
      { value: "Santa Rosa De Calchines", label: "Santa Rosa De Calchines" },
      { value: "Santa Teresa", label: "Santa Teresa" },
      { value: "Santo Domingo", label: "Santo Domingo" },
      { value: "Santo Tome", label: "Santo Tome" },
      { value: "Santurce", label: "Santurce" },
      { value: "Sargento Cabral", label: "Sargento Cabral" },
      { value: "Sarmiento", label: "Sarmiento" },
      { value: "Sastre", label: "Sastre" },
      { value: "Sauce Viejo", label: "Sauce Viejo" },
      { value: "Serodino", label: "Serodino" },
      { value: "Silva (Est. Abipones)", label: "Silva (Est. Abipones)" },
      { value: "Soldini", label: "Soldini" },
      { value: "Soledad", label: "Soledad" },
      { value: "Stephenson", label: "Stephenson" },
      { value: "Suardi", label: "Suardi" },
      { value: "Sunchales", label: "Sunchales" },
      { value: "Susana", label: "Susana" },
      {
        value: "Tacuarendi (Emb. Kilometro 421)",
        label: "Tacuarendi (Emb. Kilometro 421)",
      },
      { value: "Tacural", label: "Tacural" },
      {
        value: "Tartagal (Est. El Tajamar)",
        label: "Tartagal (Est. El Tajamar)",
      },
      { value: "Teodelina", label: "Teodelina" },
      { value: "Theobald", label: "Theobald" },
      { value: "Timbues", label: "Timbues" },
      { value: "Toba", label: "Toba" },
      { value: "Tortugas", label: "Tortugas" },
      { value: "Tostado", label: "Tostado" },
      { value: "Totoras", label: "Totoras" },
      { value: "Traill", label: "Traill" },
      { value: "Venado Tuerto", label: "Venado Tuerto" },
      {
        value: "Vera (Est. Gobernador Vera)",
        label: "Vera (Est. Gobernador Vera)",
      },
      {
        value: "Vera Y Pintado (Est. Guaranies)",
        label: "Vera Y Pintado (Est. Guaranies)",
      },
      { value: "Videla", label: "Videla" },
      { value: "Vila", label: "Vila" },
      { value: "Villa Adelina", label: "Villa Adelina" },
      { value: "Villa Amelia", label: "Villa Amelia" },
      { value: "Villa Ana", label: "Villa Ana" },
      { value: "Villa Cañas", label: "Villa Cañas" },
      { value: "Villa Constitucion", label: "Villa Constitucion" },
      { value: "Villa Del Plata", label: "Villa Del Plata" },
      { value: "Villa Eloisa", label: "Villa Eloisa" },
      { value: "Villa Elvira", label: "Villa Elvira" },
      { value: "Villa Gobernador Galvez", label: "Villa Gobernador Galvez" },
      { value: "Villa Guillermina", label: "Villa Guillermina" },
      { value: "Villa Josefina", label: "Villa Josefina" },
      {
        value: "Villa La Rivera (Comuna Oliveros)",
        label: "Villa La Rivera (Comuna Oliveros)",
      },
      {
        value: "Villa La Rivera (Comuna Pueblo Andino)",
        label: "Villa La Rivera (Comuna Pueblo Andino)",
      },
      {
        value: "Villa Laura (Est. Constituyentes)",
        label: "Villa Laura (Est. Constituyentes)",
      },
      { value: "Villa Minetti", label: "Villa Minetti" },
      { value: "Villa Mugueta", label: "Villa Mugueta" },
      { value: "Villa Ocampo", label: "Villa Ocampo" },
      { value: "Villa San Jose", label: "Villa San Jose" },
      { value: "Villa Saralegui", label: "Villa Saralegui" },
      { value: "Villa Trinidad", label: "Villa Trinidad" },
      { value: "Villada", label: "Villada" },
      { value: "Virginia", label: "Virginia" },
      { value: "Wheelwright", label: "Wheelwright" },
      {
        value: "Wildermuth (Est. Granadero B. Bustos)",
        label: "Wildermuth (Est. Granadero B. Bustos)",
      },
      { value: "Zavalla", label: "Zavalla" },
      { value: "Zenon Pereyra", label: "Zenon Pereyra" },
    ],
  },
  {
    id: 86,
    value: "Santiago Del Estero",
    label: "Santiago Del Estero",
    ciudades: [
      { value: "Abra Grande", label: "Abra Grande" },
      { value: "Aerolito", label: "Aerolito" },
      { value: "Alhuampa", label: "Alhuampa" },
      { value: "Ancajan", label: "Ancajan" },
      { value: "Antaje", label: "Antaje" },
      { value: "Añatuya", label: "Añatuya" },
      { value: "Ardiles", label: "Ardiles" },
      { value: "Argentina", label: "Argentina" },
      { value: "Árraga", label: "Árraga" },
      { value: "Averias", label: "Averias" },
      { value: "Bandera", label: "Bandera" },
      { value: "Bandera Bajada", label: "Bandera Bajada" },
      { value: "Beltran", label: "Beltran" },
      { value: "Brea Pozo", label: "Brea Pozo" },
      { value: "Campo Gallo", label: "Campo Gallo" },
      { value: "Cañada Escobar", label: "Cañada Escobar" },
      { value: "Casares", label: "Casares" },
      { value: "Caspi Corral", label: "Caspi Corral" },
      { value: "Chañar Pozo De Abajo", label: "Chañar Pozo De Abajo" },
      { value: "Chauchillas", label: "Chauchillas" },
      { value: "Chaupi Pozo", label: "Chaupi Pozo" },
      { value: "Chilca Juliana", label: "Chilca Juliana" },
      { value: "Choya", label: "Choya" },
      { value: "Clodomira", label: "Clodomira" },
      { value: "Colonia Alpina", label: "Colonia Alpina" },
      { value: "Colonia Dora", label: "Colonia Dora" },
      { value: "Colonia El Simbolar", label: "Colonia El Simbolar" },
      { value: "Colonia San Juan", label: "Colonia San Juan" },
      { value: "Colonia Tinco", label: "Colonia Tinco" },
      { value: "Coronel Manuel L. Rico", label: "Coronel Manuel L. Rico" },
      { value: "Cuatro Bocas", label: "Cuatro Bocas" },
      { value: "Donadeu", label: "Donadeu" },
      { value: "El Bobadal", label: "El Bobadal" },
      { value: "El Cabure", label: "El Cabure" },
      { value: "El Charco", label: "El Charco" },
      { value: "El Charco", label: "El Charco" },
      { value: "El Colorado", label: "El Colorado" },
      { value: "El Crucero", label: "El Crucero" },
      { value: "El Cuadrado", label: "El Cuadrado" },
      { value: "El Dean", label: "El Dean" },
      { value: "El Mojon", label: "El Mojon" },
      { value: "El Mojon", label: "El Mojon" },
      { value: "El Rincon", label: "El Rincon" },
      { value: "El Zanjon", label: "El Zanjon" },
      { value: "El 49", label: "El 49" },
      { value: "Estacion Atamisqui", label: "Estacion Atamisqui" },
      { value: "Estacion La Punta", label: "Estacion La Punta" },
      { value: "Estacion Robles", label: "Estacion Robles" },
      { value: "Estacion Taboada", label: "Estacion Taboada" },
      { value: "Estacion Tacañitas", label: "Estacion Tacañitas" },
      { value: "Fernandez", label: "Fernandez" },
      { value: "Fortin Inca", label: "Fortin Inca" },
      { value: "Frias", label: "Frias" },
      { value: "Garza", label: "Garza" },
      { value: "Gramilla", label: "Gramilla" },
      { value: "Gramilla", label: "Gramilla" },
      { value: "Guardia Escolta", label: "Guardia Escolta" },
      { value: "Hasse", label: "Hasse" },
      { value: "Hernan Mejia Miraval", label: "Hernan Mejia Miraval" },
      { value: "Herrera", label: "Herrera" },
      { value: "Huyamampa", label: "Huyamampa" },
      { value: "Icaño", label: "Icaño" },
      {
        value: "Ingeniero Forres (Est. Chaguar Punco)",
        label: "Ingeniero Forres (Est. Chaguar Punco)",
      },
      { value: "Isca Yacu", label: "Isca Yacu" },
      { value: "Isca Yacu Semaul", label: "Isca Yacu Semaul" },
      { value: "Kilometro 30", label: "Kilometro 30" },
      { value: "La Aurora", label: "La Aurora" },
      { value: "La Banda", label: "La Banda" },
      { value: "La Cañada", label: "La Cañada" },
      { value: "La Darsena", label: "La Darsena" },
      { value: "La Firmeza", label: "La Firmeza" },
      { value: "La Invernada", label: "La Invernada" },
      { value: "La Nena", label: "La Nena" },
      { value: "La Nueva Donosa", label: "La Nueva Donosa" },
      { value: "Laprida", label: "Laprida" },
      { value: "Las Delicias", label: "Las Delicias" },
      { value: "Las Palmitas", label: "Las Palmitas" },
      { value: "Las Tinajas", label: "Las Tinajas" },
      { value: "Lavalle", label: "Lavalle" },
      { value: "Libertad", label: "Libertad" },
      { value: "Lilo Viejo", label: "Lilo Viejo" },
      { value: "Los Cardozos", label: "Los Cardozos" },
      { value: "Los Juries", label: "Los Juries" },
      { value: "Los Miranda", label: "Los Miranda" },
      { value: "Los Nuñez", label: "Los Nuñez" },
      { value: "Los Pirpintos", label: "Los Pirpintos" },
      { value: "Los Quiroga", label: "Los Quiroga" },
      { value: "Los Soria", label: "Los Soria" },
      { value: "Los Telares", label: "Los Telares" },
      { value: "Los Tigres", label: "Los Tigres" },
      { value: "Lugones", label: "Lugones" },
      { value: "Maco", label: "Maco" },
      { value: "Malbran", label: "Malbran" },
      { value: "Mansupa", label: "Mansupa" },
      { value: "Maquito", label: "Maquito" },
      { value: "Matara", label: "Matara" },
      { value: "Medellin", label: "Medellin" },
      { value: "Minerva", label: "Minerva" },
      { value: "Monte Quemado", label: "Monte Quemado" },
      { value: "Morales", label: "Morales" },
      { value: "Nueva Esperanza", label: "Nueva Esperanza" },
      { value: "Nueva Esperanza", label: "Nueva Esperanza" },
      { value: "Nueva Francia", label: "Nueva Francia" },
      { value: "Palo Negro", label: "Palo Negro" },
      { value: "Pampa De Los Guanacos", label: "Pampa De Los Guanacos" },
      { value: "Patay", label: "Patay" },
      { value: "Pozo Betbeder", label: "Pozo Betbeder" },
      { value: "Pozo Hondo", label: "Pozo Hondo" },
      { value: "Pozuelos", label: "Pozuelos" },
      {
        value: "Pueblo Pablo Torelo (Est. Otumpa)",
        label: "Pueblo Pablo Torelo (Est. Otumpa)",
      },
      { value: "Puesto De San Antonio", label: "Puesto De San Antonio" },
      { value: "Quimili", label: "Quimili" },
      { value: "Ramirez De Velazco", label: "Ramirez De Velazco" },
      { value: "Rapelli", label: "Rapelli" },
      { value: "Real Sayana", label: "Real Sayana" },
      { value: "Rodeo De Valdez", label: "Rodeo De Valdez" },
      { value: "Roversi", label: "Roversi" },
      { value: "Sachayoj", label: "Sachayoj" },
      { value: "San Jose Del Boqueron", label: "San Jose Del Boqueron" },
      { value: "San Pedro", label: "San Pedro" },
      { value: "San Pedro", label: "San Pedro" },
      { value: "San Pedro", label: "San Pedro" },
      { value: "San Pedro", label: "San Pedro" },
      { value: "Santa Maria", label: "Santa Maria" },
      { value: "Santiago Del Estero", label: "Santiago Del Estero" },
      { value: "Santo Domingo", label: "Santo Domingo" },
      { value: "Santos Lugares", label: "Santos Lugares" },
      { value: "Sauzal", label: "Sauzal" },
      { value: "Selva", label: "Selva" },
      { value: "Simbol", label: "Simbol" },
      { value: "Simbolar", label: "Simbolar" },
      { value: "Sol De Julio", label: "Sol De Julio" },
      { value: "Sumamao", label: "Sumamao" },
      { value: "Sumampa", label: "Sumampa" },
      { value: "Sumampa Viejo", label: "Sumampa Viejo" },
      { value: "Suncho Corral", label: "Suncho Corral" },
      { value: "Tapso", label: "Tapso" },
      { value: "Termas De Rio Hondo", label: "Termas De Rio Hondo" },
      { value: "Tintina", label: "Tintina" },
      { value: "Tomas Young", label: "Tomas Young" },
      { value: "Tramo 16", label: "Tramo 16" },
      { value: "Tramo 20", label: "Tramo 20" },
      { value: "Urutau", label: "Urutau" },
      { value: "Vaca Huañuna", label: "Vaca Huañuna" },
      { value: "Vilelas", label: "Vilelas" },
      { value: "Villa Atamisqui", label: "Villa Atamisqui" },
      { value: "Villa Figueroa", label: "Villa Figueroa" },
      {
        value: "Villa General Mitre (Est. Pinto)",
        label: "Villa General Mitre (Est. Pinto)",
      },
      { value: "Villa Gimenez", label: "Villa Gimenez" },
      { value: "Villa La Punta", label: "Villa La Punta" },
      { value: "Villa Mailin", label: "Villa Mailin" },
      { value: "Villa Nueva", label: "Villa Nueva" },
      { value: "Villa Ojo De Agua", label: "Villa Ojo De Agua" },
      { value: "Villa Rio Hondo", label: "Villa Rio Hondo" },
      { value: "Villa Salavina", label: "Villa Salavina" },
      {
        value: "Villa San Martin (Est. Loreto)",
        label: "Villa San Martin (Est. Loreto)",
      },
      { value: "Villa Silipica", label: "Villa Silipica" },
      {
        value: "Villa Turistica Del Embalse",
        label: "Villa Turistica Del Embalse",
      },
      { value: "Villa Union", label: "Villa Union" },
      { value: "Vilmer", label: "Vilmer" },
      { value: "Vinara", label: "Vinara" },
      { value: "Vuelta De La Barranca", label: "Vuelta De La Barranca" },
      { value: "Weisburd", label: "Weisburd" },
      { value: "Yanda", label: "Yanda" },
      { value: "Yuchan", label: "Yuchan" },
    ],
  },
  {
    id: 94,
    value: "Tierra Del Fuego",
    label: "Tierra Del Fuego",
    ciudades: [
      { value: "Laguna Escondida", label: "Laguna Escondida" },
      { value: "Puerto Argentino", label: "Puerto Argentino" },
      { value: "Rio Grande", label: "Rio Grande" },
      { value: "Tolhuin", label: "Tolhuin" },
      { value: "Ushuaia", label: "Ushuaia" },
    ],
  },
  {
    id: 90,
    value: "Tucuman",
    label: "Tucuman",
    ciudades: [
      { value: "Acheral", label: "Acheral" },
      { value: "Aguilares", label: "Aguilares" },
      { value: "Alderetes", label: "Alderetes" },
      { value: "Alpachiri", label: "Alpachiri" },
      { value: "Alto Verde", label: "Alto Verde" },
      { value: "Amaicha Del Valle", label: "Amaicha Del Valle" },
      { value: "Arcadia", label: "Arcadia" },
      { value: "Atahona", label: "Atahona" },
      { value: "Banda Del Rio Sali", label: "Banda Del Rio Sali" },
      { value: "Barrio Aeropuerto", label: "Barrio Aeropuerto" },
      { value: "Barrio Araujo", label: "Barrio Araujo" },
      { value: "Barrio Casa Rosada", label: "Barrio Casa Rosada" },
      { value: "Barrio El Cruce", label: "Barrio El Cruce" },
      { value: "Barrio Lomas De Tafi", label: "Barrio Lomas De Tafi" },
      { value: "Barrio Mutual San Martin", label: "Barrio Mutual San Martin" },
      { value: "Barrio Parada 14", label: "Barrio Parada 14" },
      { value: "Barrio San Felipe", label: "Barrio San Felipe" },
      { value: "Barrio San Jorge", label: "Barrio San Jorge" },
      { value: "Barrio San Jose Iii", label: "Barrio San Jose Iii" },
      { value: "Barrio San Roque", label: "Barrio San Roque" },
      { value: "Barrio U.T.A. Ii", label: "Barrio U.T.A. Ii" },
      { value: "Bella Vista", label: "Bella Vista" },
      { value: "Campo De Herrera", label: "Campo De Herrera" },
      { value: "Capitan Caceres", label: "Capitan Caceres" },
      { value: "Choromoro", label: "Choromoro" },
      { value: "Colalao Del Valle", label: "Colalao Del Valle" },
      { value: "Colombres", label: "Colombres" },
      {
        value: "Colonia Mayo - Barrio La Milagrosa",
        label: "Colonia Mayo - Barrio La Milagrosa",
      },
      { value: "Concepcion", label: "Concepcion" },
      { value: "Delfin Gallo", label: "Delfin Gallo" },
      { value: "Diagonal Norte", label: "Diagonal Norte" },
      { value: "El Bracho", label: "El Bracho" },
      { value: "El Cadillal", label: "El Cadillal" },
      { value: "El Chañar", label: "El Chañar" },
      { value: "El Corte", label: "El Corte" },
      { value: "El Manantial", label: "El Manantial" },
      { value: "El Mollar", label: "El Mollar" },
      { value: "El Naranjo", label: "El Naranjo" },
      { value: "El Paraiso", label: "El Paraiso" },
      { value: "Estacion Araoz", label: "Estacion Araoz" },
      { value: "Ex Ingenio Esperanza", label: "Ex Ingenio Esperanza" },
      { value: "Ex Ingenio Los Ralos", label: "Ex Ingenio Los Ralos" },
      { value: "Ex Ingenio Lujan", label: "Ex Ingenio Lujan" },
      { value: "Ex Ingenio Nueva Baviera", label: "Ex Ingenio Nueva Baviera" },
      { value: "Ex Ingenio San Jose", label: "Ex Ingenio San Jose" },
      { value: "Famailla", label: "Famailla" },
      { value: "Garmendia", label: "Garmendia" },
      { value: "Graneros", label: "Graneros" },
      { value: "Iltico", label: "Iltico" },
      { value: "Ingenio Fronterita", label: "Ingenio Fronterita" },
      { value: "Ingenio La Florida", label: "Ingenio La Florida" },
      { value: "Ingenio San Pablo", label: "Ingenio San Pablo" },
      { value: "Ingenio Santa Barbara", label: "Ingenio Santa Barbara" },
      { value: "Juan Bautista Alberdi", label: "Juan Bautista Alberdi" },
      { value: "La Cocha", label: "La Cocha" },
      { value: "La Florida", label: "La Florida" },
      { value: "La Ramada", label: "La Ramada" },
      { value: "La Reduccion", label: "La Reduccion" },
      { value: "La Trinidad", label: "La Trinidad" },
      { value: "Lamadrid", label: "Lamadrid" },
      { value: "Las Cejas", label: "Las Cejas" },
      { value: "Lastenia", label: "Lastenia" },
      { value: "Los Gutierrez", label: "Los Gutierrez" },
      { value: "Los Pocitos", label: "Los Pocitos" },
      { value: "Los Puestos", label: "Los Puestos" },
      { value: "Los Ralos", label: "Los Ralos" },
      { value: "Los Sarmientos", label: "Los Sarmientos" },
      { value: "Lules", label: "Lules" },
      { value: "Luz Y Fuerza", label: "Luz Y Fuerza" },
      { value: "Macomitas", label: "Macomitas" },
      { value: "Manuel Garcia Fernandez", label: "Manuel Garcia Fernandez" },
      { value: "Medina", label: "Medina" },
      { value: "Monteagudo", label: "Monteagudo" },
      { value: "Monteros", label: "Monteros" },
      { value: "Nueva Trinidad", label: "Nueva Trinidad" },
      { value: "Pacara", label: "Pacara" },
      { value: "Pala Pala", label: "Pala Pala" },
      { value: "Piedrabuena", label: "Piedrabuena" },
      { value: "Pueblo Independencia", label: "Pueblo Independencia" },
      { value: "Ranchillos", label: "Ranchillos" },
      { value: "Rio Chico", label: "Rio Chico" },
      { value: "Rio Colorado", label: "Rio Colorado" },
      { value: "Rio Seco", label: "Rio Seco" },
      { value: "San Andres", label: "San Andres" },
      { value: "San Jose De La Cocha", label: "San Jose De La Cocha" },
      {
        value: "San Miguel De Tucuman (Est. Tucuman)",
        label: "San Miguel De Tucuman (Est. Tucuman)",
      },
      { value: "San Pedro De Colalao", label: "San Pedro De Colalao" },
      { value: "Santa Ana", label: "Santa Ana" },
      { value: "Santa Cruz", label: "Santa Cruz" },
      { value: "Santa Lucia", label: "Santa Lucia" },
      { value: "Santa Rosa De Leales", label: "Santa Rosa De Leales" },
      { value: "Sargento Moya", label: "Sargento Moya" },
      { value: "Simoca", label: "Simoca" },
      { value: "Soldado Maldonado", label: "Soldado Maldonado" },
      { value: "Taco Ralo", label: "Taco Ralo" },
      { value: "Tafi Del Valle", label: "Tafi Del Valle" },
      { value: "Tafi Viejo", label: "Tafi Viejo" },
      { value: "Teniente Berdina", label: "Teniente Berdina" },
      { value: "Villa Padre Monti", label: "Villa Padre Monti" },
      { value: "Villa Belgrano", label: "Villa Belgrano" },
      { value: "Villa Benjamin Araoz", label: "Villa Benjamin Araoz" },
      { value: "Villa Burruyacu", label: "Villa Burruyacu" },
      { value: "Villa Carmela", label: "Villa Carmela" },
      { value: "Villa Chicligasta", label: "Villa Chicligasta" },
      { value: "Villa Clodomiro Hileret", label: "Villa Clodomiro Hileret" },
      { value: "Villa De Trancas", label: "Villa De Trancas" },
      {
        value: "Villa Fiad - Ingenio Leales",
        label: "Villa Fiad - Ingenio Leales",
      },
      { value: "Villa Las Flores", label: "Villa Las Flores" },
      { value: "Villa Leales", label: "Villa Leales" },
      {
        value: "Villa Mariano Moreno - El Colmenar",
        label: "Villa Mariano Moreno - El Colmenar",
      },
      { value: "Villa Nueva Italia", label: "Villa Nueva Italia" },
      { value: "Villa Quinteros", label: "Villa Quinteros" },
      { value: "Villa Recaste", label: "Villa Recaste" },
      { value: "Villa Tercera", label: "Villa Tercera" },
      { value: "Yerba Buena - Marcos Paz", label: "Yerba Buena - Marcos Paz" },
      { value: "7 De Abril", label: "7 De Abril" },
    ],
  },
];

export const degreeOptions = [
  { key: 1, value: "primario", label: "Primario completo" },
  { key: 2, value: "secundario", label: "Secundario completo" },
  { key: 3, value: "universitario", label: "Universitario" },
  { key: 4, value: "terciario", label: "Terciario" },
  { key: 5, value: "posgrado", label: "Posgrado" },
];

export const languagesOptions = [
  { value: "ingles", label: "Inglés" },
  { value: "frances", label: "Frances" },
  { value: "italiano", label: "Italiano" },
  { value: "aleman", label: "Alemán" },
  { value: "portugues", label: "Portugues" },
];

export const areaOptions = [
  { value: "administracion", label: "Administracion/Oficina" },
  { value: "callcenter", label: "CallCenter/Telemercadeo" },
  { value: "publicidad", label: "Publicidad/Comunicación" },
  { value: "informatica", label: "Informática/IT" },
  { value: "logistica", label: "Logistica/Almacen/Transporte" },
  { value: "ingenieria", label: "Ingeniería" },
  { value: "atenciónclientes", label: "Atención al Cliente" },
  { value: "comercioexterior", label: "Compras/Comercio Exterior" },
  { value: "construccion", label: "Construccion/Obras" },
  { value: "gerencia", label: "Gerencia/Direccion Empresarial" },
  { value: "mantenimiento", label: "Mantenimiento y Reparación Técnica" },
  { value: "legal", label: "Legal/Asesoría" },
  { value: "hoteleria", label: "Hotelería/Turísmo" },
  { value: "salud", label: "Salud/Medicina" },
  { value: "educacion", laber: "Educacion" },
];

// export const subareaAdmin = [
// 	{value:'administracion', label:'administracion'},
// 	{value:'contabilidad', label:'contabilidad'},
// 	{value:'impuestos', label:'impuestos'},
// 	{value:'recursoshumanos', label:'recursos humanos'},
// 	{value:'ventas', label:'ventas'},
// 	{value:'compras', label:'compras'},
// 	{value:'auditoria', label:'auditoria'}
//   ]

//  export const subareaCallCenter = [
// 	{value:'callcenter', label:'CallCenter'},
// 	{value:'telemercadeo', label:'Telemercadeo'},
//   ]

// export const subareaPublicidad = [
// 	{value:'creativo', label:'Área Creativa'},
// 	{value:'cuentas', label:'Área de Cuentas'},
// 	{value:'investigacion', label:'Área de Investigación y Mercadeo'},
// 	{value:'medios', label:'Área de Medios'},
// 	{value:'produccion', label:'Área de Producción'},
//   ]

// export const subareaInformatica = [
// 	{value:'programador', label:'Programador'},
// 	{value:'sistoperativos', label:'Sistemas Operativos'},
// 	{value:'sistredes', label:'Sistemas de Redes'},
// 	{value:'soportetecnico', label:'IT'},
//   ]

// export const subareaHoteleria = [
// 	{value:'admhotelera', label:'Administracion Hotelera'},
// 	{value:'hosekeeping', label:'Hosekeeping'},
// 	{value:'gastronomia', label:'Gastronomía'},

//   ]

// export const subareaLogistica = [
// 	{value:'almacenamiento', label:'Logística de Almacenamiento'},
// 	{value:'produccion', label:'Logística de Producción'},
// 	{value:'distribucion', label:'Logística de Distribución'},
// 	{value:'inversa', label:'Logística Inversa'},
//   ]

// export const subareaIngenieria = [
// 	{value:'mecanica', label:'Mecánica'},
// 	{value:'informatica', label:'Informática/Sistemas'},
// 	{value:'industrial', label:'Industrial'},
// 	{value:'procesos', label:'En Procesos/Química'},
// 	{value:'naval', label:'Naval'},
// 	{value:'civil', label:'Civil'},
// 	{value:'sistemas', label:'Analista en Sistemas'},
//   ]

// export const subareaAtencionclientes = [
// 	{value:'recepcionista', label:'Recepcionista'},
// 	{value:'comercial', label:'Comercial'},
// 	{value:'profesional', label:'Profesionales/Consultorios/Legal'},
//   ]

// export const subareaComercioExterior = [
// 	{value:'mantenimiento', label:'Despacho de Aduana'},
// 	{value:'maestranza', label:'Almacenamiento y Distribución'},
//   ]

// export const subareaConstruccion = [
// 	{value:'directordeobra', label:'Director de Obra'},
// 	{value:'ingenierocivil', label:'Ingeniero Civil'},
// 	{value:'obrero', label:'Obrero'},
//   ]

// export const subareaMantenimiento = [
// 	{value:'mantenimiento', label:'Reparaciones/Mantenimiento'},
// 	{value:'limpieza', label:'Limpieza'},
//   ]

// export const subareaGerencia = [
// 	{value:'general', label:'General'},
// 	{value:'produccion', label:'Producción'},
// 	{value:'venta', label:'Venta'},
// 	{value:'administracion', label:'Administración'},
// 	{value:'rrhh', label:'RRHH'},
// 	{value:'', label:''},
//   ]

// export const subareaLegal = [
// 	{value:'', label:'Contratos'},
// 	{value:'', label:'Tributario'},
//   ]

// export const subareaSalud = [
// 	{value:'', label:' '},
// 	{value:'', label:''},
// 	{value:'', label:' '}
//   ]

// export const subareaEducacion = [
// 	{value:'', label:' '},
// 	{value:'', label:''},
// 	{value:'', label:' '}
//   ]

export const expresiones = {
  nombre: /^(?=[\p{L}0-9])[\p{L}\p{N}_@,.&$%#\s-]{1,50}$/u, // Letras y espacios, pueden llevar acentos.
  correo: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
  phonenumber: /^[0-9]{8,13}$/,
};

export const imagesCarousel = [
  {
    image: img3,
    altText: 'Shaking Hands',
    caption: 'Generamos buenas relaciones con el Cliente y con nuestros empleados!',
    id: 1
  },
  {
    image: img2,
    altText: 'Slide 2',
    caption: 'Lideramos en busqueda y selección de talento humano. Gestionamos uno de los recursos mas valiosos para cada empresa, facilitando el personal que necesitas para hacerte crecer!',
    id: 2
  },
  {
    image: img1,
    altText: 'Family Picture',
    caption: 'Somos una empresa de excelencia, abocados a brindar un servicio de calidad, apoyados sobre nuestros 30 años de experiencia en el rubro!',
    id: 3
  }
];