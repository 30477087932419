import React from 'react';
import { Navigate  } from 'react-router-dom';
import { PanelOfertas } from '../pages/index';
import { getValue } from "../services/LocalStorage"; 

function PrivateRoute() {

    return (
        getValue('access_token') ? <PanelOfertas /> : <Navigate to="/ofertaslaborales"/>
    );

  }
  
  export default PrivateRoute;