import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import Router from './router/Router';
import { BrowserRouter } from 'react-router-dom';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import { CssBaseline } from '@mui/material';

ReactDOM.render(
  <React.StrictMode>
    <CssBaseline>
      <BrowserRouter>
        <Router />
      </BrowserRouter>
    </CssBaseline>
  </React.StrictMode>,
  document.getElementById('root')
);