import { React } from "react";
import "./Navbar.css";
import logo from "../../images/Isologoblanco.png";
import PhoneIphone from "@mui/icons-material/PhoneIphone";
import AttachmentIcon from "@mui/icons-material/Attachment";
import HomeIcon from "@mui/icons-material/Home";
import { AppBar } from "@material-ui/core";
import { Grid, IconButton, Toolbar, Typography } from "@mui/material";

// import { Modal } from '../index';

function Navbar() {
  // const [ showModal, setShowModal ] = useState(false);

  return (
    <>
      <AppBar
        position="static"
        style={{ marginBottom: "1vw", backgroundColor: "#7B2A86" }}
      >
        <Grid container >
          <Grid item xs>
            <Toolbar sx={{ justifyContent: "flex-start", flexGrow: 1 }}>
              <img className="navbar-logo" src={logo} alt="Logo de la firma."></img>
            </Toolbar>
          </Grid>
          <Grid item xs>
            <Toolbar sx={{ justifyContent: "flex-end" }}>
              <IconButton component="a" href="/" color="inherit" size="large">
                <HomeIcon />
                <Typography variant="h6">Home</Typography>
              </IconButton>
              <IconButton
                component="a"
                href="/OfertasLaborales"
                color="inherit"
                size="large"
              >
                <AttachmentIcon />
                <Typography variant="h6">Ofertas</Typography>
              </IconButton>
              <IconButton
                component="a"
                href="/Servicios"
                color="inherit"
                size="large"
              >
                <AttachmentIcon />
                <Typography variant="h6">Servicios</Typography>
              </IconButton>
              <IconButton
                component="a"
                href="/#contacto"
                color="inherit"
                size="large"
              >
                <PhoneIphone />
                <Typography variant="h6">Contacto</Typography>
              </IconButton>
            </Toolbar>
          </Grid>
        </Grid>
      </AppBar>
    </>
  );
}

export default Navbar;
