import React from "react";
import { Box } from "@material-ui/core";
import CloseIcon from "@mui/icons-material/Close";
import "./Modal.css";
import MOButton from "../Button/MOButton";
import { Grid } from "@mui/material";

function Modal({
  children,
  visibility,
  changeVisibility,
  clearData,
  title,
  disabled,
  handleSubmit,
  buttonText,
}) {
  return (
    <>
      {visibility && (
        <Box className="modal-container">
          <div className="modal-content">
            <div className="modal-header">
              <Box component={"h3"}>{title}</Box>
              <MOButton onClick={() => changeVisibility(false)}>
                <CloseIcon />
              </MOButton>
            </div>
            <div>{children}</div>
            <Grid container justifyContent={"space-around"} marginBottom={2}>
              <MOButton onClick={clearData}>BorrarDatos</MOButton>
              <MOButton onClick={handleSubmit} disabled={disabled}>{buttonText}</MOButton>
            </Grid>
          </div>
        </Box>
      )}
    </>
  );
}

export default Modal;
