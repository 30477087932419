import React from 'react';
import './Servicios.css';
import { Servicegrid} from '../../components/index'


function Servicios() {
  return (
    <>
    <div className="container-grid">
      <main className='services-container'>
        <Servicegrid/>
      </main>
    </div>
    </>
  );
}

export default Servicios;