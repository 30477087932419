import React from "react";
import './loading.css';
import logo from '../../images/logo.png';
import { Typography } from "@mui/material";

function Loading() {

    return (
      <div className="loading-container">
          <Typography className="loading-item">
              Cargando...
          </Typography>
          <Typography className="loading-item">
              Por Favor Espere
          </Typography>
          <img src={logo} className="loading-logo" alt="logo" />
      </div>
    );
  }
  
  export default Loading;