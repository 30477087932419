import React from 'react';
import Servicecard from './Servicecard';
import './servicegrid.css';
import {servicios} from '../dummyData/serviceData';


function Servicegrid() {
    return (  
        <div className="servicegrid">
            {servicios.map((servicio) => 
                <Servicecard key={servicio.id} service={servicio}/>
            )}
        </div>
    );
}

export default Servicegrid;