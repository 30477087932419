import React from "react";
import "./Aboutus.css";
import { Grid } from "@material-ui/core";
// import imagen from '../../images/Logotipo blanco.png';
import imagen from "../../images/Isologotipo color.png";
import { Box, List, ListItem, Typography } from "@mui/material";

function Aboutus() {
  return (
    <Box padding={2}>
      <Grid container justifyContent="space-around">
          <img
            className="image-title"
            src={imagen}
            alt="Nombre de la empresa"
          />
      </Grid>

      <Box>
        <Typography variant="h3" align="center">Nuestra Historia</Typography>
        <Box>
          <Typography mr={"7vw"} ml={"7vw"} align="justify" variant="h6">
            MO es una empresa nacida en San Luis, que tiene su desarrollo en en
            la región de Cuyo, y que con el tiempo ha logrado instalarse en
            Capital Federal y Gran Buenos Aires.

            De la mano de su fundadora, y gracias al aporte de su basta
            experiencia en el reclutamiento y seleccion de talento humano, nace
            MO Servicios Eventuales. Especializados en dar una solucion ante una
            problemática creciente, la falta de mano de obra calificada, entre
            algunos otros servicios.

            Sabemos que es lo que valoran nuestros cientes a la hora de
            contratar un servicio de eventuales, prolijidad, contacto directo,
            agilidad en las respuestas.

            Bajo criterios de especialización y eficacia, desarrollando un
            concepto de Gestión Integral de la mano de lo que hoy ofrecemos como
            Facility Management, abarcando distintas disciplinas a través de la
            integración del espacio , el personal y los procesos.
          </Typography>
        </Box>
      </Box>
      <Grid container justifyContent="space-evenly">
        <Grid item>
          <List>
            <Typography borderBottom={1} variant="h5">
              Que nos diferencia
            </Typography>
            <ListItem>Excelencia</ListItem>
            <ListItem>Confiabilidad</ListItem>
            <ListItem>Dinamísmo</ListItem>
            <ListItem>Compromiso</ListItem>
            <ListItem>Empatía</ListItem>
          </List>
        </Grid>
        <Grid item>
          <List>
            <Typography borderBottom={1} variant="h5">
              Ventajas que ofrecemos
            </Typography>
            <ListItem>Atención Responsable</ListItem>
            <ListItem>Solución Inmediata</ListItem>
            <ListItem>Innovación en los RRHH</ListItem>
          </List>
        </Grid>
      </Grid>

    </Box>
  );
}

export default Aboutus;
