import "./Servicecard.css"

function Servicecard({ id, service }) {
    return (   
    <div className="servicecard-image-container">
        <figure className="image-container">  
            <img className="serviceimg" src={service.image} alt={"imagen de" + service.title}/>

            <div className="text-container">
                <h4 className="servicetitle">{service.title}</h4>
                <p className="servicedescription">{service.description}</p>
            </div>
        </figure>
    </div>
    );
    
}

export default Servicecard;