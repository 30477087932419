import React, { useState } from "react";
import "./PanelOfertas.css";
import { Input, SelectInput } from "../../components/index";
import axios from "axios";
import { URLInsertJobOffer } from "../../constants/constants";
import {
  Box,
  Grid,
  TextareaAutosize,
  InputLabel,
  MenuItem,
} from "@material-ui/core";
import Alert from "@mui/material/Alert";
import MOButton from "../../components/Button/MOButton";
import { Container, Typography } from "@mui/material";
const FormData = require("form-data");

function PanelOfertas() {
  const [alert, setAlert] = useState({
    visible: false,
    text: "Se cargó correctamente",
    severity: "success",
  });

  const [dataForm, setDataForm] = useState({
    title: "",
    description: "",
    zone: "",
    date: "",
    salary: "",
    company: "",
    time: "",
    area: "",
  });

  const formData = new FormData();

  const handleSubmit = () => {
    // Update the formData object
    formData.set("title", dataForm.title);
    formData.set("description", dataForm.description);
    formData.set("zone", dataForm.zone);
    formData.set("date", dataForm.date);
    formData.set("salary", dataForm.salary);
    formData.set("company", dataForm.company);
    formData.set("time", dataForm.time);
    formData.set("area", dataForm.area);

    axios({
      method: "post",
      url: `${URLInsertJobOffer}`,
      headers: {
        "content-type": `multipart/form-data`,
      },
      data: formData,
    })
      .then((result) => {
        console.log(result.statusText);
        setAlert({
          visible: true,
          severity: "success",
          text: "La oferta se cargó correctamente",
        });
      })
      .catch((error) =>
        setAlert({ visible: true, severity: "error", text: error.message })
      );
  };

  const isFormValid = () => {
    for (let [key, value] of Object.entries(dataForm)) {
      if (value === "") {
        return false;
      }
    }
    return true;
  };

  return (
    <Container maxWidth="md" sx={{ padding: 15 }}>
      {alert.visible && <Alert severity={alert.severity}>{alert.text}</Alert>}
      <Box
        sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12} sm={6} md={4}>
            <Input
              id="title"
              label="Titulo para la publicación"
              error={!dataForm.title}
              sx={{ borderRadius: 1 }}
              onChange={(e) =>
                setDataForm({ ...dataForm, title: e.target.value })
              }
            />
          </Grid>

          <Grid item xs={12} sm={6} md={4}>
            <Input
              id="zone"
              label="Zona de trabajo"
              error={!dataForm.zone}
              onChange={(e) =>
                setDataForm({ ...dataForm, zone: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Input
              name="date"
              type="date"
              label="Fecha de publicacion"
              error={!dataForm.date}
              onChange={(e) =>
                setDataForm({ ...dataForm, date: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12}>
            <InputLabel
              id="time"
              style={{
                textAlign: "left",
                padding: "5px",
                fontWeight: "700",
                color: "black",
              }}
            >
              Descripción
            </InputLabel>
            <TextareaAutosize
              style={{ width: "100%", borderRadius: 4 }}
              minRows={3}
              aria-label="Descripcion"
              id="description"
              onChange={(e) =>
                setDataForm({ ...dataForm, description: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Input
              id="salary"
              label="Sueldo"
              error={!dataForm.salary}
              onChange={(e) =>
                setDataForm({ ...dataForm, salary: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Input
              id="company"
              label="Empresa que contrata"
              error={!dataForm.company}
              onChange={(e) =>
                setDataForm({ ...dataForm, company: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <Input
              id="area"
              label="Rubro o Area"
              error={!dataForm.area}
              placeholder={"comercial/contable/operario"}
              onChange={(e) =>
                setDataForm({ ...dataForm, area: e.target.value })
              }
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <SelectInput
              id="time"
              label="Modalidad de contratación"
              value={dataForm.time}
              onChange={(e) =>
                setDataForm({ ...dataForm, time: e.target.value })
              }
            >
              <MenuItem value={"full-time"}>Full-Time</MenuItem>
              <MenuItem value={"part-time"}>Part-Time</MenuItem>
              <MenuItem value={"eventual"}>Eventual</MenuItem>
            </SelectInput>
            {/* <InputLabel
              id="time"
              style={{
                textAlign: "left",
                padding: "5px",
                fontWeight: "700",
                color: "black",
              }}
            >
              Tipo de Contratación
            </InputLabel>
            <Box borderRadius={5} bgcolor={"white"} height={30}>
              <Select
                fullWidth
                labelId="time"
                id="time"
                value={dataForm.time}
                onChange={(e) =>
                  setDataForm({ ...dataForm, time: e.target.value })
                }
              >
                <MenuItem value={"full-time"}>Full-Time</MenuItem>
                <MenuItem value={"part-time"}>Part-Time</MenuItem>
                <MenuItem value={"eventual"}>Eventual</MenuItem>
              </Select>
            </Box> */}
          </Grid>
          <Grid item xs={12} style={{ marginTop: 20 }}>
            <MOButton disabled={!isFormValid()} onClick={() => handleSubmit()}>
              <Typography color={"white"}>Guardar!</Typography>
            </MOButton>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}

export default PanelOfertas;
