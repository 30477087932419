import React from 'react';
import './Home.css';
import { Slideshow, Contact, Aboutus, Sucursales } from '../../components/index';



function Home() {
  return (
    <>
        <div>
          <Aboutus/>
        </div>
        <div>
          <Slideshow/>
        </div> 
        <div id="contacto">
          <Contact />
        </div>
        <div>
          <Sucursales/>
        </div>
        </>
  );
}

export default Home;