import { React, useEffect, useState } from "react";
import axios from "axios";

import { useParams } from "react-router-dom";
import {
  Grid,
  ListItemText,
  Paper,
  Box,
  Typography,
  Alert,
  Button,
} from "@mui/material";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FmdGoodIcon from "@mui/icons-material/FmdGood";
import TouchAppIcon from '@mui/icons-material/TouchApp';
import CheckIcon from "@mui/icons-material/Check";
import { URLGetJobOffersByID, URLSendCV } from "../../constants/constants";
import { Form, Loading, Modal } from "../../components";

const DetallesOferta = () => {
  const { id } = useParams();
  const [jobOffer, setJobOffer] = useState({});
  const [loading, setLoading] = useState(true);
  const [showApply, setShowApply] = useState(false);
  const [jobOfferSelect, setJobOfferSelect] = useState({
    jobTitle: "",
    jobId: "",
  });
  const [dataForm, setDataForm] = useState({
    name: "",
    lastname: "",
    birth: "",
    celphone: "",
    province: "",
    town: "",
    degree: "",
    languages: "",
    area: "",
    subarea: "",
    message: "",
    file: "",
    email: "",
    jobId: "",
  });
  const [fileUpload, setFileUpload] = useState(false);
  const [alert, setAlert] = useState({
    visible: false,
    text: "",
    severity: "",
  });
  const [sent, setSended] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.set("job_id", `${id}`);

      const result = await axios({
        method: "get",
        url: `${URLGetJobOffersByID}?job_id=${id}`,
      });
      console.log(result.data);
      setJobOffer(result.data);
      setLoading(false);
    } catch (error) {
      console.log({ error: error.message });
    }
  };

  const handleSubmit = async () => {
    const formData = new FormData();

    // Update the formData object
    formData.append("file", dataForm.file);
    formData.set("name", dataForm.name);
    formData.set("lastname", dataForm.lastname);
    formData.set("birth", dataForm.birth);
    formData.set("cel", dataForm.celphone);
    formData.set("email", dataForm.email);
    formData.set("province", dataForm.province);
    formData.set("town", dataForm.town);
    formData.set("degree", dataForm.degree);
    formData.set("languages", dataForm.languages);
    formData.set("area", dataForm.area);
    formData.set("subarea", dataForm.subarea);
    formData.set("jobId", jobOfferSelect.jobId);
    formData.set("message", dataForm.message);

    // Details of the uploaded file

    if (dataForm.name && dataForm.celphone && dataForm.file && dataForm.email) {
      for (let [key, value] of formData) {
        console.log(`${key}: ${value}`);
      }
      try {
        const result = await axios({
          method: "post",
          url: `${URLSendCV}`,
          headers: {
            "content-type": `multipart/form-data; boundary=${dataForm.file._boundary}`,
          },
          data: formData,
        });
        console.log({
          mailSent: result.data,
        });
        setSended(true);
        cleardataForm();
        setShowApply(!showApply);
      } catch (error) {
        console.log({ error: error.message });
      }
    } else {
      setAlert({
        visible: true,
        severity: "error",
        text: `
          Debe completar todos los campos.
          De esta forma podemos garantizar un mejor servicio.
        `,
      });
    }
  };

  const cleardataForm = () => {
    const dataFormVacio = {
      name: "",
      lastname: "",
      birth: "",
      celphone: "",
      province: "",
      town: "",
      degree: "",
      languages: "",
      area: "",
      subarea: "",
      message: "",
      file: "",
      email: "",
      jobId: "",
    };
    setDataForm(dataFormVacio);
    setFileUpload(false);
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Grid
          container
          spacing={2}
          direction="column"
          alignItems="center"
          justifyContent="center"
        >
          <Grid item style={{ width: "75%", padding: "30px" }}>
            <Paper
              elevation={6}
              style={{ padding: "30px" }}
              square={false}
              // sx={{ border: "2px solid grey", boxShadow: 1 }}
            >
              <ListItemText>
                <Grid container item>
                  <Grid item xs={8} md={10}>
                    <Typography variant="h4">{jobOffer?.title}</Typography>
                  </Grid>
                  <Grid item xs={8} md={2}>
                    <Button
                      variant="contained"
                      sx={{ bgcolor: "#EF8B2D" }}
                      startIcon={<TouchAppIcon/>}
                      onClick={() => (
                        setShowApply(!showApply),
                        setJobOfferSelect({
                          jobTitle: jobOffer.title,
                          jobId: id,
                        })
                      )}
                    >Aplicar</Button>
                  </Grid>
                </Grid>
              </ListItemText>
              <br />
              <ListItemText>
                <Typography variant="h6">Descripcion del puesto:</Typography>
              </ListItemText>
              <ListItemText primary={jobOffer.description} />
              {jobOffer.salary ? (
                <ListItemText primary={`Salario: ${jobOffer.salary}`} />
              ) : (
                <></>
              )}
              <ListItemText primary={`Empresa: ${jobOffer.company}`} />
              <ListItemText primary={`Area de trabajo: ${jobOffer.area}`} />
              <ListItemText primary={`Publicado: ${jobOffer.date}`} />
              <Grid container item direction="row">
                <Box
                  height={5}
                  width={150}
                  my={4}
                  display="flex"
                  alignItems="center"
                  gap={2}
                  p={2}
                  sx={{
                    border: "2px solid grey",
                    borderRadius: 5,
                    boxShadow: 1,
                  }}
                >
                  <AccessTimeIcon /> {jobOffer.time}
                </Box>
                <Box
                  height={5}
                  my={4}
                  display="flex"
                  alignItems="center"
                  gap={2}
                  p={2}
                  sx={{
                    border: "2px solid grey",
                    borderRadius: 5,
                    boxShadow: 1,
                  }}
                >
                  <FmdGoodIcon /> {jobOffer.zone}
                </Box>
              </Grid>
            </Paper>
          </Grid>
          {sent ? (
            <Grid item>
              <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
                Ha enviado correctamente su aplicación.
              </Alert>
            </Grid>
          ) : (
            <></>
          )}
        </Grid>
      )}

      <Modal
        visibility={showApply}
        changeVisibility={setShowApply}
        title={jobOfferSelect.jobTitle}
        buttonText="Submit"
        handleSubmit={() => handleSubmit()}
        clearData={cleardataForm}
      >
        <Form
          jobId={jobOfferSelect.jobId}
          dataForm={dataForm}
          setDataForm={setDataForm}
          fileUpload={fileUpload}
          setFileUpload={setFileUpload}
          alert={alert}
        />
      </Modal>
    </>
  );
};

export default DetallesOferta;
