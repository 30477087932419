import React from 'react';
import './Slideshow.css';
import { imagesCarousel } from '../../constants/constants';
import Slider from 'infinite-react-carousel';


const settings = {
  autoplay: true,
  duration: 1000,
  autoplaySpeed: 7000,
  className: 'slidershow-container',
}


function Slideshow() {

    return ( 
        <section className='slidershow'>
            <Slider  {...settings}>
                {
                    imagesCarousel.map(image => 
                    <div key={image.id}>
                        <img className='slidershow-container--image' src={image.image} alt={image.altText}/>
                        <p className='slidershow-text'>{image.caption}</p>
                    </div>)
                }
            </Slider>
        </section>
     );
}

export default Slideshow;