import React, { useState } from "react";
// import "./Form.css";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Input, SelectInput } from "../../components/index";
import {
  ciudadesArgentinas,
  subAreas,
  expresiones,
  degreeOptions,
  areaOptions,
  languagesOptions,
} from "../../constants/constants";
import {
  Alert,
  Container,
  Grid,
  Button,
} from "@mui/material";
import MenuItem from "@mui/material/MenuItem";


function Form({ jobId, dataForm, setDataForm, fileUpload, setFileUpload, alert }) {
  const [townOptions, setTownOptions] = useState([]);
  const [subareaOptions, setSubAreaOptions] = useState([]);
  // const [alert, setAlert] = useState({
  //   visible: false,
  //   severity: "success",
  // });

  //Handle inputs
  const handleInput = (value, id) => {
    let form = dataForm;

    form[`${id}`] = value;

    if (id === "province") form["town"] = "";
    if (id === "area") form["subarea"] = "";
    if (id === "file") setFileUpload(true);
    setDataForm(form);

    console.log(dataForm);

  };

  const validateTownOptions = (province) => {
    const found = ciudadesArgentinas.find(
      (element) => element.label === province
    );
    // console.log(found.ciudades);
    setTownOptions(found.ciudades);
  };
  const validateAreaOptions = (area) => {
    const encontrado = subAreas.find((element) => element.value === area);
    setSubAreaOptions(encontrado.subareas);
  };


  return (
    <Container maxWidth="md" sx={{ padding: 5 }}>
      {alert.visible && <Alert severity={alert.severity}>{alert.text}</Alert>}
      <Grid container spacing={1}>
        <Grid item md={6}>
          <Input
            type="text"
            id="name"
            label="Nombre"
            value={dataForm.name}
            error={!expresiones.nombre.test(dataForm.name)}
            helperText="El nombre no pude contener números o carácteres especiales"
            onChange={(e) => setDataForm({ ...dataForm, name: e.target.value })}
          />
        </Grid>
        <Grid item md={6}>
          <Input
            type="text"
            id="apellido"
            value={dataForm.lastname}
            label="Apellido"
            error={!expresiones.nombre.test(dataForm.lastname)}
            helperText="El apellido no pude contener números o carácteres especiales"
            onChange={(e) =>
              setDataForm({ ...dataForm, lastname: e.target.value })
            }
          />
        </Grid>
        <Grid item md={6}>
          <Input
            type="date"
            id="years"
            value={dataForm.birth}
            label="Fecha de Nacimiento"
            error={!dataForm.birth}
            onChange={(e) =>
              setDataForm({ ...dataForm, birth: e.target.value })
            }
          />
        </Grid>
        <Grid item md={6}>
          <Input
            type="text"
            id="celphone"
            label="Teléfono o Celular"
            value={dataForm.celphone}
            error={!expresiones.phonenumber.test(dataForm.celphone)}
            onChange={(e) => setDataForm({...dataForm, celphone: e.target.value})}
          />
        </Grid>
        <Grid item md={12}>
          <Input
            type="text"
            id="email"
            value={dataForm.email}
            label="E-mail pesonal"
            error={!expresiones.correo.test(dataForm.email)}
            onChange={(e) => handleInput(e.target.value, "email")}
          />
        </Grid>

        <Grid item md={6}>
          {/* Province section */}
          <SelectInput
            id="province"
            label="Seleccione provincia de recidencia"
            value={dataForm.province}
            onChange={(e) => (
              validateTownOptions(e.target.value),
              handleInput(e.target.value, "province")
            )}
          >
            {ciudadesArgentinas.map(({ id, value, label }) => (
              <MenuItem key={id} value={value}>
                {label}
              </MenuItem>
            ))}
          </SelectInput>
        </Grid>

        {/* Town section */}
        <Grid item md={6}>
          <SelectInput
            id="town"
            label="Seleccione su localidad"
            value={dataForm.town}
            onChange={(e) => setDataForm({ ...dataForm, town: e.target.value })}
          >
            {townOptions.map(({ key, value, label }) => (
              <MenuItem key={key} value={value}>
                {label}
              </MenuItem>
            ))}
          </SelectInput>
        </Grid>

        {/* Degree section */}
        <Grid item md={6}>
          <SelectInput
            id="degree"
            label="Nivel de estudio alcanzado"
            value={dataForm.degree}
            onChange={(e) =>
              setDataForm({ ...dataForm, degree: e.target.value })
            }
          >
            {degreeOptions.map(({ key, value, label }) => (
              <MenuItem key={key} value={value}>
                {label}
              </MenuItem>
            ))}
          </SelectInput>
        </Grid>

        {/* Languages section */}
        <Grid item md={6}>
          <SelectInput
            id="languages"
            label="Idiomas"
            value={dataForm.languages}
            onChange={(e) =>
              setDataForm({ ...dataForm, languages: e.target.value })
            }
            //multiple={true}
          >
            {languagesOptions.map(({ key, value, label }) => (
              <MenuItem key={key} value={value}>
                {label}
              </MenuItem>
            ))}
          </SelectInput>
        </Grid>

        {/* Area section */}
        <Grid item md={6}>
          <SelectInput
            id="area"
            label="Seleccione su area de experiencia"
            value={dataForm.area}
            onChange={(e) => (
              handleInput(e.target.value, "area"),
              validateAreaOptions(e.target.value)
            )}
          >
            {areaOptions.map(({ key, value, label }) => (
              <MenuItem key={key} value={value}>
                {label}
              </MenuItem>
            ))}
          </SelectInput>
        </Grid>

        {/* Subarea section */}
        <Grid item md={6}>
          <SelectInput
            id="subarea"
            label="Seleccione subarea de especialización"
            value={dataForm.subarea}
            onChange={(e) =>
              setDataForm({ ...dataForm, subarea: e.target.value })
            }
          >
            {subareaOptions.map(({ id, value, label }) => (
              <MenuItem key={id} value={value}>
                {label}
              </MenuItem>
            ))}
          </SelectInput>
        </Grid>

        {/* Message section */}
        <Grid item md={12}>
          <Input
            type="text"
            id="message"
            label="Ingrese un mensaje"
            error={!dataForm.message}
            onChange={(e) =>
              setDataForm({ ...dataForm, message: e.target.value })
            }
          />
        </Grid>
      </Grid>

      {/* File Section */}
      <Grid container marginTop={1} justifyContent={"center"} alignItems={"center"}>
        <Grid item md={12}>
        <Button
          component="label"
          role={undefined}
          variant="contained"
          tabIndex={-1}
          startIcon={<CloudUploadIcon />}
        >
          Upload file
          <input
            style={{
              clip: "rect(0 0 0 0)",
              clipPath: "inset(50%)",
              height: 1,
              overflow: "hidden",
              position: "absolute",
              bottom: 0,
              left: 0,
              whiteSpace: "nowrap",
              width: 1,
            }}
            type="file"
            // accept=".txt,.pdf,text/plain"
            onChange={(e) => handleInput(e.target.files[0], "file")}
          ></input>
        </Button>
        </Grid>
        {fileUpload ? <Grid item md={12}>Archivo subido correctamente<FileUploadIcon/></Grid> : <Grid  item md={12}><h6>accept=".txt,.pdf,text/plain</h6></Grid>}        
      </Grid>
    </Container>
  );
}

export default Form;
