export const servicios = [
    {
        "title" : "Personal Eventual",
        "description" : "Encontramos los candidatos mas adecuados para tu negocio. Mediante un meticuloso proceso de entrevistas seleccionamos los perfiles más destacados.",
        "image" : require('../../images/personaleventual.jpg'),
        "id" : "001"
    },
    {
        "title" : "Busqueda de Talento",
        "description" : "Ponemos a disposición un grupo de novedosos consultores especializados en la busqueda de nuevos talentos.",
        "image" : require('../../images/busquedatalento.jpg'),
        "id" : "002"
    },
    {
        "title" : "Payroll",
        "description" : "Brindamos el servicio de liquidación de sueldos.",
        "image" : require('../../images/payroll.jpg'),
        "id" : "003"
    },
    {
        "title" : "Coaching y Consultorías",
        "description" : "Orientamos nuestro servicio a las areas más novedosas dentro del mercado. Nuestros Coachs se capacitan día a día para brindar la mejora capacitación y orientación a los equipos de trabajo para lograr sus objetivos.",
        "image" : require('../../images/coaching.jpg'),
        "id" : "004"
    },
    {
        "title" : "Diseño y Desarrollo Web",
        "description" : "Brindamos el servicio de creación y hosting de páginas web. Pueden ser de una página o multipáginas con redireccionamiento",
        "image" : require('../../images/desarrollo-web.jpg'),
        "id" : "005"
    }

];