import './Comingsoon.css'
import fondo from '../../images/fondoComingsoon.jpg'
import ConstructionIcon from '@mui/icons-material/Construction';

function Comingsoon() {
    return ( 
        <div className='comingsoon-container'>
            <img className='comingsoon-image' src={fondo} alt='fondo difuminado'/>
               <div className='comingsoon-text'>
                   <b>PAGINA EN CONSTRUCCION</b>
                    <ConstructionIcon sx={{ fontSize: 40 }}/>
               </div>
        </div>
    );
}

export default Comingsoon;