import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const MOButton = ({variant='contained', disabled=false, onClick, children}) => {

  const ColorButton = styled(Button)(({ theme }) => ({
    color: theme.palette.getContrastText("#7B2A86"),
    backgroundColor: "#7B2A86",
    '&:hover': {
      backgroundColor: "#46184D",
    },
  }));

  return (
      <ColorButton disabled={disabled} onClick={onClick} variant={variant}>{children}</ColorButton>
  );
}

export default MOButton;