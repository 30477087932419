import {
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Typography,
  Box,
} from "@mui/material";
import React from "react";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import FmdGoodIcon from "@mui/icons-material/FmdGood";

function JobOfferCard({
  jobID,
  jobTitle,
  jobDescription,
  jobArea,
  jobTime,
  jobDate,
}) {
  return (
    <Card raised height={"inherit"}>
      <CardActionArea href={`/OfertasLaborales/${jobID}`}>
        <CardHeader title={jobTitle.toUpperCase()} titleTypographyProps={{variant:"h6"}} sx={{height:"fit-content"}}/>
        <CardContent>
          <Typography my={2} noWrap variant="body2" color="text.secondary">
            {jobDescription}
          </Typography>
          <Box
            height={5}
            // width={150}
            my={1}
            display="flex"
            alignItems="center"
            gap={2}
            p={2}
            sx={{
              border: "2px solid grey",
              borderRadius: 5,
              boxShadow: 1,
            }}
          >
            <AccessTimeIcon /> {jobTime}
          </Box>
          <Box
            height={"fit-content"}
            //my={4}
            display="flex"
            alignItems="center"
            gap={2}
            p={2}
            sx={{
              border: "2px solid grey",
              borderRadius: 5,
              boxShadow: 1,
            }}
          >
            <FmdGoodIcon /> {jobArea}
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}

export default JobOfferCard;
