import './sucursales.css';
import  ImagenPais  from '../../images/MapaArgentina.png'
import { Box, Grid } from '@mui/material';

function sucursales() {
    return ( 
        <Box >

            <Grid container justifyContent={"space-around"}>
                <img src={ImagenPais} alt='Mapa de America del sur con argentina marcada en verde'/>
            </Grid>
        
        </Box> 
     );
}

export default sucursales;