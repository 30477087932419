import React from "react";
import "./SelectInput.css";
import { Box, InputLabel, Select } from "@mui/material";

function SelectInput({
  id,
  label,
  onChange,
  value,
  children
}) {
  return (
    <>
      <InputLabel
        id={id}
        style={{
          textAlign: "left",
          padding: "5px",
          fontWeight: "700",
          color: "black",
        }}
      >
        {label}
      </InputLabel>
      <Box borderRadius={5} bgcolor={"white"} height={30}>
        <Select
          fullWidth
          labelId={id}
          id={id}
          value={value}
          onChange={onChange}
          style={{height:"inherit"}}
        >
          {children}
        </Select>
      </Box>

      {/* // <label className="selectinput-label" htmlFor={labelname}>{label}</label>
        // <div>
        // <Select
        //     id={labelname}
        //     name={labelname}
        //     options={opciones}
        //     onChange={onChange}
        //     value={value}
        //     isMulti={selmultiple}
        //   />
        // </div> */}
    </>
  );
}

export default SelectInput;
