import React from 'react';
import './Footer.css'
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import Link from '@material-ui/core/Link';
import imglogo from '../../images/Isologotipo color.png';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import { linkedInURL, instagramURL, facebookURL, twitterURL, whatsappURL } from '../../constants/constants';

const date = new Date().toLocaleString();

function Footer() {
    return (
        <footer className='footer-container'>

            <img className='footer-image' src={imglogo} alt="logo empresa"/>
{/* 
            <div className="nuestras-redes-container">  
                Nuestras Redes
            </div> */}

            <div className="container-socialnetworks">
                <Link href={linkedInURL} target="_blank" rel="noopener">
                    <LinkedInIcon className="contact-socialnetworks-icons" sx={{ fontSize: 50 }}/>
                </Link>
                <Link href={instagramURL} target="_blank" rel="noopener">
                    <InstagramIcon className="contact-socialnetworks-icons" sx={{ fontSize: 50 }}/>
                </Link>
                <Link href={facebookURL} target="_blank" rel="noopener">
                    <FacebookIcon className="contact-socialnetworks-icons" sx={{ fontSize: 50 }}/>
                </Link>
                <Link href={twitterURL} target="_blank" rel="noopener">
                    <TwitterIcon className="contact-socialnetworks-icons" sx={{ fontSize: 50 }}/>
                </Link>
                <Link href={whatsappURL} target="_blank" rel="noopener">
                    <WhatsAppIcon className="contact-socialnetworks-icons" sx={{ fontSize: 50 }}/>
                </Link>
            </div>
            <div className='copyright-container'>
                <p>Copyright MO Empresa de Servicios Eventuales S.R.L</p>
                <p> {date}</p>
            </div>
            
        </footer>
    );
}

export default Footer;