import React from "react";
import "./Input.css";
import CancelIcon from "@mui/icons-material/Cancel";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from "@mui/material";

function Input({
  onChange,
  color,
  id,
  label,
  error,
  type,
  value
}) {
  return (
    <>
      <InputLabel
        id={id}
        title={id}
        style={{
          textAlign: "left",
          padding: "5px",
          fontWeight: "700",
          color: "black",
        }}
      >
        {label}
      </InputLabel>
      <OutlinedInput
        fullWidth
        type={type}
        error={error}
        color={color}
        onChange={onChange}
        value={value}
        endAdornment={
          <InputAdornment position="end">
            <IconButton edge="end" sx={{ cursor: "default" }}>
              {!error ? (
                <CheckCircleIcon color="success" />
              ) : (
                <CancelIcon color="error" />
              )}
            </IconButton>
          </InputAdornment>
        }
      />
    </>
  );
}

export default Input;
